import { useState } from "react";
import { useTranslation } from "react-i18next";

import { convertErrorToTi18nKey } from "../../../../api";
import {
  useCompanyCasesQuery,
  useCompanyDetailsQuery,
} from "../../../../api/rest/administration";
import { LabelValue } from "../../../../components/design-system/LabelValue";
import { Loading } from "../../../../components/design-system/Loading";
import { Tab } from "../../../../components/design-system/Tab";
import { TableV2 } from "../../../../components/design-system/TableV2";
import { NoData } from "../../../../components/NoData";
import type {
  CompanyCaseContact,
  CompanyInformation as CompanyInformationModel,
} from "../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../types/models/company";
import { getCountryName } from "../../../../utils/country";
import { getFormattedDate } from "../../../../utils/date";
import {
  formatCurrency,
  formatNumber,
  formatRefId,
} from "../../../../utils/format";

type Props = {
  currentCompany: CompanyInvolvement | CompanyInformationModel;
};

type FormatTextProps = {
  text: string;
};

const FormatText = ({ text }: FormatTextProps) => {
  const lines = text.split("\\n");
  return (
    <ul>
      {lines.map((line) => (
        <li key={line}>
          {line.split(/\\t/g).map((part, j) =>
            j > 0 ? (
              <>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {part}
              </>
            ) : (
              <>{part}</>
            )
          )}
        </li>
      ))}
    </ul>
  );
};

const formatContact = (contact: CompanyCaseContact) => {
  const address = contact.address
    ? [
        contact.address.coAdress,
        contact.address.street,
        contact.address.postcode,
        contact.address.city,
      ]
        .filter((x) => x !== undefined)
        .join(", ")
    : undefined;
  return [contact.addressee, address, contact.email]
    .filter((x) => x !== undefined)
    .join(", ");
};

const CompanyInformation = ({ currentCompany }: Props) => {
  const i18n = useTranslation();
  const [expanded, setExpanded] = useState<Record<number, boolean>>({});
  const {
    error,
    isLoading,
    data: companyInfo,
    isError,
    isSuccess,
  } = useCompanyDetailsQuery(currentCompany?.orgNumber);

  const casesQuery = useCompanyCasesQuery(currentCompany?.orgNumber);
  const cases = casesQuery.data?.cases || [];
  const casesMapping: { [key: string]: string } = {
    NYB: i18n.t("changeLog.case.formation"),
    EPA: i18n.t("changeLog.case.email"),
    PA: i18n.t("changeLog.case.address"),
    FÖRP: i18n.t("changeLog.case.representativeAddress"),
    MPA: i18n.t("changeLog.case.multiAddress"),
    FIRM: i18n.t("changeLog.case.companyName"),
    REV: i18n.t("changeLog.case.auditors"),
    FÖRU: i18n.t("changeLog.case.withdrawal"),
    DELÅR: i18n.t("changeLog.case.interimReport"),
    ÅRSRED: i18n.t("changeLog.case.annualReport"),
    FUVE: i18n.t("changeLog.case.merger"),
  };
  const events = cases.reduce((prev, c) => {
    const newEvents = c.codes
      .map((code) =>
        casesMapping[code]
          ? {
              caseId: c.caseId,
              event: casesMapping[code],
              date: c.created.split("T")[0]!,
              completedDate: c.completed?.split("T")[0],
              name: code === "FIRM" ? c.actor : undefined,
              contact: c.contact,
            }
          : null
      )
      .filter((i) => i !== null);
    return [...prev, ...newEvents];
  }, [] as { caseId: string; event: string; date: string; completedDate?: string; name?: string; contact?: CompanyCaseContact | null }[]);

  const { sharesInformation } = companyInfo || {};
  const shareClassWithLimits =
    sharesInformation?.shareClasses?.filter(
      (shareClass) => !!shareClass.shareLimits
    ) ?? [];
  const hasShareClassesLimits = shareClassWithLimits.length > 0;

  return (
    <div className="tw-space-y-2">
      {isError && (
        <NoData
          type="error"
          title={i18n.t("error.fetch")}
          description={i18n.t(convertErrorToTi18nKey(error))}
        />
      )}
      {isLoading && <Loading />}
      {isSuccess && !companyInfo && <NoData />}
      {isSuccess && companyInfo && (
        <>
          <h1 className="tw-text-2xl tw-font-medium">
            {i18n.t("label.companyInformation")}
          </h1>
          {companyInfo.lastUpdated && (
            <span className="tw-text-sm tw-text-gray-500">
              {i18n.t("label.updated")}{" "}
              {getFormattedDate(companyInfo.lastUpdated.split("T")[0])}
            </span>
          )}
          <Tab.Group>
            <Tab.List className="tw-pb-4">
              <Tab>{i18n.t("label.companyChangeLog")}</Tab>
              <Tab>{i18n.t("label.shareInformation")}</Tab>
              <Tab>{i18n.t("label.aboutCompany")}</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className="tw-mb-4 tw-rounded tw-border tw-p-4 tw-text-sm">
                  <p>{i18n.t("changeLog.cardDescription")}</p>
                </div>
                <TableV2
                  columns={[
                    {
                      title: i18n.t("changeLog.data.event"),
                      name: "event",
                      key: true,
                      sortable: false,
                    },
                    {
                      title: i18n.t("changeLog.data.date"),
                      name: "date",
                      key: true,
                      sortable: false,
                    },
                    {
                      title: i18n.t("changeLog.data.caseId"),
                      name: "caseId",
                    },
                    {
                      title: i18n.t("changeLog.data.completedDate"),
                      name: "completedDate",
                    },
                    {
                      title: i18n.t("changeLog.data.name"),
                      name: "name",
                    },
                    {
                      title: i18n.t("changeLog.data.contact"),
                      name: "contact",
                    },
                  ]}
                  data={events
                    .map((e) => ({
                      ...e,
                      key: `${e.caseId}-${e.event}`,
                      contact: e.contact ? formatContact(e.contact) : undefined,
                    }))
                    .reverse()}
                  expandedRows={expanded}
                  setExpandedRows={setExpanded}
                />
              </Tab.Panel>
              {sharesInformation && (
                <Tab.Panel>
                  <div className="tw-space-y-4">
                    {sharesInformation.shareCapital && (
                      <LabelValue
                        label={i18n.t("label.shareCapital")}
                        value={`${formatCurrency(
                          sharesInformation.shareCapital.amount
                        )} ${sharesInformation.shareCapital.currency}`}
                      />
                    )}
                    {sharesInformation.numberOfShares && (
                      <LabelValue
                        label={i18n.t("label.totalNrOfShares")}
                        value={`${formatNumber(
                          sharesInformation.numberOfShares
                        )} ${i18n.t("myShares.sharesSumUnit")}`}
                      />
                    )}
                    {sharesInformation.shareLimits.capital && (
                      <LabelValue
                        label={i18n.t("label.shareLimits")}
                        value={`${formatCurrency(
                          sharesInformation.shareLimits.capital.min
                        )} ${sharesInformation.shareLimits.currency} ${i18n.t(
                          "label.to"
                        )} ${formatCurrency(
                          sharesInformation.shareLimits.capital.max
                        )} ${sharesInformation.shareLimits.currency}`}
                      />
                    )}
                    {sharesInformation.shareLimits.shares && (
                      <LabelValue
                        label={i18n.t("label.shareAmountLimits")}
                        value={`${formatNumber(
                          sharesInformation.shareLimits.shares.min
                        )} ${i18n.t("myShares.sharesSumUnit")} - ${formatNumber(
                          sharesInformation.shareLimits.shares.max
                        )} ${i18n.t("myShares.sharesSumUnit")}`}
                      />
                    )}
                    {sharesInformation.shareClasses.length > 0 && (
                      <LabelValue
                        label={i18n.t("label.shareClasses")}
                        value={sharesInformation.shareClasses
                          .map(
                            (shareClass) =>
                              `${shareClass.name} ${
                                shareClass.amount
                                  ? `(${formatNumber(shareClass.amount)})`
                                  : ""
                              }`
                          )
                          .join(", ")}
                      />
                    )}
                    {hasShareClassesLimits && (
                      <LabelValue
                        label={i18n.t("label.shareClassesLimits")}
                        value={shareClassWithLimits
                          .map(
                            (shareClass) =>
                              `${shareClass.name} ${
                                shareClass.shareLimits
                                  ? `(${formatNumber(
                                      shareClass.shareLimits.min
                                    )} ${i18n.t(
                                      "myShares.sharesSumUnit"
                                    )} - ${formatNumber(
                                      shareClass.shareLimits.max
                                    )} ${i18n.t("myShares.sharesSumUnit")})`
                                  : ""
                              }`
                          )
                          .join(", ")}
                      />
                    )}
                    {sharesInformation.description && (
                      <LabelValue
                        label={i18n.t("label.comment")}
                        value={sharesInformation.description}
                      />
                    )}
                  </div>
                </Tab.Panel>
              )}
              <Tab.Panel>
                <div className="tw-space-y-4">
                  <LabelValue
                    label={i18n.t("label.companyName")}
                    value={companyInfo.name}
                  />
                  <LabelValue
                    label={i18n.t("label.organizationId")}
                    value={formatRefId({
                      refId: companyInfo.orgNumber,
                      countryCode: companyInfo.countryCode ?? "",
                      type: "Company",
                    })}
                  />
                  {companyInfo?.countryCode && (
                    <LabelValue
                      label={i18n.t("label.country")}
                      value={getCountryName(companyInfo.countryCode)}
                    />
                  )}
                  <LabelValue
                    label={i18n.t("label.description")}
                    value={<FormatText text={companyInfo.description ?? ""} />}
                  />
                  {companyInfo.contact?.address && (
                    <LabelValue
                      label={i18n.t("label.address")}
                      value={`${companyInfo.contact.address.line1}${
                        companyInfo.contact.address.line2
                          ? ` ${companyInfo.contact.address.line2}`
                          : ""
                      }, ${companyInfo.contact.address.postalCode} ${
                        companyInfo.contact.address.city
                      } ${
                        companyInfo?.countryCode
                          ? getCountryName(companyInfo?.countryCode)
                          : ""
                      }`}
                    />
                  )}
                  {companyInfo.contact?.email && (
                    <LabelValue
                      label={i18n.t("label.email")}
                      value={companyInfo.contact.email}
                    />
                  )}
                  {companyInfo.authorizedSignatory?.clearText && (
                    <LabelValue
                      label={i18n.t("label.signatory")}
                      value={
                        <FormatText
                          text={companyInfo.authorizedSignatory.clearText}
                        />
                      }
                    />
                  )}
                  {companyInfo.formationDate && (
                    <LabelValue
                      label={i18n.t("label.formationDate")}
                      value={getFormattedDate(companyInfo.formationDate)}
                    />
                  )}
                  {companyInfo.registrationDate && (
                    <LabelValue
                      label={i18n.t("label.registrationDate")}
                      value={getFormattedDate(companyInfo.registrationDate)}
                    />
                  )}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </>
      )}
    </div>
  );
};

export { CompanyInformation };
