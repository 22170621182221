import { useTranslation } from "react-i18next";

import { DistributionMethod } from "../../pages/CompanyShares/Distribution.utils";
import { FormGroup, FormLabel } from "../design-system/FormGroup";
import { InfoIcon } from "../design-system/icons";
import {
  Option as SelectOption,
  OptionProps,
  Select,
} from "../design-system/Select";
import { TooltipV2 } from "../design-system/Tooltip/TooltipV2";

type DistribtionOption = {
  value: DistributionMethod;
  label: string;
  description?: string;
};

const DistributionOption = (props: OptionProps<DistribtionOption, false>) => {
  const { data } = props;

  return (
    <SelectOption {...props}>
      <div>{data.label}</div>
      {data.description && (
        <div className="tw-text-sm tw-text-secondary">{data.description}</div>
      )}
    </SelectOption>
  );
};

type DistributionSelectProps = {
  distributionMethod: DistributionMethod;
  setDistributionMethod: (value: DistributionMethod) => void;
};

const DistributionSelect = ({
  distributionMethod,
  setDistributionMethod,
}: DistributionSelectProps) => {
  const i18n = useTranslation();
  const distributionMethodOptions: DistribtionOption[] = [
    { label: i18n.t("distribution.manual"), value: "manual" },
    {
      label: i18n.t("distribution.saintLague"),
      description: i18n.t("distribution.saintLague.description"),
      value: "saintLague",
    },
    {
      label: i18n.t("distribution.modifiedSaintLague1.2"),
      description: i18n.t("distribution.modifiedSaintLague1.2.description"),
      value: "modifiedSaintLague1.2",
    },
    {
      label: i18n.t("distribution.modifiedSaintLague1.4"),
      description: i18n.t("distribution.modifiedSaintLague1.4.description"),
      value: "modifiedSaintLague1.4",
    },
    {
      label: i18n.t("distribution.dHondt"),
      description: i18n.t("distribution.dHondt.description"),
      value: "dHondt",
    },
  ];

  return (
    <FormGroup>
      <FormLabel
        htmlFor="distribution"
        className="tw-flex tw-items-center tw-gap-2"
      >
        {i18n.t("label.distribution")}
        <div className="tw-hidden md:tw-block">
          <TooltipV2
            content={i18n.t("distribution.description")}
            placement="right"
          >
            <InfoIcon />
          </TooltipV2>
        </div>
      </FormLabel>
      <Select
        id="distribution"
        value={distributionMethodOptions.find(
          (v) => v.value === distributionMethod
        )}
        components={{ Option: DistributionOption }}
        onChange={(v) => v?.value && setDistributionMethod(v?.value)}
        options={distributionMethodOptions}
      />
    </FormGroup>
  );
};

export { DistributionSelect };
