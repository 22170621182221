import { Dispatch, SetStateAction, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import {
  useLedgerQuery,
  useShareblocksQuery,
  useShareholderBlocksQuery,
} from "../../../api/blockchain/company";
import { useEntitiesQuery } from "../../../api/rest/entities";
import { useTransferSharesRange } from "../../../api/rest/events";
import { EventFormData } from "../../../components/AddEvents/EventsWizard.utils";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { Input } from "../../../components/design-system/Input";
import { SelectEntity } from "../../../components/design-system/SelectEntity";
import { AddEntity } from "../../../components/Entities/AddEntity";
import { InfoText } from "../../../components/InfoText";
import {
  SelectMultiRange,
  ShareRangeErrors,
} from "../../../components/SelectMultiRange";
import useLatestVersion from "../../../hooks/useLatestVersion";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import { ShareRangeSchema } from "../../../types/models/shares";
import { dateToIsoString } from "../../../utils/date";
import {
  hasOverlappingRanges,
  sumRanges,
  validRanges,
} from "../../../utils/shares";
import { getSelectedBlocksByTypeFromShareholders } from "../CompanyShares.utils";
import { CapitalAmount } from "../IssueShares/CapitalAmount";

const TransferByRangeFormSchema = z.object({
  date: z.string(),
  purchasePrice: z.number().optional(),
  recipientId: z.string(),
  shareRanges: z.array(ShareRangeSchema),
});

type FormProps = z.infer<typeof TransferByRangeFormSchema>;

type TransferSharesProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  formId: string;
  onSuccess: (eventId: string) => void;
  setFormData: Dispatch<SetStateAction<EventFormData>>;
};

const TransferRange = ({
  currentCompany,
  formId,
  onSuccess,
  setFormData,
}: TransferSharesProps) => {
  const i18n = useTranslation();
  const form = useForm<FormProps>({
    mode: "onChange",
    defaultValues: {
      shareRanges: [
        {
          start: null,
          end: null,
          type: new Date().toISOString(),
        },
      ],
    },
  });

  const formValues = form.watch();
  const orgNumber = currentCompany.orgNumber || "";

  const shareholdersQuery = useShareholderBlocksQuery(orgNumber);
  const allEntitiesQuery = useEntitiesQuery(orgNumber);
  const allEntities = allEntitiesQuery.data || [];
  const shareHolders = shareholdersQuery.data || [];
  const shareBlockQuery = useShareblocksQuery(orgNumber, "");
  const shareBlocks = shareBlockQuery.data || [];

  const ledgerQuery = useLedgerQuery(currentCompany.orgNumber, "");
  const lastEventDate = useLatestVersion();

  const transferSharesRangeMutation = useTransferSharesRange(
    currentCompany.orgNumber,
    { onSuccess: (eventId) => onSuccess(eventId) }
  );

  const onSubmit = (data: FormProps) => {
    const shareRangeErrors = data.shareRanges.map((range) => {
      const matchingBlock = shareBlocks.find(
        (x) =>
          range.start &&
          range.end &&
          x.start <= range.start &&
          x.end >= range.end
      );
      if (!matchingBlock) {
        return i18n.t("error.validation.blocks.overlaps");
      }
      if (matchingBlock.cancelled) {
        return i18n.t("error.verification.shares.cancelled");
      }
      if (matchingBlock.holder.id === data.recipientId) {
        return i18n.t("error.validation.recipient");
      }
      return null;
    });
    if (shareRangeErrors.some((x) => x)) {
      for (let i = 0; i < shareRangeErrors.length; i++) {
        const error = shareRangeErrors[i];
        if (error !== null) {
          form.setError(`shareRanges.${i}.start`, {
            type: "manual",
            message: error,
          });
          form.setError(`shareRanges.${i}.end`, {
            type: "manual",
            message: error,
          });
        }
      }
      return;
    }

    transferSharesRangeMutation.mutate({
      date: data.date,
      purchasePrice: data.purchasePrice,
      targetIdentifier: data.recipientId,
      shareRanges: validRanges(data.shareRanges).map((r) => ({
        start: r.start,
        end: r.end,
      })),
    });
  };

  useEffect(() => {
    setFormData((data) => ({
      ...data,
      isLoading: transferSharesRangeMutation.isLoading,
    }));
  }, [transferSharesRangeMutation.isLoading, setFormData]);

  useEffect(() => {
    const selectedTypes: string[] = [];
    if (formValues.shareRanges?.length > 0) {
      const validShareRanges = validRanges(formValues.shareRanges);
      const selectedBlocks = getSelectedBlocksByTypeFromShareholders(
        validShareRanges,
        shareHolders
      );
      selectedTypes.push(...Object.keys(selectedBlocks));
    }
    setFormData((data) => ({ ...data, selectedShareTypes: selectedTypes }));
  }, [formValues.shareRanges, shareHolders.length, setFormData]);

  return (
    <form
      className="tw-space-y-6"
      onSubmit={form.handleSubmit(onSubmit)}
      id={formId}
    >
      <FormGroup>
        <InfoText content={i18n.t("events.transfer.form.date.tooltip")}>
          <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
        </InfoText>
        <Controller
          control={form.control}
          render={({ field: { ref, name, onChange, value }, fieldState }) => (
            <>
              <Input
                id="date"
                value={value}
                ref={ref}
                name={name}
                onChange={onChange}
                type="date"
                className="tw-w-full"
                max={dateToIsoString(new Date())}
                min={lastEventDate && dateToIsoString(lastEventDate.date)}
              />
              <FormError>{fieldState.error?.message}</FormError>
            </>
          )}
          name="date"
          rules={{ required: i18n.t("error.validation.required") }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel htmlFor="purchasePrice" isOptional>
          {i18n.t("label.purchasePrice")}
        </FormLabel>
        <Controller
          control={form.control}
          name="purchasePrice"
          render={({ field: { onChange }, fieldState }) => (
            <>
              <CapitalAmount
                currency={currentCompany.settings?.currency}
                totalShares={sumRanges(formValues.shareRanges)}
                onChange={onChange}
              />
              <FormError>{fieldState.error?.message}</FormError>
            </>
          )}
        />
      </FormGroup>
      <Controller
        control={form.control}
        render={({ field: { onChange, value }, fieldState }) => (
          <FormGroup>
            <SelectMultiRange
              existingShareBlocks={shareBlocks}
              entities={allEntities}
              value={value}
              onChange={onChange}
              fieldKey="shareRanges"
              register={form.register}
              errors={
                (form.formState.errors.shareRanges as ShareRangeErrors) || {}
              }
            />
            <FormError>{fieldState.error?.message}</FormError>
          </FormGroup>
        )}
        name="shareRanges"
        rules={{
          validate: (value) => {
            const totalShares = ledgerQuery.data?.shares.total || 1;
            const ranges = validRanges(value);
            if (ranges.length === 0) {
              return i18n.t("error.validation.required");
            }
            const rangeTotal = sumRanges(ranges);
            if (rangeTotal > totalShares) {
              return i18n.t("error.validation.range.max", {
                max: totalShares,
              });
            }
            if (hasOverlappingRanges(ranges)) {
              return i18n.t("error.validation.overlaps");
            }

            return true;
          },
        }}
      />
      <FormGroup>
        <Controller
          control={form.control}
          render={({ field: { onChange, value }, fieldState }) => (
            <>
              <div className="tw-flex tw-justify-between">
                <div className="tw-flex tw-items-end">
                  <FormLabel htmlFor="recipient">
                    {i18n.t("label.recipient")}
                  </FormLabel>
                </div>
                <div>
                  <AddEntity
                    currentCompany={currentCompany}
                    onSuccess={(newEntity) => {
                      allEntitiesQuery.refetch();
                      onChange(newEntity.id);
                    }}
                  />
                </div>
              </div>

              <SelectEntity
                id="recipient"
                options={allEntities}
                value={value}
                onChange={onChange}
                shareBlocks={shareHolders}
              />
              <FormError>{fieldState.error?.message}</FormError>
            </>
          )}
          name="recipientId"
          rules={{ required: i18n.t("error.validation.required") }}
        />
      </FormGroup>
      {transferSharesRangeMutation.error && (
        <FormErrorList
          error={transferSharesRangeMutation.error}
          className="tw-py-2"
        />
      )}
    </form>
  );
};

export default TransferRange;
export type { FormProps };
