import { useTranslation } from "react-i18next";

import { useUpdateRestrictionCaseStatus } from "../../../../api/rest/restriction-case";
import { Button } from "../../../../components/design-system/Button";
import { Dialog } from "../../../../components/design-system/Dialog";
import { FormErrorList } from "../../../../components/design-system/FormGroup";
import { notify } from "../../../../components/design-system/Notifications";

type RestrictionCaseStatusDialogProps = {
  orgNumber: string;
  caseId: string;
  status: "ACTIVE" | "ARCHIVED";
  onClose: () => void;
};

const RestrictionCaseStatusDialog = ({
  orgNumber,
  caseId,
  status,
  onClose,
}: RestrictionCaseStatusDialogProps) => {
  const i18n = useTranslation();
  const mutation = useUpdateRestrictionCaseStatus(orgNumber, caseId, {
    onSuccess: () => {
      notify(i18n.t("restrictionCase.status.update"), { type: "success" });
      onClose();
    },
  });

  return (
    <Dialog
      isOpen
      title={
        status === "ACTIVE"
          ? i18n.t("restrictionCase.status.archived.title")
          : i18n.t("restrictionCase.status.active.title")
      }
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} disabled={mutation.isLoading}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            variant="solid"
            color="primary"
            isLoading={mutation.isLoading}
            onClick={() =>
              mutation.mutate(status === "ACTIVE" ? "ARCHIVED" : "ACTIVE")
            }
          >
            {status === "ACTIVE"
              ? i18n.t("restrictionCase.status.archived.update")
              : i18n.t("restrictionCase.status.active.update")}
          </Button>
        </>
      }
      isLoading={mutation.isLoading}
    >
      <p>
        {status === "ACTIVE"
          ? i18n.t("restrictionCase.status.archived.description")
          : i18n.t("restrictionCase.status.active.description")}
      </p>
      {mutation.error && <FormErrorList error={mutation.error} />}
    </Dialog>
  );
};

export { RestrictionCaseStatusDialog };
