import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import { clsxm } from "../../../utils/tailwind";
import { Description } from "../Description";
import { ChevronDownIcon, ChevronUpIcon } from "../icons";
import { Column } from "./Column";

type ColumnProps = {
  name: string;
  title: string | JSX.Element;
  key?: boolean;
  compact?: boolean;
  className?: string;
  colspan?: number;
  sortable?: boolean;
  onClick?: () => void;
};

type RowData = {
  key: string;
  link?: string;
  [key: string]: JSX.Element | JSX.Element[] | string | undefined;
};

type TableProps = React.HTMLAttributes<HTMLSpanElement> & {
  columns: ColumnProps[];
  data: RowData[];
  sortBy?: string;
  setSortBy?: (value: string) => void;
  expandedRows?: Record<number, boolean>;
  setExpandedRows?: (data: Record<number, boolean>) => void;
};

const Card = ({
  columns,
  item,
  expanded,
  setExpanded,
}: {
  columns: ColumnProps[];
  item: RowData;
  expanded: boolean;
  setExpanded: (value: boolean) => void;
}) => {
  const navigate = useNavigate();
  const hasChildContent =
    columns.filter((c) => !c.key && item[c.name]).length > 0;
  return (
    <button
      type="button"
      id="mobile"
      className="tw-relative tw-w-full tw-rounded tw-p-4 tw-text-left"
      style={{
        boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.08)",
      }}
      onClick={() => {
        if (item.link) {
          navigate(item.link);
        } else if (hasChildContent) {
          setExpanded(!expanded);
        }
      }}
    >
      <div
        className={clsxm(
          "tw-flex tw-flex-col tw-gap-0 tw-transition-all tw-duration-500 tw-ease-in-out",
          { "tw-gap-4": expanded }
        )}
      >
        <div className="tw-flex tw-items-center tw-justify-between">
          {columns
            .filter((c) => c.key)
            .map(({ name }) => (
              <div key={name}>{item[name]}</div>
            ))}
        </div>
        <div
          className={clsxm(
            "tw-flex tw-max-h-0 tw-flex-col tw-gap-6 tw-overflow-hidden tw-transition-all tw-duration-500 tw-ease-in-out",
            { "tw-max-h-[1000px]": expanded }
          )}
        >
          {columns
            .filter((c) => !c.key && item[c.name])
            .map(({ name, title }) =>
              title ? (
                <Description
                  key={name}
                  title={title}
                  description={item[name]}
                  theme="grayBlack"
                />
              ) : (
                item[name]
              )
            )}
        </div>
      </div>
    </button>
  );
};

const Row = ({
  columns,
  item,
  expanded,
  setExpanded,
}: {
  columns: ColumnProps[];
  item: RowData;
  expanded: boolean;
  setExpanded: (value: boolean) => void;
}) => {
  const navigate = useNavigate();
  const hasChildContent =
    columns.filter((c) => !c.key && item[c.name]).length > 0;
  return (
    <>
      <tr
        className={clsxm("tw-border-t", {
          "tw-cursor-pointer hover:tw-bg-neutral-50":
            item.link || hasChildContent,
        })}
        onClick={() => {
          if (item.link) {
            navigate(item.link);
          } else if (hasChildContent) {
            setExpanded(!expanded);
          }
        }}
      >
        {columns
          .filter((c) => c.key)
          .map(({ name }) => (
            <td className="tw-p-4 tw-font-normal" key={name}>
              {item[name]}
            </td>
          ))}
        {hasChildContent && (
          <td className="tw-p-4 tw-text-right">
            <button
              type="button"
              onClick={() => setExpanded(!expanded)}
              data-testid="expand-row-button"
              className="tw-align-bottom"
            >
              {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </button>
          </td>
        )}
      </tr>
      {expanded && hasChildContent && (
        <tr>
          <td colSpan={columns.filter((c) => c.key).length + 1}>
            <div className="tw-flex tw-flex-col tw-gap-6 tw-p-4">
              {columns
                .filter((c) => !c.key && item[c.name])
                .map(({ name, title }) =>
                  title ? (
                    <Description
                      key={name}
                      title={title}
                      description={item[name]}
                      theme="grayBlack"
                    />
                  ) : (
                    item[name]
                  )
                )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const TableV2 = ({
  id,
  columns,
  data,
  sortBy,
  setSortBy,
  expandedRows = [],
  setExpandedRows = () => {},
}: TableProps) => {
  const i18n = useTranslation();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const indexes = data.map((_, i) => i);
  const allExpanded = indexes.every((i) => !!expandedRows[i]);

  const setExpandedRow = (index: number, value: boolean) => {
    setExpandedRows({ ...expandedRows, [index]: value });
  };

  const toggleExpandAll = () => {
    setExpandedRows(
      indexes.reduce(
        (acc, item) => ({ ...acc, [item]: !allExpanded }),
        {} as Record<number, boolean>
      )
    );
  };

  if (isTabletOrMobileDevice) {
    return (
      <div className="tw-flex tw-flex-col tw-gap-2">
        {columns.filter((c) => c.key).length > 1 && (
          <div className="tw-flex tw-justify-between">
            {columns
              .filter((c) => c.key)
              .map(({ name, title, onClick }) => (
                <button
                  className="tw-text-sm tw-text-secondary"
                  key={name}
                  onClick={() => {
                    if (onClick) {
                      onClick();
                    }
                  }}
                  type="button"
                >
                  {title}
                </button>
              ))}
          </div>
        )}
        {data.map((item, i) => (
          <Card
            columns={columns}
            item={item}
            key={item.key}
            expanded={!!expandedRows[i]}
            setExpanded={(value) => setExpandedRow(i, value)}
          />
        ))}
      </div>
    );
  }

  return (
    <div className="tw-relative" data-testid={id}>
      <table className="tw-w-full">
        <thead className="tw-text-left">
          <tr className="tw-border-b">
            {columns
              .filter((c) => c.key)
              .map(
                ({
                  name,
                  title,
                  sortable,
                  colspan,
                  compact,
                  className,
                  onClick,
                }) => (
                  <th
                    scope="col"
                    className={clsxm("tw-px-4 tw-py-2 tw-font-normal", {
                      "tw-w-0": compact,
                    })}
                    key={name}
                    colSpan={colspan || 1}
                  >
                    <div className={className}>
                      {name && (
                        <Column
                          title={title}
                          sortDesc={sortBy === `${name}-desc`}
                          sortAsc={sortBy === `${name}-asc`}
                          onClick={() => {
                            if (sortable !== false && setSortBy) {
                              setSortBy(
                                sortBy === `${name}-asc`
                                  ? `${name}-desc`
                                  : `${name}-asc`
                              );
                            }
                            if (onClick) {
                              onClick();
                            }
                          }}
                          clickable={
                            sortable !== false || onClick !== undefined
                          }
                          sortable={sortable !== undefined ? sortable : true}
                        />
                      )}
                    </div>
                  </th>
                )
              )}
            {data.length > 0 && columns.some((c) => !c.key) && (
              <th className="tw-w-28 tw-font-normal">
                <button
                  type="button"
                  onClick={toggleExpandAll}
                  className="tw-h-full tw-w-full tw-text-sm tw-text-secondary"
                >
                  {allExpanded
                    ? i18n.t("label.collapseAll")
                    : i18n.t("label.expandAll")}
                </button>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <Row
              columns={columns}
              item={item}
              key={item.key}
              expanded={!!expandedRows[i]}
              setExpanded={(value) => setExpandedRow(i, value)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { TableV2 };
export type { TableProps };
