import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useShareblocksQuery } from "../../../api/blockchain/company";
import { useEntitiesQuery } from "../../../api/rest/entities";
import { useShareCertificatesUpdateMutation } from "../../../api/rest/events";
import { EventFormProps } from "../../../components/AddEvents/EventsWizard.utils";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { Input } from "../../../components/design-system/Input";
import { SelectShareBlocks } from "../../../components/SelectShareBlocks";
import useLatestVersion from "../../../hooks/useLatestVersion";
import type { Shareblock } from "../../../types/models/shares";
import { dateToIsoString } from "../../../utils/date";

type FormProps = { date: string; shareRanges: Shareblock[] };

const shareCertificatesUpdateFormId = "share-certificates-update-form";

const ShareCertificateUpdate = ({
  currentCompany,
  onSuccess,
  setFormData,
}: EventFormProps) => {
  const i18n = useTranslation();
  const shareBlocksQuery = useShareblocksQuery(currentCompany.orgNumber, "");
  const form = useForm<FormProps>({
    mode: "onChange",
    defaultValues: {
      shareRanges: (shareBlocksQuery.data || []).filter(
        (block) => !!block.hasCertificateSince
      ),
    },
  });

  const shareBlockOptions = (shareBlocksQuery.data || []).filter(
    (block) => !block.cancelled
  );
  const lastEventDate = useLatestVersion();
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const entitiesData = entitiesQuery.data || [];
  const entitiesMap = Object.fromEntries(entitiesData.map((e) => [e.id, e]));
  const ledgerHasCert = shareBlockOptions.some(
    (block) => !!block.hasCertificateSince
  );

  const certifiedShareBlocks = shareBlockOptions.filter(
    (blocks) => blocks.hasCertificateSince !== null
  );
  const selectedShareBlocks = form.watch("shareRanges");

  const areSelectedSharesCertified =
    selectedShareBlocks.length === certifiedShareBlocks.length &&
    selectedShareBlocks.every((selectedShare) =>
      certifiedShareBlocks.some(
        (certifiedShare) =>
          certifiedShare.start === selectedShare.start &&
          certifiedShare.end === selectedShare.end
      )
    );

  const mutation = useShareCertificatesUpdateMutation(
    currentCompany.orgNumber,
    { onSuccess: (eventId) => onSuccess(eventId) }
  );

  useEffect(() => {
    setFormData((data) => ({ ...data, formId: shareCertificatesUpdateFormId }));
  }, [setFormData]);

  useEffect(() => {
    setFormData((data) => ({
      ...data,
      loading: mutation.isLoading,
    }));
  }, [mutation.isLoading, setFormData]);

  useEffect(() => {
    setFormData((data) => ({
      ...data,
      selectedShareTypes: selectedShareBlocks.map((block) => block.type),
    }));
  }, [selectedShareBlocks, setFormData]);

  const handleSubmit = (data: FormProps) => {
    if (!ledgerHasCert && selectedShareBlocks.length === 0) {
      form.setError("shareRanges", {
        type: "manual",
        message: i18n.t("error.verification.certificate.none"),
      });
      return;
    }
    if (areSelectedSharesCertified) {
      form.setError("shareRanges", {
        type: "manual",
        message: i18n.t("error.verification.certificate.alreadyIssued"),
      });
      return;
    }
    mutation.mutate({
      date: data.date,
      shareRanges: data.shareRanges.map((range) => ({
        start: range.start,
        end: range.end,
      })),
    });
  };

  return (
    <div>
      <header className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h4>{i18n.t("events.shareCertificateUpdate.title")}</h4>
        </div>
      </header>
      <form
        id={shareCertificatesUpdateFormId}
        className="tw-space-y-6"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormGroup>
          <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
          <Controller
            control={form.control}
            render={({ field: { ref, name, onChange, value }, fieldState }) => (
              <>
                <Input
                  id="date"
                  value={value}
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  type="date"
                  className="tw-w-full"
                  max={dateToIsoString(new Date())}
                  min={lastEventDate && dateToIsoString(lastEventDate.date)}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            name="date"
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="shareRanges">
            {i18n.t("shares.certificates")}
          </FormLabel>
          <Controller
            name="shareRanges"
            control={form.control}
            render={({ field: { onChange, value }, fieldState }) => (
              <>
                <SelectShareBlocks
                  options={shareBlockOptions}
                  value={value}
                  onChange={onChange}
                  entitiesMap={entitiesMap}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
          />
        </FormGroup>
        {mutation.error && <FormErrorList error={mutation.error} />}
      </form>
    </div>
  );
};

export default ShareCertificateUpdate;
