import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { convertErrorToTi18nKey } from "../../api";
import {
  useLedgerQuery,
  useShareblocksQuery,
  useShareholdersQuery,
  useShareTypesQuery,
} from "../../api/blockchain/company";
import { useParentEventsQuery } from "../../api/blockchain/events";
import { useOptionsProgramQuery } from "../../api/blockchain/options";
import { useEntitiesQuery } from "../../api/rest/entities";
import { ShareholdersBottomBar } from "../../components/design-system/BottomBar/ShareholdersBottomBar";
import { Chip } from "../../components/design-system/Chip";
import { InfoIcon, SearchIcon } from "../../components/design-system/icons";
import { Input } from "../../components/design-system/Input";
import { Loading } from "../../components/design-system/Loading";
import { Toggle } from "../../components/design-system/Toggle";
import { getEntityWithFallback } from "../../components/EventList/EventsTable/EventsTable.utils";
import { ExportMenuShareholders } from "../../components/ExportMenu";
import { NoData } from "../../components/NoData";
import { PageWrapper } from "../../components/PageWrapper";
import { SelectVersion } from "../../components/SelectVersion";
import { getTotalSharesByType } from "../../components/ShareBlocks/ShareBlocks.utils";
import { SharesPrint } from "../../components/SharesPrint";
import { ShareView } from "../../components/ShareView";
import { useSession } from "../../context/session";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import type { Entity } from "../../types/models/entities";
import { OptionsProgram } from "../../types/models/options";
import { Shareholder } from "../../types/models/shares";
import { isGreaterLedgerVersion } from "../../utils/date";
import * as monitoring from "../../utils/monitoring";
import { hasRequiredPermission } from "../../utils/permissions";
import { clsxm } from "../../utils/tailwind";
import {
  getDilutionTotals,
  getShareHoldersWithDilution,
  ShareHolderWithDilution,
} from "./ShareHolders.utils";
import {
  ShareholdersTable,
  SortBy,
} from "./ShareholdersTable/ShareholdersTable";
import type { LedgerVersionDetails } from "./useLedgerVersions";

type Props = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  ledgerVersions: LedgerVersionDetails[];
  selectedVersion?: LedgerVersionDetails;
  setSelectedVersion: (version?: LedgerVersionDetails) => void;
};

export const CLASS_CELL_GAP = "tw-gap-6";

const shareHolderMatchSearchValue = (
  shareHolder: Shareholder & { entity: Entity },
  searchValue?: string
) => {
  if (!searchValue || searchValue.length < 1) {
    return true;
  }
  const { entity } = shareHolder;

  return (
    entity.name.toLowerCase().includes(searchValue.toLowerCase()) ||
    entity.refId.toLowerCase().includes(searchValue.toLowerCase()) ||
    Object.keys(shareHolder.shareTypes).some((share_type) =>
      share_type.toLowerCase().includes(searchValue.toLowerCase())
    )
  );
};

const OptionsProgramFilter = ({
  optionsPrograms,
  selectedOptionsPrograms,
  setSelectedOptionsPrograms,
  className,
}: {
  optionsPrograms: OptionsProgram[];
  selectedOptionsPrograms: string[];
  setSelectedOptionsPrograms: Dispatch<SetStateAction<string[]>>;
  className?: string;
}) => (
  <div className={clsxm("tw-flex tw-flex-wrap tw-gap-2", className)}>
    {optionsPrograms.map((p) => {
      return (
        <Chip
          key={p.title}
          isActive={selectedOptionsPrograms.includes(p.id!)}
          onClick={() =>
            setSelectedOptionsPrograms((prevState) => {
              if (prevState.includes(p.id!)) {
                return prevState.filter((item) => item !== p.id);
              }
              return [...prevState, p.id!];
            })
          }
        >
          {p.title}
        </Chip>
      );
    })}
  </div>
);

const Shareholders = ({
  currentCompany,
  ledgerVersions,
  selectedVersion,
  setSelectedVersion,
}: Props) => {
  const i18n = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState<SortBy>("shares-desc");
  const [displayDiluted, setDisplayDiluted] = useState(false);
  const [selectedOptionsProgramIds, setSelectedOptionsProgramsIds] = useState<
    string[]
  >([]);
  const { user } = useSession();
  const canAddView = hasRequiredPermission(
    "Administrator",
    currentCompany,
    user
  );

  const ledgerQuery = useLedgerQuery(
    currentCompany.orgNumber,
    selectedVersion?.formatedValue
  );

  const shareHoldersQuery = useShareholdersQuery(
    currentCompany.orgNumber,
    selectedVersion?.formatedValue
  );

  const shareBlocksQuery = useShareblocksQuery(
    currentCompany.orgNumber,
    selectedVersion?.formatedValue
  );
  const shareBlocks = shareBlocksQuery.data || [];

  const shareTypesQuery = useShareTypesQuery(currentCompany.orgNumber, "");

  const shareTypesByName = useMemo<Record<string, number>>(() => {
    if (!shareTypesQuery.isSuccess || !shareTypesQuery.data) {
      return {};
    }
    return shareTypesQuery.data.reduce(
      (result, shareType) => ({
        ...result,
        [shareType.name]: shareType.voteValue,
      }),
      {}
    );
  }, [shareTypesQuery.data, shareTypesQuery.isSuccess]);

  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const entitiesData = entitiesQuery.data || [];
  const entitiesMap: Record<string, Entity> = Object.fromEntries(
    entitiesData.map((e) => [e.id, e])
  );

  const optionsProgramsQuery = useOptionsProgramQuery(currentCompany.orgNumber);
  const optionsPrograms = optionsProgramsQuery.data || [];
  const selectedOptionPrograms = optionsPrograms.filter((x) =>
    selectedOptionsProgramIds.includes(x.id!)
  );

  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const events = selectedVersion
    ? eventsQuery.data?.data.filter(
        (e) => !isGreaterLedgerVersion(e.date, selectedVersion.formatedValue)
      )
    : eventsQuery.data?.data || [];

  const {
    shareholders,
    mappedShareHolders,
    hasSplit,
  }: {
    shareholders: (Shareholder & { entity: Entity })[];
    mappedShareHolders: ShareHolderWithDilution[] | undefined;
    hasSplit: boolean;
  } = useMemo(() => {
    if (
      !shareHoldersQuery.isSuccess ||
      !entitiesQuery.isSuccess ||
      !shareTypesQuery.isSuccess
    ) {
      return {
        shareholders: [],
        mappedShareHolders: undefined,
        hasSplit: false,
      };
    }

    const shareHolders = (shareHoldersQuery.data?.shareholders || [])
      .map((shareholder) => {
        const entity = getEntityWithFallback(entitiesMap, shareholder.holder);
        if (!("type" in entity)) {
          console.error("No entity fallback found");
          monitoring.captureException(
            new TypeError("Shareholder missing in entities"),
            { contexts: { entitiesMap, holder: shareholder.holder } }
          );

          return null;
        }
        return { ...shareholder, entity };
      })
      .filter((x) => x !== null);

    if (displayDiluted) {
      const { shareHoldersWithDilution, hasSplit: split } =
        getShareHoldersWithDilution(
          shareHolders,
          selectedOptionPrograms,
          shareTypesByName,
          entitiesMap,
          events || []
        );
      const shareHoldersWithDilutionFiltered = shareHoldersWithDilution.filter(
        (x) => shareHolderMatchSearchValue(x, searchValue)
      );

      return {
        shareholders: shareHolders,
        mappedShareHolders: shareHoldersWithDilutionFiltered,
        hasSplit: split,
      };
    }

    const shareHoldersFiltered = shareHolders.filter((x) =>
      shareHolderMatchSearchValue(x, searchValue)
    );

    return {
      shareholders: shareHolders,
      mappedShareHolders: shareHoldersFiltered,
      hasSplit: false,
    };
  }, [
    entitiesQuery.isSuccess,
    entitiesMap,
    searchValue,
    shareHoldersQuery.isSuccess,
    shareHoldersQuery.data,
    shareTypesQuery.isSuccess,
    shareTypesByName,
    events,
    displayDiluted,
    selectedOptionPrograms,
  ]);

  const totalSharesByType = getTotalSharesByType(shareBlocks);

  const totalVotes = shareHoldersQuery.data?.totalVotes || 0;
  const totalShares = shareHoldersQuery.data?.totalShares || 0;
  const dilutedTotals = getDilutionTotals(
    totalShares,
    totalVotes,
    shareTypesByName,
    selectedOptionPrograms
  );

  const isLoading =
    shareHoldersQuery.isLoading ||
    entitiesQuery.isLoading ||
    shareTypesQuery.isLoading ||
    !Array.isArray(mappedShareHolders);
  const isSuccess =
    shareHoldersQuery.isSuccess &&
    entitiesQuery.isSuccess &&
    shareTypesQuery.isSuccess &&
    Array.isArray(mappedShareHolders);
  const errorCode =
    (shareHoldersQuery.error &&
      convertErrorToTi18nKey(shareHoldersQuery.error)) ||
    (entitiesQuery.error && convertErrorToTi18nKey(entitiesQuery.error)) ||
    (shareTypesQuery.error && convertErrorToTi18nKey(shareTypesQuery.error));

  const approvedLedgerVersions = ledgerVersions.filter(
    (version) => version.isApproved
  );

  const toggleDilutedView = () => {
    setSelectedOptionsProgramsIds(
      optionsPrograms
        .filter((x) => new Date(x.strikeEndDate) >= new Date())
        .map((x) => x.id!)
    );
    setDisplayDiluted(!displayDiluted);
  };

  return (
    <>
      <SharesPrint
        currentCompany={currentCompany}
        numberOfShareholders={shareHoldersQuery.data?.shareholders.length || 0}
        ledgerData={ledgerQuery.data}
        currentVersion={selectedVersion}
        approvedLedgerVersions={approvedLedgerVersions}
      />
      <PageWrapper data-testid="shares-layout">
        <div className="tw-relative">
          {errorCode && (
            <NoData
              type="error"
              title={i18n.t("error.fetch")}
              description={i18n.t(errorCode)}
            />
          )}
          {isLoading && <Loading />}
          {isSuccess && !isLoading && (
            <div className="tw-flex tw-flex-col tw-gap-4">
              <div className="tw-flex tw-justify-between max-md:tw-hidden">
                <div className="tw-flex tw-gap-2">
                  <SelectVersion
                    selectedVersion={selectedVersion}
                    availableVersions={ledgerVersions}
                    onChange={setSelectedVersion}
                  />
                  <Input
                    className="tw-h-12 tw-w-52 tw-rounded-md max-md:tw-w-full"
                    placeholder={i18n.t("label.search")}
                    prefix={<SearchIcon />}
                    type="search"
                    value={searchValue}
                    onChange={(event) => {
                      setSearchValue(event.target.value);
                    }}
                  />
                  {canAddView && (
                    <ShareView
                      orgNumber={currentCompany.orgNumber}
                      selectedVersion={selectedVersion}
                    />
                  )}
                  <ExportMenuShareholders
                    currentCompany={currentCompany}
                    ledgerVersions={ledgerVersions}
                    selectedVersion={selectedVersion}
                    optionPrograms={optionsPrograms}
                    selectedPrograms={selectedOptionsProgramIds}
                    shareholders={shareholders}
                    shareTypesByName={shareTypesByName}
                    entities={entitiesMap}
                    totals={{
                      votes: totalVotes,
                      shares: totalShares,
                      sharesByType: totalSharesByType,
                      dilutedShares: dilutedTotals.totalShares,
                      dilutedVotes: dilutedTotals.totalVotes,
                    }}
                  />
                </div>
                {optionsPrograms.length > 0 && (
                  <Toggle
                    label={i18n.t("label.diluted")}
                    className="tw-ml-2 print:tw-hidden"
                    isActive={displayDiluted}
                    onClick={() => toggleDilutedView()}
                  />
                )}
              </div>
              {displayDiluted && (
                <OptionsProgramFilter
                  optionsPrograms={optionsPrograms}
                  selectedOptionsPrograms={selectedOptionsProgramIds}
                  setSelectedOptionsPrograms={setSelectedOptionsProgramsIds}
                  className="tw-py-2 print:tw-hidden max-md:tw-hidden"
                />
              )}
              <div className="tw-flex tw-flex-col tw-gap-4">
                <div
                  id="mobile-view"
                  className="tw-flex tw-flex-col tw-gap-2 md:tw-hidden"
                >
                  <div className="tw-flex tw-flex-col tw-gap-4">
                    <Input
                      placeholder={i18n.t("label.search")}
                      prefix={<SearchIcon />}
                      type="search"
                      value={searchValue}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                      }}
                      className="tw-h-12 tw-rounded-md"
                    />
                    {optionsPrograms.length > 0 && (
                      <Toggle
                        label={i18n.t("label.diluted")}
                        className="tw-ml-2 print:tw-hidden"
                        isActive={displayDiluted}
                        onClick={() => toggleDilutedView()}
                      />
                    )}
                  </div>
                  {displayDiluted && (
                    <OptionsProgramFilter
                      optionsPrograms={optionsPrograms}
                      selectedOptionsPrograms={selectedOptionsProgramIds}
                      setSelectedOptionsPrograms={setSelectedOptionsProgramsIds}
                      className="tw-py-2 print:tw-hidden"
                    />
                  )}
                </div>
                {hasSplit && (
                  <p className="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-border tw-p-6 tw-text-gray-500">
                    <InfoIcon className="tw-h-6 tw-w-6 tw-shrink-0" />
                    <div className="tw-text-bottom tw-text-sm">
                      {i18n.t("shareholders.splitCalculation")}
                    </div>
                  </p>
                )}
                <ShareholdersTable
                  displayDiluted={displayDiluted}
                  dilutedTotals={dilutedTotals}
                  searchValue={searchValue}
                  totalShares={totalShares}
                  totalVotes={totalVotes}
                  shareholders={mappedShareHolders}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </div>
            </div>
          )}
          <ShareholdersBottomBar
            currentCompany={currentCompany}
            selectedVersion={selectedVersion}
            setSelectedVersion={setSelectedVersion}
            ledgerVersions={ledgerVersions}
            enableViewShare={canAddView}
            optionPrograms={optionsPrograms}
            selectedPrograms={selectedOptionsProgramIds}
            shareholders={shareholders}
            shareTypesByName={shareTypesByName}
            entities={entitiesMap}
            totals={{
              votes: totalVotes,
              shares: totalShares,
              sharesByType: totalSharesByType,
              dilutedShares: dilutedTotals.totalShares,
              dilutedVotes: dilutedTotals.totalVotes,
            }}
          />
        </div>
      </PageWrapper>
    </>
  );
};

export type { SortBy };
export default Shareholders;
