import "./index.css";

import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Navigate } from "react-router-dom";

import { Button } from "../../../components/design-system/Button";
import LedgerIpad from "../../../components/design-system/LedgerIpad/LedgerIpad";
import { useCurrentCompany } from "../../../context/account";
import { useSession } from "../../../context/session";
import { APP_ROUTE } from "../../../routes/constants";
import type { CompanyInformation } from "../../../types/models/administration";
import type { LoggedInUser } from "../../../types/models/auth";
import type { CompanyInvolvement } from "../../../types/models/company";
import { hasRequiredPermission } from "../../../utils/permissions";

const ONBOARDING_FIRST_STEP = "ONBOARDING_FIRST_STEP";
const ONBOARDING_SECOND_STEP = "ONBOARDING_SECOND_STEP";
type OnboardingStep =
  | typeof ONBOARDING_FIRST_STEP
  | typeof ONBOARDING_SECOND_STEP;

const OnboardingPage: React.FunctionComponent = () => {
  const currentCompany = useCurrentCompany();
  const { user } = useSession();

  if (!user || !currentCompany) {
    return null;
  }

  if (currentCompany.companyStatus === "Onboarded") {
    return (
      <Navigate
        to={`${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/shares/holders`}
      />
    );
  }

  return (
    <div>
      <Onboarding currentCompany={currentCompany} user={user} />
    </div>
  );
};

const Onboarding: React.FunctionComponent<{
  currentCompany: CompanyInvolvement | CompanyInformation;
  user: LoggedInUser;
}> = ({ currentCompany, user }) => {
  const hasRole = hasRequiredPermission("Editor", currentCompany, user);
  const [onboardingStep, setOnboardingStep] = useState<OnboardingStep>(
    ONBOARDING_FIRST_STEP
  );

  return (
    <div className="onboarding-screen tw-relative tw-flex tw-justify-center tw-overflow-hidden">
      {onboardingStep === ONBOARDING_FIRST_STEP && (
        <OnboardingFirstStep
          hasRole={hasRole}
          goToNextStep={() => setOnboardingStep(ONBOARDING_SECOND_STEP)}
        />
      )}
      {onboardingStep === ONBOARDING_SECOND_STEP && (
        <OnboardingSecondStep
          hasRole={hasRole}
          currentCompany={currentCompany}
          goBackStep={() => setOnboardingStep(ONBOARDING_FIRST_STEP)}
        />
      )}
    </div>
  );
};

const OnboardingFirstStep: React.FunctionComponent<{
  hasRole: boolean;
  goToNextStep: () => void;
}> = ({ hasRole, goToNextStep }) => {
  const i18n = useTranslation();

  return (
    <div className="tw-relative tw-grid  tw-grid-cols-1 tw-flex-row tw-items-center tw-justify-between tw-px-4 tw-py-6 max-md:tw-items-start md:tw-grid-cols-2">
      <div className="tw-ml-16 tw-mr-8 max-md:tw-ml-0">
        <h1 className="tw-pb-1">{i18n.t("onboarding.firstStep.title")}</h1>
        <h3 className="tw-pb-4">{i18n.t("onboarding.firstStep.subTitle")}</h3>
        <p className="tw-pb-20">{i18n.t("onboarding.firstStep.text")}</p>
        <Button
          color="primary"
          variant="solid"
          disabled={!hasRole}
          onClick={goToNextStep}
        >
          {i18n.t("onboarding.firstStep.button")}
        </Button>
      </div>
      <LedgerIpad />
    </div>
  );
};

const OnboardingSecondStep: React.FunctionComponent<{
  hasRole: boolean;
  currentCompany: CompanyInvolvement | CompanyInformation;
  goBackStep: () => void;
}> = ({ hasRole, currentCompany, goBackStep }) => {
  const i18n = useTranslation();

  return (
    <div className="tw-grid  tw-items-center tw-justify-center tw-gap-32 tw-px-4 tw-py-6 max-md:tw-items-start">
      <div className="tw-grid tw-grid-cols-1 tw-grid-rows-2 tw-gap-20">
        <div className="tw-grid tw-grid-cols-1 tw-items-center tw-gap-12 md:tw-grid-cols-2 md:tw-gap-32">
          <div className="tw-flex-1">
            <h1 className="tw-pb-2 tw-text-4xl">
              {i18n.t("onboarding.secondStep.title")}
            </h1>
            <p>
              {i18n.t("onboarding.secondStep.textBeforeLink")}{" "}
              <a
                href="https://www.bolagsverket.se"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline" }}
              >
                {i18n.t("onboarding.secondStep.linkText")}
              </a>{" "}
              {i18n.t("onboarding.secondStep.textAfterLink")}
            </p>
          </div>
          <div className="tw-flex-1">
            <div className="tw-flex tw-flex-col tw-gap-12">
              <div className="tw-grid tw-grid-cols-[1fr_10fr]">
                <h4>{1}.</h4>
                <div>
                  <h4>{i18n.t("onboarding.secondStep.cardTitle1")}</h4>
                  <p className="tw-text-sm tw-text-neutral-500">
                    {i18n.t("onboarding.secondStep.cardText1")}
                  </p>
                </div>
              </div>
              <div className="tw-grid tw-grid-cols-[1fr_10fr]">
                <h4>{2}.</h4>
                <div>
                  <h4>{i18n.t("onboarding.secondStep.cardTitle2")}</h4>
                  <p className="tw-text-sm tw-text-neutral-500">
                    {i18n.t("onboarding.secondStep.cardText2")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-flex tw-justify-end tw-gap-2">
          <Button
            color="secondary"
            variant="outline"
            disabled={!hasRole}
            onClick={goBackStep}
          >
            {i18n.t("label.back")}
          </Button>
          <Link
            to={`${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/shares/setup`}
          >
            <Button color="primary" variant="solid" disabled={!hasRole}>
              {i18n.t("label.continue")}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export { Onboarding, OnboardingFirstStep, OnboardingPage };
