import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { useBlockchainClient } from "../../context/blockchain";
import { isNonEmptyArray } from "../../types";
import type {
  ShareAllocationEvent as ChildEvent,
  TParentEvent as ParentEvent,
} from "../../types/models/events";
import type { LedgerVersion } from "../../types/models/shares";
import type { IRequestError } from "..";
import { unmaskValues } from "../rest/company";
import type { UseQueryOptions } from "../types";
import { BlockchainClient } from "./client";

type Pagination = { hasNextPage: boolean };

export type ParentEventStatus =
  | "Approved"
  | "Pending"
  | "PendingRollback"
  | "Draft";

type ParentEventsQueryResponse = {
  data: { payload: ParentEvent; status: ParentEventStatus }[];
  pagination: Pagination;
};

export type ParentEventWithStatus = ParentEvent & {
  status: ParentEventStatus;
};

export type ParentEventsResponse = {
  data: ParentEventWithStatus[];
  pagination: Pagination;
};

export type Snapshot = {
  date: LedgerVersion;
  status: ParentEventStatus;
};

const getChildEvents = (
  blockchainClient: BlockchainClient,
  parentId: string
): Promise<ChildEvent[]> =>
  blockchainClient.query<ChildEvent[]>("events.get_children", {
    parent_id: parentId,
  });

const getParentEvents = (
  blockchainClient: BlockchainClient,
  orgNumber: string,
  offset: number,
  limit: number
): Promise<ParentEventsQueryResponse> =>
  blockchainClient.query<ParentEventsQueryResponse>("events.get_parents", {
    org_number: orgNumber,
    skip: offset,
    take: limit,
  });

const getVersions = (blockchainClient: BlockchainClient, orgNumber: string) =>
  blockchainClient.query<Snapshot[]>("ledger.get_available_snapshots", {
    org_number: orgNumber,
  });

const extractRefIdFromUnmaskedValue = (
  unmaskedValue: string | undefined
): string | undefined => unmaskedValue?.split(":").pop();

const unmaskRefId = <T extends { refId: string }>(
  obj: T,
  refIdMap: Record<string, string>
): T & { unmaskedRefId: string | undefined } => ({
  ...obj,
  unmaskedRefId: extractRefIdFromUnmaskedValue(refIdMap[obj.refId]),
});

const useParentEventsQuery = ({
  offset,
  limit,
  orgNumber = "",
  onSuccess,
}: {
  offset: number;
  limit: number;
  orgNumber: string | undefined;
  onSuccess?: (
    data: ParentEventWithStatus[]
  ) => Promise<void> | void | undefined;
}): UseQueryResult<ParentEventsResponse, IRequestError> => {
  const blockchainClient = useBlockchainClient();

  return useQuery<
    ParentEventsResponse,
    IRequestError,
    ParentEventsResponse,
    string[]
  >(
    ["parentEvents", orgNumber, offset.toString(), limit.toString()],
    async (): Promise<ParentEventsResponse> => {
      const parentEventsQueryResponse = await getParentEvents(
        blockchainClient,
        orgNumber,
        offset,
        limit
      );

      const parentEventsResponse: ParentEventsResponse = {
        ...parentEventsQueryResponse,
        data: parentEventsQueryResponse.data.map(({ payload, status }) => ({
          ...payload,
          status,
        })),
      };

      /**
       * !IMPORTANT! We're patching the response here, because we need to have
       * the unmasked refIds later on. We're adding a property to each
       * EventEntity called "unmaskedRefId" which contains the unmasked refId
       * found in the entityMap, but more importantly, this is not added to the
       * type-definition, because we don't want to pollute it since we'll remove
       * it at a later point anyways.
       */
      const maskedRefIds = parentEventsResponse.data
        .map((parentEvent) => {
          if (parentEvent.type === "ShareTransfer") {
            return parentEvent.shares.map(({ recipient, sender }) => [
              recipient.refId,
              sender.refId,
            ]);
          }
          if (parentEvent.type === "SharePledgedUpdate") {
            return parentEvent.ranges.after.map(({ creditor }) => [
              creditor.refId,
            ]);
          }
          return [];
        })
        .flat(2);
      const uniqueMaskedIds = [...new Set(maskedRefIds)];
      const unmasked = await unmaskValues(orgNumber, uniqueMaskedIds);

      // For all ShareTransfer-events, add the unmasked refIds to the entities.
      const patchedData: ParentEventWithStatus[] =
        parentEventsResponse.data.map((parentEvent): ParentEventWithStatus => {
          if (parentEvent.type === "ShareTransfer") {
            const patchedShares = parentEvent.shares.map((share) => ({
              ...share,
              recipient: unmaskRefId(share.recipient, unmasked),
              sender: unmaskRefId(share.sender, unmasked),
            }));

            if (!isNonEmptyArray(patchedShares)) {
              return parentEvent;
            }

            const result = {
              ...parentEvent,
              shares: patchedShares,
            };

            return result;
          }

          if (parentEvent.type === "SharePledgedUpdate") {
            return {
              ...parentEvent,
              ranges: {
                after: parentEvent.ranges.after.map((range) => ({
                  ...range,
                  creditor: unmaskRefId(range.creditor, unmasked),
                })),
                before: parentEvent.ranges.before,
              },
            };
          }

          return parentEvent;
        });

      return {
        ...parentEventsResponse,
        data: patchedData,
      };
    },
    {
      enabled: !!orgNumber,
      onSuccess(data) {
        if (onSuccess) {
          onSuccess(data.data);
        }
      },
    }
  );
};

const useChildEventsQuery = (orgNumber: string, parentId: string) => {
  const blockchainClient = useBlockchainClient();

  return useQuery(
    ["childEvents", orgNumber, parentId],
    async () => {
      const childEvents = await getChildEvents(blockchainClient, parentId);
      const uniqueMaskedRefIds = Array.from(
        new Set(
          childEvents
            .map((e) =>
              e.type === "ShareAllocation" ? e.recipient.refId : null
            )
            .filter((x) => x !== null)
        )
      );
      const unmasked = await unmaskValues(orgNumber, uniqueMaskedRefIds);
      const patchedChildEvents = childEvents.map((e) => {
        if (e.type === "ShareAllocation") {
          return {
            ...e,
            recipient: {
              ...e.recipient,
              unmaskedRefId: unmaskRefId(e.recipient, unmasked).unmaskedRefId,
            },
          };
        }
        return e;
      });
      return patchedChildEvents;
    },
    { enabled: !!orgNumber && !!parentId }
  );
};

const useVersionsQuery = (
  orgNumber: string,
  options?: UseQueryOptions<unknown, IRequestError, Snapshot[], string[]>
) => {
  const blockchainClient = useBlockchainClient();

  return useQuery(
    ["versions", orgNumber],
    () => getVersions(blockchainClient, orgNumber),
    {
      enabled: options?.enabled !== undefined ? options.enabled : !!orgNumber,
      ...options,
    }
  );
};

export {
  extractRefIdFromUnmaskedValue,
  unmaskRefId,
  useChildEventsQuery,
  useParentEventsQuery,
  useVersionsQuery,
};
