import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import { InfoIcon } from "../design-system/icons";

const ContactBanner = ({
  i18nKey,
}: {
  i18nKey: "home.ledgerCard.help" | "home.assetsCard.help";
}) => {
  return (
    <div className="tw-mt-4 tw-flex tw-gap-4 tw-rounded-md tw-border tw-border-neutral-200 tw-p-6 tw-text-left tw-text-sm tw-text-secondary">
      <InfoIcon width="60" className="max-md:tw-hidden" />
      <p>
        <Trans
          components={{
            contactLink: (
              <Link
                target="_blank"
                to="https://kvanta.com/contact"
                className="tw-underline"
              />
            ),
            termsLink: (
              <Link
                target="_blank"
                to="https://kvanta.com/terms"
                className="tw-underline"
              />
            ),
            privacyPolicyLink: (
              <Link
                target="_blank"
                to="https://kvanta.com/privacy-policy"
                className="tw-underline"
              />
            ),
          }}
          i18nKey={i18nKey}
        />
      </p>
    </div>
  );
};

export { ContactBanner };
