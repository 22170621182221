import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { useShareholdersQuery } from "../../../api/blockchain/company";
import { ShareHolderWithDilution } from "../../../pages/CompanyShares/ShareHolders.utils";
import { useLedgerVersions } from "../../../pages/CompanyShares/useLedgerVersions";
import { CompanyInformation } from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import { EntitiesMap, Entity } from "../../../types/models/entities";
import { Shareholder } from "../../../types/models/shares";
import { formatNumber, formatPercentage } from "../../../utils/format";
import { clsxm } from "../../../utils/tailwind";
import { Badge } from "../../design-system/Badge";
import { Description } from "../../design-system/Description";
import { EntityItem } from "../../design-system/EntityItem";
import { Loading } from "../../design-system/Loading";
import { Tab } from "../../design-system/Tab";
import { TableV2 } from "../../design-system/TableV2";
import { getEntityWithFallback } from "../../EventList/EventsTable/EventsTable.utils";

type ShareholdersComparisonProps = {
  currentCompany: CompanyInformation | CompanyInvolvement;
  entitiesMap: EntitiesMap;
};

const getShareholderWithEntity = (
  entitiesMap: EntitiesMap,
  shareholder: Shareholder
): ShareHolderWithDilution | null => {
  const entity = getEntityWithFallback(entitiesMap, shareholder.holder);

  if (!("type" in entity)) {
    console.error("No entity fallback found");

    return null;
  }

  return { ...shareholder, entity };
};

type HolderDiff = {
  id: string;
  entity: Entity;
  before: number | { [key: string]: number };
  after: number | { [key: string]: number };
};

const ShareClassBadge = ({
  name,
  shares,
  color,
}: {
  name: string;
  shares?: number;
  color?: "red" | "green";
}) => (
  <Badge
    className={clsxm("tw-text-sm", {
      "!tw-text-green-700": color === "green",
      "!tw-text-red-600": color === "red",
    })}
  >
    {`${name} ${shares ? `(${formatNumber(shares)})` : ""}`}
  </Badge>
);

const ShareholdersComparisonTable = ({
  data,
  totals,
  sortBy,
  setSortBy,
}: {
  data?: HolderDiff[];
  totals?: {
    before: number;
    after: number;
  };
  sortBy: string;
  setSortBy: (value: string) => void;
}) => {
  const i18n = useTranslation();
  const [expanded, setExpanded] = useState<Record<number, boolean>>({});
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  if (!data) {
    return <Loading />;
  }

  const rows = data.map((i) => {
    if (typeof i.after === "number" && typeof i.before === "number" && totals) {
      const change = i.after - i.before;
      const changeClassName =
        change > 0
          ? "tw-text-green-700"
          : change < 0
          ? "tw-text-red-600"
          : undefined;
      const percentageChange =
        i.after / totals.after - i.before / totals.before;
      const percentageChangeClassName =
        percentageChange > 0
          ? "tw-text-green-700"
          : percentageChange < 0
          ? "tw-text-red-600"
          : undefined;
      const valueChange = i.after - i.before;
      const valueChangeClassName =
        valueChange > 0
          ? "tw-text-green-700"
          : valueChange < 0
          ? "tw-text-red-600"
          : undefined;

      return {
        key: i.id,
        shareholder: (
          <EntityItem value={i.entity} showId hasFlag displayIcon={false} />
        ),
        current: (
          <Description
            description={formatNumber(i.before)}
            title={formatPercentage(i.before / totals.before)}
            className="md:tw-text-right"
            descriptionClassName="md:tw-text-right"
          />
        ),
        change:
          change !== 0 ? (
            <p
              className={clsxm("tw-text-sm md:tw-text-right", changeClassName)}
            >
              {formatNumber(change)}
            </p>
          ) : undefined,
        result: (
          <Description
            description={formatNumber(i.after)}
            title={formatPercentage(i.after / totals.after)}
            className={clsxm(percentageChangeClassName, "tw-text-right")}
            descriptionClassName={clsxm(valueChangeClassName, "tw-text-right")}
          />
        ),
      };
    }
    const newClasses = Object.entries(i.after).filter(
      ([k]) => !Object.keys(i.before).includes(k)
    );
    const removedClasses = Object.entries(i.before).filter(
      ([k]) => !Object.keys(i.after).includes(k)
    );

    return {
      key: i.id,
      shareholder: (
        <EntityItem value={i.entity} showId hasFlag displayIcon={false} />
      ),
      current: (
        <div className="tw-flex tw-flex-wrap tw-gap-2">
          {Object.entries(i.before).map(([k, v]) => (
            <ShareClassBadge
              key={k}
              name={k}
              shares={Object.keys(i.before).length > 1 ? v : undefined}
            />
          ))}
        </div>
      ),
      change: (
        <div className="tw-flex tw-flex-wrap tw-gap-2">
          {newClasses.map(([k, v]) => (
            <ShareClassBadge
              key={k}
              name={k}
              color="green"
              shares={newClasses.length > 1 ? v : undefined}
            />
          ))}
          {removedClasses.map(([k, v]) => (
            <ShareClassBadge
              key={k}
              name={k}
              color="red"
              shares={removedClasses.length > 1 ? v : undefined}
            />
          ))}
        </div>
      ),
      result: (
        <div className="tw-flex tw-flex-wrap tw-gap-2">
          {Object.entries(i.after).map(([k, v]) => (
            <ShareClassBadge
              key={k}
              name={k}
              color={Object.keys(i.before).includes(k) ? undefined : "green"}
              shares={Object.keys(i.after).length > 1 ? v : undefined}
            />
          ))}
        </div>
      ),
    };
  });

  const totalChange = totals ? totals.after - totals.before : 0;
  const totalChangeClassName =
    totalChange > 0
      ? "tw-text-green-700"
      : totalChange < 0
      ? "tw-text-red-600"
      : undefined;

  const totalRow = totals && {
    key: "totals",
    current: (
      <Description
        title={`${formatPercentage(100 / 100)}`}
        description={formatNumber(totals.before)}
        className="md:tw-text-right"
        descriptionClassName="md:tw-text-right"
      />
    ),
    change:
      totalChange !== 0 ? (
        <p
          className={clsxm("tw-text-sm md:tw-text-right", totalChangeClassName)}
        >
          {formatNumber(totalChange)}
        </p>
      ) : undefined,
    result: (
      <Description
        title={`${formatPercentage(100 / 100)}`}
        description={formatNumber(totals.after)}
        className="tw-text-right"
        descriptionClassName={clsxm(totalChangeClassName, "tw-text-right")}
      />
    ),
  };

  return (
    <TableV2
      columns={[
        {
          name: "shareholder",
          title: i18n.t("label.shareholder"),
          key: true,
          sortable: true,
        },
        {
          name: "current",
          title: i18n.t("eventsWizard.shareholdersTable.before"),
          key: !isTabletOrMobileDevice,
          sortable: true,
          className: totals ? "tw-flex tw-justify-end" : undefined,
        },
        {
          name: "change",
          title: i18n.t("eventsWizard.shareholdersTable.change"),
          key: !isTabletOrMobileDevice,
          sortable: true,
          className: totals ? "tw-flex tw-justify-end" : undefined,
        },
        {
          name: "result",
          title: i18n.t("eventsWizard.shareholdersTable.after"),
          key: true,
          sortable: true,
          className: totals ? "tw-flex tw-justify-end" : undefined,
        },
      ]}
      data={totalRow && !isTabletOrMobileDevice ? [...rows, totalRow] : rows}
      sortBy={sortBy}
      setSortBy={setSortBy}
      expandedRows={expanded}
      setExpandedRows={setExpanded}
    />
  );
};

const useCombinedHolders = (
  draft?: Shareholder[],
  approved?: Shareholder[]
) => {
  if (!approved || !draft) {
    return [];
  }
  const matching = approved.map((a) => ({
    before: a,
    after: draft?.find((b) => b.holder.id === a.holder.id),
  }));
  const onlyAfter = draft
    .filter((d) => !matching?.find((m) => m.before.holder.id === d.holder.id))
    .map((i) => ({ after: i, before: undefined }));
  return [...matching, ...onlyAfter];
};

const ShareholdersComparison = ({
  currentCompany,
  entitiesMap,
}: ShareholdersComparisonProps) => {
  const i18n = useTranslation();
  const [selectedComparison, setSelectedComparison] = useState(0);
  const [sortBy, setSortBy] = useState<string>("result-desc");
  const approvedVersions = useLedgerVersions(
    currentCompany.orgNumber,
    "approved"
  );
  const shareholdersQueryDraft = useShareholdersQuery(
    currentCompany.orgNumber,
    ""
  );
  const shareholdersQueryApproved = useShareholdersQuery(
    currentCompany.orgNumber,
    approvedVersions[0]?.formatedValue
  );
  const combined = useCombinedHolders(
    shareholdersQueryDraft.data?.shareholders,
    shareholdersQueryApproved.data?.shareholders
  );

  if (
    shareholdersQueryDraft.isLoading ||
    !shareholdersQueryDraft.data ||
    shareholdersQueryApproved.isLoading ||
    !shareholdersQueryApproved.data
  ) {
    return <Loading />;
  }

  const sortHolders = (
    holders: {
      id: string;
      entity: Entity;
      before?: Shareholder;
      after?: Shareholder;
    }[],
    key: string
  ) => {
    const [sort, sortOrder] = key.split("-");
    const isAscending = sortOrder === "asc";
    const comparisonKey =
      selectedComparison === 0
        ? "totalShares"
        : selectedComparison === 1
        ? "totalVotes"
        : "shareTypes";

    const itemToComparisonValue = (item: Shareholder | undefined): number => {
      if (comparisonKey === "totalShares" || comparisonKey === "totalVotes") {
        return item ? item[comparisonKey] : 0;
      }
      return Object.keys(item?.shareTypes || {}).length;
    };

    if (sort === "shareholder") {
      return holders.sort((a, b) =>
        isAscending
          ? a.entity.name.localeCompare(b.entity.name)
          : b.entity.name.localeCompare(a.entity.name)
      );
    }
    if (sort === "current") {
      return holders.sort((a, b) =>
        isAscending
          ? itemToComparisonValue(a.before) - itemToComparisonValue(b.before)
          : itemToComparisonValue(b.before) - itemToComparisonValue(a.before)
      );
    }
    if (sort === "change") {
      return holders.sort((a, b) => {
        const aChange =
          itemToComparisonValue(a.after) - itemToComparisonValue(a.before);
        const bChange =
          itemToComparisonValue(b.after) - itemToComparisonValue(b.before);
        return isAscending ? aChange - bChange : bChange - aChange;
      });
    }
    return holders.sort((a, b) =>
      isAscending
        ? itemToComparisonValue(a.after) - itemToComparisonValue(b.after)
        : itemToComparisonValue(b.after) - itemToComparisonValue(a.after)
    );
  };

  const holders = combined
    .map(({ before, after }) => {
      const shareholder = getShareholderWithEntity(
        entitiesMap,
        before || after
      );
      if (!shareholder) {
        return null;
      }
      return {
        id: shareholder.holder.id,
        entity: shareholder.entity,
        before,
        after,
      };
    })
    .filter((x) => x !== null);
  const sortedHolders = sortHolders(holders, sortBy);

  return (
    <div className="tw-rounded md:tw-border">
      <div className="tw-pb-4 md:tw-px-4 md:tw-py-4">
        <h3 className="tw-text-xl tw-font-medium">
          {i18n.t("eventsWizard.shareholdersTable.title")}
        </h3>
        <p className="tw-text-secondary">
          {i18n.t("eventsWizard.shareholdersTable.description")}
        </p>
      </div>
      <Tab.Group onChange={(index) => setSelectedComparison(index)}>
        <Tab.List className="tw-p-4 md:tw-border-b">
          <Tab>{i18n.t("label.shares")}</Tab>
          <Tab>{i18n.t("label.votes")}</Tab>
          <Tab>{i18n.t("label.shareClasses")}</Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <ShareholdersComparisonTable
              data={sortedHolders.map((i) => ({
                ...i,
                before: i.before?.totalShares || 0,
                after: i.after?.totalShares || 0,
              }))}
              totals={{
                before: shareholdersQueryApproved.data.totalShares,
                after: shareholdersQueryDraft.data.totalShares,
              }}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          </Tab.Panel>
          <Tab.Panel>
            <ShareholdersComparisonTable
              data={sortedHolders.map((i) => ({
                ...i,
                before: i.before?.totalVotes || 0,
                after: i.after?.totalVotes || 0,
              }))}
              totals={{
                before: shareholdersQueryApproved.data.totalVotes,
                after: shareholdersQueryDraft.data.totalVotes,
              }}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          </Tab.Panel>
          <Tab.Panel>
            <ShareholdersComparisonTable
              data={sortedHolders.map((i) => ({
                ...i,
                before: i.before?.shareTypes || {},
                after: i.after?.shareTypes || {},
              }))}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export { getShareholderWithEntity, ShareholdersComparison };
