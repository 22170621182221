import { useTranslation } from "react-i18next";
import { Navigate, Outlet, useMatch, useParams } from "react-router-dom";

import { Loading } from "../../../components/design-system/Loading";
import { Sidebar } from "../../../components/design-system/Sidebar";
import { NoData } from "../../../components/NoData";
import { useCurrentCompany } from "../../../context/account";
import { useSession } from "../../../context/session";
import { useCompanyEvents } from "../../../hooks/useCompanyEvents";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import { Role } from "../../../types/models/users";
import ActionBanner from "./ActionBanner";
import { CompanyEmptyStateForViewers } from "./CompanyEmptyStateForViewers";
import { UserActionModal } from "./userActions/UserActionModal";
import { useUserActionRequired } from "./useUserActionRequired";

const CompanyPageContent = ({
  role,
  companyId,
}: {
  role: Role;
  companyId: string;
}) => {
  const i18n = useTranslation();
  const companyEvents = useCompanyEvents(companyId);

  if (!companyEvents.isSuccess) {
    return <Loading />;
  }

  if (role === "Viewer" && companyEvents.approvedEvents.length === 0) {
    return <CompanyEmptyStateForViewers />;
  }

  if (!role) {
    return (
      <NoData
        type="error"
        title={i18n.t("error.fetch")}
        description={i18n.t("error.verification.unauthorized")}
      />
    );
  }

  return <Outlet />;
};

const CompanyPage = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { user } = useSession();
  const isInMailVerificationPage = useMatch(
    "/companies/:companyId/email/verify"
  );

  const company = useCurrentCompany(companyId);
  const userRole = (company as CompanyInvolvement)?.access;
  const supportRole =
    user?.role === "Administrator"
      ? (company as CompanyInformation)?.supportRole
      : null;
  const role = userRole || supportRole;

  // Avoid making queries if user does not have role
  const [requiredUserAction, refresh] = useUserActionRequired(
    role ? companyId : ""
  );

  if (!companyId) {
    return <></>;
  }

  return (
    <>
      {requiredUserAction && companyId && !isInMailVerificationPage && (
        <UserActionModal
          requiredAction={requiredUserAction}
          orgNumber={companyId}
          refresh={refresh}
        />
      )}
      <div className="tw-flex">
        <Sidebar />
        <main className="tw-w-full tw-bg-white tw-p-4 max-lg:tw-mt-[56px] md:tw-p-6 lg:tw-ml-[240px] lg:tw-max-w-[calc(100%-240px)]">
          {company && <ActionBanner company={company} />}
          <CompanyPageContent role={role} companyId={companyId} />
        </main>
      </div>
    </>
  );
};

const CompanyRedirect = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const company = useCurrentCompany(companyId);
  return (
    <Navigate
      to={company?.companyStatus === "Onboarding" ? "onboarding" : "shares"}
    />
  );
};

export { CompanyPage, CompanyRedirect };
