import type { Policy } from "../../utils/policy";
import { clsxm } from "../../utils/tailwind";
import { Description } from "../design-system/Description";

const STEPS = {
  CHOOSE_APPROVAL_POLICY: "CHOOSE_APPROVAL_POLICY",
  REVIEW_POLICY_CHANGES: "REVIEW_POLICY_CHANGES",
} as const;

type Step = keyof typeof STEPS;

const PolicyAlternativesNew = ({
  title,
  description,
  value,
  checked,
  children,
  onChange,
  setStep,
}: {
  title: string;
  description: string;
  value: string;
  checked: boolean;
  children?: JSX.Element;
  onChange: (policy: Policy) => void;
  setStep?: (step: Step) => void;
}) => (
  <div
    className={clsxm(
      "tw-rounded-lg",
      checked ? "tw-bg-kvanta-secondary" : "tw-bg-white"
    )}
  >
    <label
      className="tw-relative tw-flex tw-items-center tw-justify-between tw-p-5 hover:tw-cursor-pointer"
      htmlFor={value}
    >
      <Description
        theme="kvanta"
        title={title}
        titleWeight="medium"
        description={description}
        descriptionCollapse
      />
      <div className="tw-relative">
        <input
          type="radio"
          name="policy"
          value={value}
          id={value}
          className="tw-sr-only"
          checked={checked}
          onChange={(e) => onChange(e.target.value as Policy)}
          onClick={() => {
            setStep?.("REVIEW_POLICY_CHANGES");
          }}
        />
        <div
          className={`w-5 h-5 tw-relative tw-flex tw-items-center tw-justify-center tw-rounded-full tw-border-2 ${
            checked ? "tw-border-radio" : "tw-border-gray-300"
          }`}
          data-testid={value}
        >
          {checked ? (
            <div className="tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full tw-bg-radio">
              <div className="tw-h-1 tw-w-1 tw-rounded-full tw-bg-kvanta-secondary" />
            </div>
          ) : (
            <div className="tw-h-5 tw-w-5 tw-rounded-full tw-bg-transparent" />
          )}
        </div>
      </div>
    </label>
    {checked && children}
  </div>
);

export { PolicyAlternativesNew };
