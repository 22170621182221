import {
  Book,
  BookOpen,
  Gear,
  GearSix,
  Globe,
  ListDashes,
  Megaphone,
  Notepad,
  ShareFat,
  SignOut,
  TrendUp,
  UsersThree,
} from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { featureToggles } from "../../../config";
import { useCurrentCompany } from "../../../context/account";
import { useSession } from "../../../context/session";
import { useCompanyEvents } from "../../../hooks/useCompanyEvents";
import { useToggles } from "../../../hooks/useToggles";
import { getLocale, locales, setLocale } from "../../../i18n";
import bv from "../../../pages/companies/[companyId]/bv.svg";
import { APP_ROUTE } from "../../../routes/constants";
import { CompanyInformation } from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import { hasRequiredPermission } from "../../../utils/permissions";
import { clsxm } from "../../../utils/tailwind";
import { PieChartIcon } from "../icons";

const companyHaveApprovedEvents = (
  events: ReturnType<typeof useCompanyEvents>
) => {
  const ledgerWithNoApprovedEvents =
    !events.approvedEvents || events.approvedEvents?.length === 0;
  if (ledgerWithNoApprovedEvents) {
    return false;
  }

  return true;
};

const useCheckPermission = () => {
  const currentCompany = useCurrentCompany();
  const { user } = useSession();
  const events = useCompanyEvents(currentCompany?.orgNumber);

  if (!currentCompany) {
    return false;
  }

  const userRole = (currentCompany as CompanyInvolvement)?.access;
  const supportRole =
    user?.role === "Administrator"
      ? (currentCompany as CompanyInformation)?.supportRole
      : null;

  return (
    hasRequiredPermission("Editor", currentCompany, user) ||
    ((userRole === "Viewer" || (!userRole && supportRole === "Viewer")) &&
      companyHaveApprovedEvents(events))
  );
};

type NavItem = {
  key: string;
  label: string;
  to?: string;
  onClick?: () => void;
  icon?: JSX.Element;
  hasPermission: boolean;
  tier?: number;
  isBeta?: boolean;
  alert?: boolean;
};

export const useGlobalLinks = (onSignout: () => void): NavItem[] => {
  const i18n = useTranslation();
  const { user } = useSession();
  const newLocale = getLocale() === "en-US" ? "sv-SE" : "en-US";

  return [
    {
      key: "language",
      label: locales[newLocale],
      onClick: () => setLocale(newLocale),
      icon: <Globe className="tw-h-5 tw-w-5" />,
      hasPermission: true,
    },
    {
      key: "logout",
      label: i18n.t("label.logout"),
      onClick: () => onSignout(),
      icon: <SignOut className="tw-h-5 tw-w-5" />,
      hasPermission: true,
    },
    {
      key: "admin",
      label: i18n.t("label.administration"),
      to: "/administration",
      icon: <GearSix className="tw-h-5 tw-w-5" />,
      hasPermission: user?.role === "Administrator",
    },
  ].filter((link) => link.hasPermission);
};

export const useCompanyLinks = (): NavItem[] => {
  const i18n = useTranslation();
  const currentCompany = useCurrentCompany();
  const { isFeatureEnabled } = useToggles();
  const havePermissionToViewMenu = useCheckPermission();

  if (!currentCompany) {
    return [];
  }

  return [
    {
      key: "shareholders",
      label: i18n.t("label.shareholders"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/shares/holders`,
      icon: <PieChartIcon className="tw-h-5 tw-w-5" />,
      hasPermission:
        havePermissionToViewMenu &&
        currentCompany.companyStatus !== "Onboarding",
      tier: 0,
    },
    {
      key: "actions",
      label: i18n.t("label.corporateActions"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/events`,
      icon: <ListDashes className="tw-h-5 tw-w-5" />,
      hasPermission:
        havePermissionToViewMenu &&
        currentCompany.companyStatus !== "Onboarding",
      tier: 0,
    },
    {
      key: "ledger",
      label: i18n.t("label.shareLedger"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/shares/blocks`,
      icon: <Book className="tw-h-5 tw-w-5" />,
      hasPermission:
        havePermissionToViewMenu &&
        currentCompany.companyStatus !== "Onboarding",
      tier: 0,
    },
    {
      key: "entities",
      label: i18n.t("label.entities"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/settings/entities`,
      icon: <UsersThree className="tw-h-5 tw-w-5" />,
      hasPermission: havePermissionToViewMenu,
      tier: 1,
    },
    {
      key: "options",
      label: i18n.t("label.options"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/options`,
      icon: <TrendUp className="tw-h-5 tw-w-5" />,
      hasPermission:
        havePermissionToViewMenu &&
        currentCompany.companyStatus !== "Onboarding",
      tier: 1,
    },
    {
      key: "views",
      label: i18n.t("label.views"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/views`,
      icon: <ShareFat className="tw-h-5 tw-w-5" />,
      hasPermission:
        havePermissionToViewMenu &&
        currentCompany.companyStatus !== "Onboarding",
      tier: 1,
    },
    {
      key: "validate",
      label: i18n.t("label.validate"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/bv-validation`,
      icon: <img src={bv} alt="bolagsverket logo" className="tw-h-6" />,
      hasPermission:
        isFeatureEnabled(featureToggles.ENABLE_EVENT_VALIDATION) &&
        currentCompany.companyStatus !== "Onboarding",
      tier: 1,
    },
    {
      key: "voting",
      label: i18n.t("label.votingRegister"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/voting-register`,
      icon: <Megaphone className="tw-h-5 tw-w-5" />,
      hasPermission:
        havePermissionToViewMenu &&
        currentCompany.companyStatus !== "Onboarding",
      tier: 1,
    },
    {
      key: "restrictionCases",
      label: i18n.t("restrictionCase.title"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/restriction-cases`,
      icon: <Notepad className="tw-h-5 tw-w-5" />,
      hasPermission:
        havePermissionToViewMenu &&
        currentCompany.companyStatus !== "Onboarding",
      tier: 1,
    },
    {
      key: "aboutCompany",
      label: i18n.t("label.aboutCompany"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/company-information`,
      icon: <BookOpen className="tw-h-5 tw-w-5" />,
      hasPermission: havePermissionToViewMenu,
      tier: 1,
    },
    {
      key: "settings",
      label: i18n.t("label.settings"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/settings`,
      icon: <Gear className="tw-h-5 tw-w-5" />,
      hasPermission: havePermissionToViewMenu,
      tier: 2,
    },
  ].filter((link) => link.hasPermission);
};

export const SidebarOption = ({
  link,
  onClick,
}: {
  link: NavItem;
  onClick: () => void;
}) => {
  const i18n = useTranslation();
  const location = useLocation();

  const content = (
    <div
      className={clsxm(
        "tw-flex tw-cursor-pointer tw-items-center tw-gap-2 tw-rounded-md tw-px-3 tw-py-2 tw-text-sm",
        {
          "tw-bg-kvanta-secondary tw-text-kvanta-primary":
            location.pathname === link.to,
          "tw-text-secondary hover:tw-bg-neutral-100 hover:tw-text-primary":
            location.pathname !== link.to,
        }
      )}
    >
      {link.icon}
      <span>{link.label}</span>
      {link.isBeta && (
        <div className="tw--right-2 tw--top-2 tw-rounded tw-bg-blue-100 tw-px-1 tw-text-xs tw-font-medium">
          {i18n.t("label.beta")}
        </div>
      )}
    </div>
  );

  return link.to ? (
    <Link to={link.to} onClick={onClick}>
      {content}
    </Link>
  ) : (
    <button
      onClick={() => {
        if (link.onClick) {
          link.onClick();
        }
        onClick();
      }}
      type="button"
    >
      {content}
    </button>
  );
};

export type { NavItem };
