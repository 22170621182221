import type { CompanyHoldings, Holding } from "@capchapdev/rell-api";
import { isEqual } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";

import { useCompanyHoldings } from "../../api/blockchain/companyHolding";
import { usePrivateHoldings } from "../../api/blockchain/privateHolding";
import type { RepresentativeHolding } from "../../api/blockchain/representativeHolding";
import { useRepresentativeHolding } from "../../api/blockchain/representativeHolding";
import { useRepresentativeEntityQuery } from "../../api/rest/entities";
import { Button } from "../../components/design-system/Button";
import { EmptyState } from "../../components/design-system/EmptyState";
import { LogoIcon } from "../../components/design-system/icons";
import { Loading } from "../../components/design-system/Loading";
import { HomePageCard } from "../../components/HomePageCard";
import { NoData } from "../../components/NoData";
import { PageWrapper } from "../../components/PageWrapper";
import { APP_ROUTE } from "../../routes/constants";

type OwnershipViewProps = {
  privateHoldings: Holding[];
  companyHoldings: CompanyHoldings[];
  representativeHoldings: RepresentativeHolding[];
};

const haveShares = (holding: Holding) => holding.shares.total > 0;

const RepresentativeHoldingRow = ({
  total,
  holderId,
  orgNumber,
}: {
  total: number;
  holderId: string;
  orgNumber: string;
}) => {
  const i18n = useTranslation();
  const entity = useRepresentativeEntityQuery(orgNumber, holderId).data;

  if (!entity) {
    return <Loading key={`representative-${holderId}`} />;
  }

  return (
    <Link to={`${APP_ROUTE.OWNERSHIP}/representative/${holderId}`}>
      <HomePageCard
        title={entity.name}
        description={i18n.t("assets.details.companies", { total })}
        footer={
          <span className="tw-text-sm tw-text-secondary">
            {i18n.t("assets.details.type.representative")}
          </span>
        }
      />
    </Link>
  );
};

const OwnershipView = ({
  privateHoldings,
  companyHoldings,
  representativeHoldings,
}: OwnershipViewProps) => {
  const i18n = useTranslation();
  const privateHoldingsFiltered = privateHoldings.filter(
    (holding) => holding.shares.total > 0
  );
  const companyHoldingsFlat = companyHoldings.map((x) => x.holdings).flat();
  const companyHoldingsFiltered = companyHoldings.filter(({ holdings }) =>
    holdings.some((holding) => holding.shares.total > 0)
  );
  const representativeHoldingsFiltered = representativeHoldings.filter(
    ({ holding }) => !companyHoldingsFlat.some((c) => isEqual(c, holding))
  );
  const representativeHoldingsGrouped = representativeHoldingsFiltered.reduce(
    (
      acc: {
        [key: string]: { id: string; orgNumber: string; total: number };
      },
      item
    ) => {
      const { id } = item.holder;
      if (acc[id]) {
        acc[id].total = acc[id].total + 1;
      } else {
        acc[id] = {
          id: item.holder.id,
          orgNumber: item.holding.company.orgNumber,
          total: 1,
        };
      }

      return acc;
    },
    {}
  );

  return (
    <div className="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2">
      {privateHoldingsFiltered.length > 0 && (
        <Link to={`${APP_ROUTE.OWNERSHIP}/private`}>
          <HomePageCard
            title={i18n.t("assets.details.type.myOwnership")}
            description={i18n.t("assets.details.companies", {
              total: privateHoldingsFiltered.length,
            })}
            footer={
              <span className="tw-text-sm tw-text-secondary">
                {i18n.t("assets.details.type.personal")}
              </span>
            }
          />
        </Link>
      )}
      {companyHoldingsFiltered.length > 0 &&
        companyHoldingsFiltered.map(({ company, holdings }) => (
          <Link
            to={`${APP_ROUTE.OWNERSHIP}/company/${company.orgNumber}`}
            key={company.orgNumber}
          >
            <HomePageCard
              title={company.name}
              description={i18n.t("assets.details.companies", {
                total: holdings.length,
              })}
              footer={
                <span className="tw-text-sm tw-text-secondary">
                  {i18n.t("assets.details.type.company")}
                </span>
              }
            />
          </Link>
        ))}
      {Object.values(representativeHoldingsGrouped).length > 0 &&
        Object.entries(representativeHoldingsGrouped).map(([k, v]) => (
          <RepresentativeHoldingRow
            key={k}
            holderId={v.id}
            orgNumber={v.orgNumber}
            total={v.total}
          />
        ))}
    </div>
  );
};

const Ownership = () => {
  const i18n = useTranslation();
  const privateHoldings = usePrivateHoldings("approved");
  const companyHoldings = useCompanyHoldings("approved");
  const representativeHoldings = useRepresentativeHolding("approved");

  const userHoldingsViaHoldingCompaniesWithShares = useMemo(
    () =>
      (
        companyHoldings.data?.filter((company) =>
          company.holdings?.some(haveShares)
        ) ?? []
      ).sort((a, b) => a.company.name.localeCompare(b.company.name)),
    [companyHoldings.data]
  );

  const userPrivateHoldingsWithShares = useMemo(
    () =>
      (privateHoldings.data?.holdings?.filter(haveShares) ?? []).sort((a, b) =>
        a.company.name.localeCompare(b.company.name)
      ),
    [privateHoldings.data]
  );

  const userRepresentativeHoldingsWithShares = useMemo(
    () =>
      (representativeHoldings.data ?? [])
        .filter((x) => haveShares(x.holding))
        .sort((a, b) =>
          a.holding.company.name.localeCompare(b.holding.company.name)
        ),
    [representativeHoldings.data]
  );

  if (
    privateHoldings.isLoading ||
    companyHoldings.isLoading ||
    representativeHoldings.isLoading
  ) {
    return <Loading />;
  }

  if (privateHoldings.isError) {
    return (
      <NoData
        type="error"
        title={i18n.t("error.fetch")}
        description={i18n.t("error.privateHoldings.fetchError")}
      />
    );
  }
  if (companyHoldings.isError) {
    return (
      <NoData
        description={i18n.t("error.companyHoldings.fetchError")}
        title={i18n.t("error.fetch")}
        type="error"
      />
    );
  }
  if (representativeHoldings.isError) {
    return (
      <NoData
        description={i18n.t("error.privateHoldings.fetchError")}
        title={i18n.t("error.fetch")}
        type="error"
      />
    );
  }

  const noPrivateHoldings = userPrivateHoldingsWithShares.length === 0;
  const noCompanyHoldings =
    userHoldingsViaHoldingCompaniesWithShares.length === 0 ||
    userHoldingsViaHoldingCompaniesWithShares.every(
      (holdingCompany) => holdingCompany.holdings?.length === 0
    );
  const noRepresentativeHoldings =
    userRepresentativeHoldingsWithShares.length === 0;

  if (noPrivateHoldings && noCompanyHoldings && noRepresentativeHoldings) {
    return (
      <EmptyState
        title={i18n.t("assets.emptyState.title")}
        titleSize="xl"
        description={
          <>
            <p className="tw-text-sm">
              {i18n.t("assets.emptyState.firstParagraph")}
            </p>
            <br />
            <p className="tw-text-sm">
              {i18n.t("assets.emptyState.thirdParagraph")}
            </p>
          </>
        }
        button={
          <a
            href="https://kvanta.com/contact"
            rel="noreferrer noopener"
            target="_blank"
          >
            <Button variant="outline" color="secondary">
              {i18n.t("signUp.contactUs")}
            </Button>
          </a>
        }
      />
    );
  }

  return (
    <OwnershipView
      companyHoldings={userHoldingsViaHoldingCompaniesWithShares}
      privateHoldings={userPrivateHoldingsWithShares}
      representativeHoldings={userRepresentativeHoldingsWithShares}
    />
  );
};

const OwnershipLayout = () => {
  return (
    <PageWrapper className="tw-max-w-3xl" data-testid="home">
      <div className="tw-space-y-6 tw-px-4">
        <LogoIcon className="tw-mx-auto tw-my-4" />
        <Outlet />
      </div>
    </PageWrapper>
  );
};

export { Ownership, OwnershipLayout };
