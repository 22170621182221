import { Book, Briefcase, DotsThreeVertical } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link, Navigate, useLocation } from "react-router-dom";

import { useCompaniesQuery } from "../api/blockchain/company";
import { ContactBanner } from "../components/ContactBanner";
import { LogoIcon } from "../components/design-system/icons";
import { Loading } from "../components/design-system/Loading";
import { Menu } from "../components/design-system/Menu";
import {
  NavItem,
  useGlobalLinks,
} from "../components/design-system/Sidebar/SidebarOption";
import { PageWrapper } from "../components/PageWrapper";
import { useSession } from "../context/session";
import { Ti18nKey } from "../i18n";
import { APP_ROUTE } from "../routes/constants";
import { Ledgers } from "./ledgers";
import { Ownership } from "./ownership";

const NavLink = ({ link }: { link: NavItem }) => {
  return link.to ? (
    <Link
      to={link.to}
      className="tw-flex tw-items-center tw-gap-2 tw-text-secondary max-md:tw-w-full md:tw-p-2"
    >
      {link.icon}
      {link.label}
    </Link>
  ) : (
    <button
      onClick={() => {
        if (link.onClick) {
          link.onClick();
        }
      }}
      type="button"
      className="tw-flex tw-items-center tw-gap-2 tw-text-secondary max-md:tw-w-full md:tw-p-2"
    >
      {link.icon}
      {link.label}
    </button>
  );
};

const DashboardMenu = () => {
  const i18n = useTranslation();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const { onSignout } = useSession();
  const globalLinks = useGlobalLinks(onSignout);
  const location = useLocation();
  const isLedger = location.pathname === APP_ROUTE.COMPANIES;
  const links = [
    {
      key: "view",
      label: i18n.t(
        isLedger ? "dashboard.switch.ownership" : "dashboard.switch.ledgers"
      ),
      to: isLedger ? APP_ROUTE.OWNERSHIP : APP_ROUTE.COMPANIES,
      icon: isLedger ? <Briefcase /> : <Book />,
      hasPermission: true,
    },
    ...globalLinks,
  ];

  if (isTabletOrMobileDevice) {
    return (
      <Menu className="tw-mx-auto tw-flex tw-flex-1 tw-justify-end tw-p-2">
        <Menu.Button isDropdown={false} className="tw-border-none">
          <DotsThreeVertical />
        </Menu.Button>
        <Menu.Items align="bottomRight" className="tw-mr-2 tw-mt-14">
          {links.map((l) => (
            <Menu.Item key={l.key}>
              <NavLink link={l} />
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    );
  }

  return (
    <div className="tw-flex tw-flex-1 tw-items-center tw-justify-end tw-gap-2 tw-text-sm tw-font-medium tw-text-secondary">
      {links.map((l) => (
        <NavLink key={l.key} link={l} />
      ))}
    </div>
  );
};

const HomePageLayout = ({
  description,
  children,
  banner,
}: {
  description: Ti18nKey;
  children: JSX.Element | JSX.Element[];
  banner: "home.ledgerCard.help" | "home.assetsCard.help";
}) => {
  const i18n = useTranslation();

  return (
    <PageWrapper data-testid="home">
      <div className="tw-space-y-6 tw-text-center">
        <div className="tw-flex tw-justify-between">
          <div className="tw-flex-1" />
          <LogoIcon className="tw-mx-auto tw-my-4 tw-flex-1" />
          <DashboardMenu />
        </div>
        <div className="tw-mx-auto tw-max-w-3xl tw-space-y-6">
          <div>
            <h1 className="tw-text-2xl tw-font-medium">
              {i18n.t("home.title")}
            </h1>
            <p>{i18n.t(description)}</p>
          </div>
          <div className="tw-px-4">{children}</div>
          <ContactBanner i18nKey={banner} />
        </div>
      </div>
    </PageWrapper>
  );
};

const LedgersPage = () => {
  return (
    <HomePageLayout
      description="home.ledger.description"
      banner="home.ledgerCard.help"
    >
      <Ledgers />
    </HomePageLayout>
  );
};

const OwnershipPage = () => {
  return (
    <HomePageLayout
      description="home.ownership.description"
      banner="home.assetsCard.help"
    >
      <Ownership />
    </HomePageLayout>
  );
};

const HomePage = () => {
  const companiesQuery = useCompaniesQuery();
  const companies = companiesQuery.data || [];

  if (companiesQuery.isLoading) {
    return <Loading />;
  }

  return (
    <Navigate
      to={companies.length > 0 ? APP_ROUTE.COMPANIES : APP_ROUTE.OWNERSHIP}
    />
  );
};

export { HomePage, LedgersPage, OwnershipPage };
