import type { Ref, SVGProps } from "react";
import { forwardRef } from "react";

type LogoProps = SVGProps<SVGSVGElement> & {
  size?: "small" | "large";
};

const Logo = (props: LogoProps, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    width={props.size === "large" ? "149" : "112"}
    height={props.size === "large" ? "34" : "26"}
    viewBox="0 0 112 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0783733 11.8292H2.69927L3.21542 11.313L9.17251 5.35595L10.9347 3.59371L12.697 5.35595L18.6541 11.313L19.1702 11.8292H21.7934L10.9359 0.97168L0.0783733 11.8292ZM21.7934 14.3214H19.1702L18.6541 14.8375L12.697 20.7946L10.9347 22.5568L9.17251 20.7946L3.21542 14.8375L2.69927 14.3214H0.0783691L10.9359 25.1789L21.7934 14.3214ZM10.9347 7.11818L4.97765 13.0753L10.9347 19.0324L16.8918 13.0753L10.9347 7.11818Z"
      fill="#262626"
    />
    <path
      d="M30.9595 19.5547V7.50082H32.9225V13.0628L38.4329 7.50082H41.2053L35.9877 12.7701L41.2225 19.5547H38.7256L34.5929 14.1649L32.9225 15.8524V19.5547H30.9595ZM48.8279 19.5547L43.1109 7.50082H45.2806L49.6544 16.7134L53.9938 7.50082H56.1807L50.4637 19.5547H48.8279ZM67.4411 19.5547L66.4596 17.4539H59.6061L58.6418 19.5547H56.4893L62.0858 7.50082H63.9972L69.5936 19.5547H67.4411ZM60.5188 15.4908H65.5469L63.0329 10.0666L60.5188 15.4908ZM72.8016 19.5547V7.50082H74.6097L81.7387 16.2829V7.50082H83.7017V19.5547H81.8937L74.7647 10.7898V19.5547H72.8016ZM91.4009 19.5547V9.46388H86.8376V7.50082H97.91V9.46388H93.3639V19.5547H91.4009ZM109.451 19.5547L108.47 17.4539H101.616L100.652 19.5547H98.4996L104.096 7.50082H106.007L111.604 19.5547H109.451ZM102.529 15.4908H107.557L105.043 10.0666L102.529 15.4908Z"
      fill="#262626"
    />
  </svg>
);

const ForwardRef = forwardRef(Logo);

export { ForwardRef as Logo };
