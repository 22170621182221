import { useTranslation } from "react-i18next";

import { useMyUnregisteredCompaniesQuery } from "../../api/rest/person";
import { LedgersEmptyState, UnregisteredCompany } from "../../pages/ledgers";
import { Dialog } from "../design-system/Dialog";
import { InfoIcon } from "../design-system/icons";
import { Loading } from "../design-system/Loading";

const AddCompany = ({ onClose }: { onClose: () => void }) => {
  const i18n = useTranslation();
  const unregisteredCompaniesQuery = useMyUnregisteredCompaniesQuery();
  const unregisteredCompanies =
    unregisteredCompaniesQuery.data?.unregisteredCompanies ?? [];

  return (
    <Dialog
      title={i18n.t("addCompany.title")}
      isOpen
      onClose={onClose}
      size="md"
    >
      <div className="tw-py-4">
        {unregisteredCompaniesQuery.isLoading ? (
          <Loading />
        ) : unregisteredCompanies.length > 0 ? (
          <div className="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2">
            {unregisteredCompanies.map((company) => (
              <UnregisteredCompany company={company} key={company.id} />
            ))}
          </div>
        ) : (
          <LedgersEmptyState />
        )}
        <div className="tw-mt-4 tw-flex tw-gap-4 tw-rounded-md tw-border tw-border-neutral-200 tw-p-6 tw-text-left tw-text-sm tw-text-secondary">
          <InfoIcon />
          <p>{i18n.t("addCompany.hint")}</p>
        </div>
      </div>
    </Dialog>
  );
};

export { AddCompany };
