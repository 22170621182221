import type { NonEmptyArray } from "..";
import type { LedgerVersion, Range, Shareblock, ShareType } from "./shares";

type BaseEvent = { version: string };

const parentEventTypes = {
  CompanyFoundation: "CompanyFoundation",
  ShareIssue: "ShareIssue",
  ShareSplit: "ShareSplit",
  ReverseShareSplit: "ReverseShareSplit",
  // ShareAllocation: "ShareAllocation",
  ShareTransfer: "ShareTransfer",
  LedgerApprovalInitialized: "LedgerApprovalInitialized",
  LedgerApproval: "LedgerApproval",
  LedgerRollback: "LedgerRollback",
  IncreaseCapital: "IncreaseCapital",
  IncreaseCapitalBonusIssue: "IncreaseCapitalBonusIssue",
  DecreaseCapital: "DecreaseCapital",
  DecreaseCapitalCancelShares: "DecreaseCapitalCancelShares",
  ReclassifySharesByClass: "ReclassifySharesByClass",
  ReclassifySharesByRange: "ReclassifySharesByRange",
  ShareClassUpdate: "ShareClassUpdate",
  ShareCertificateUpdate: "ShareCertificateUpdate",
  SharePledgedUpdate: "SharePledgedUpdate",
  LedgerPolicyApproval: "LedgerPolicyApproval",
  LedgerRollbackPending: "LedgerRollbackPending",
  LedgerRollbackRejected: "LedgerRollbackRejected",
  LedgerApprovalRejected: "LedgerApprovalRejected",
} as const;

const hiddenLedgerEvents = [
  "LedgerRollback",
  "LedgerPolicyApproval",
  "LedgerRollbackRejected",
  "LedgerApprovalRejected",
];

type ParentEventType = (typeof parentEventTypes)[keyof typeof parentEventTypes];

type BaseParentEvent = BaseEvent & {
  type: ParentEventType;
  id: string;
  date: LedgerVersion;
  created: number;
  transactionId: string;
};

type BaseChildEvent = BaseEvent & {
  type: "ShareAllocation";
  parentId: string;
};

type EventEntity = {
  id: string;
  refId: string;
  unmaskedRefId?: string;
  since: string;
  type: string;
};

type EventShareRange = { start: number; end: number; type: string };

type ShareAllocationEvent = BaseChildEvent & {
  type: "ShareAllocation";
  recipient: EventEntity;
  shares: EventShareRange;
};

type LedgerRollbackEvent = BaseParentEvent & {
  type: "LedgerRollback";
};

type CompanyFoundationEvent = BaseParentEvent & {
  type: "CompanyFoundation";
  shares: { total: number; capital: string; investment: string };
  user: { id: string };
};

type ShareIssueEvent = BaseParentEvent & {
  type: "ShareIssue";
  shares: { total: number; capital: string; investment: string };
  user: { id: string };
};

type ShareReclassificationByRangeEvent = BaseParentEvent & {
  type: "ReclassifySharesByRange";
  shares: { ranges: EventShareRange[]; toClass: string };
  user: { id: string };
};

type ShareReclassificationByClassEvent = BaseParentEvent & {
  type: "ReclassifySharesByClass";
  shares: { total: number; fromClass: string; toClass: string };
  user: { id: string };
};

type LedgerApprovalEventV1 = BaseParentEvent & {
  type: "LedgerApproval";
  user: { id: string };
  version: "1";
};

type LedgerApprovalEventV2 = BaseParentEvent & {
  type: "LedgerApproval";
  users: { id: string }[];
  version: "2";
};

type LedgerApprovalEvent = LedgerApprovalEventV1 | LedgerApprovalEventV2;

type LedgerApprovalInitializedEvent = BaseParentEvent & {
  type: "LedgerApprovalInitialized";
  user: { id: string };
};

type DecreaseCapitalEvent = BaseParentEvent & {
  type: "DecreaseCapital";
  shares: { capital: string; quotaValue: { before: number; after: number } };
  user: { id: string };
};

type DecreaseCapitalCancelSharesEvent = BaseParentEvent & {
  type: "DecreaseCapitalCancelShares";
  shares: { total: number; capital: string };
  user: { id: string };
};

type IncreaseCapitalEvent = BaseParentEvent & {
  type: "IncreaseCapital";
  shares: { capital: string; quotaValue: { before: number; after: number } };
  user: { id: string };
};

type IncreaseCapitalBonusIssueEvent = BaseParentEvent & {
  type: "IncreaseCapitalBonusIssue";
  shares: { total: number; capital: string };
  user: { id: string };
};

type ShareSplitEvent = BaseParentEvent & {
  type: "ShareSplit";
  ratio: { x: number; y: number };
  shares: { total: number };
  user: { id: string };
};

type ReverseShareSplitEvent = BaseParentEvent & {
  type: "ReverseShareSplit";
  ratio: { x: number; y: number };
  shares: { total: number };
  user: { id: string };
};

type ShareTransfer = {
  sender: EventEntity;
  recipient: EventEntity;
  range: EventShareRange;
};

type ShareTransferEvent = BaseParentEvent & {
  type: "ShareTransfer";
  shares: NonEmptyArray<ShareTransfer>;
  price?: string;
  user: { id: string };
};

type ShareClassUpdateEvent = BaseParentEvent & {
  type: "ShareClassUpdate";
  shareClass: Omit<ShareType, "voteValue">;
  user: { id: string };
};

type BlockRange = Required<Range> & { type: Shareblock["type"] };

type ShareCertificatesUpdateEvent = BaseParentEvent & {
  type: "ShareCertificateUpdate";
  ranges: { before: BlockRange[]; after: BlockRange[] };
  user: { id: string };
};

type BlockRangeWithCreditor = BlockRange & {
  creditor: EventEntity;
};

type PledgedSharesUpdateEvent = BaseParentEvent & {
  type: "SharePledgedUpdate";
  ranges: {
    before: BlockRangeWithCreditor[];
    after: BlockRangeWithCreditor[];
  };
  user: { id: string };
};

type LedgerPolicyApproval = BaseParentEvent & {
  type: "LedgerPolicyApproval";
  approvedBy: { id: string }[];
  percentageRule?: number;
  specificUsers?: string[];
};

type LedgerRollbackPending = BaseParentEvent & {
  type: "LedgerRollbackPending";
  rollback_date: LedgerVersion | "full";
  user: { id: string };
};

type LedgerRollbackRejected = BaseParentEvent & {
  type: "LedgerRollbackRejected";
  user: { id: string };
};

type LedgerApprovalRejected = BaseParentEvent & {
  type: "LedgerApprovalRejected";
  user: { id: string };
};

type ParentEvent = Extract<
  | CompanyFoundationEvent
  | DecreaseCapitalCancelSharesEvent
  | DecreaseCapitalEvent
  | IncreaseCapitalBonusIssueEvent
  | IncreaseCapitalEvent
  | LedgerApprovalEvent
  | LedgerApprovalInitializedEvent
  | LedgerRollbackEvent
  | PledgedSharesUpdateEvent
  | ShareCertificatesUpdateEvent
  | ShareClassUpdateEvent
  | ShareIssueEvent
  | ShareReclassificationByClassEvent
  | ShareReclassificationByRangeEvent
  | ShareSplitEvent
  | ReverseShareSplitEvent
  | ShareTransferEvent
  | LedgerPolicyApproval
  | LedgerRollbackPending
  | LedgerRollbackRejected
  | LedgerApprovalRejected,
  { type: ParentEventType }
>;

export type {
  CompanyFoundationEvent,
  DecreaseCapitalCancelSharesEvent,
  DecreaseCapitalEvent,
  IncreaseCapitalBonusIssueEvent,
  IncreaseCapitalEvent,
  LedgerApprovalEvent,
  LedgerApprovalInitializedEvent,
  LedgerApprovalRejected,
  LedgerPolicyApproval,
  LedgerRollbackEvent,
  LedgerRollbackPending,
  LedgerRollbackRejected,
  ParentEventType,
  PledgedSharesUpdateEvent,
  ReverseShareSplitEvent,
  ShareAllocationEvent,
  ShareCertificatesUpdateEvent,
  ShareClassUpdateEvent,
  ShareIssueEvent,
  ShareReclassificationByClassEvent,
  ShareReclassificationByRangeEvent,
  ShareSplitEvent,
  ShareTransferEvent,
  ParentEvent as TParentEvent,
};
export { hiddenLedgerEvents, parentEventTypes };
