import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useParentEventsQuery } from "../../api/blockchain/events";
import { useEntitiesQuery } from "../../api/rest/entities";
import { useSharedViewsQuery } from "../../api/rest/views";
import { Button } from "../../components/design-system/Button";
import { Loading } from "../../components/design-system/Loading";
import { Tab } from "../../components/design-system/Tab";
import { PageWrapper } from "../../components/PageWrapper";
import { useCurrentCompany } from "../../context/account";
import { useSession } from "../../context/session";
import { filterEvents } from "../../hooks/useCompanyEvents/filter-events";
import { hasRequiredPermission } from "../../utils/permissions";
import { ViewDialog } from "./ViewDialog";
import { ViewsTable } from "./ViewsTables";

const ViewSharingPage = () => {
  const i18n = useTranslation();
  const [sortBy, setSortBy] = useState("created-desc");
  const [editView, setEditView] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const currentCompany = useCurrentCompany();
  const viewsQuery = useSharedViewsQuery(currentCompany?.orgNumber);
  const views = viewsQuery.data || [];
  const { user } = useSession();
  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany?.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const events = filterEvents(eventsQuery.data?.data || []);
  const entitiesQuery = useEntitiesQuery(currentCompany?.orgNumber);
  const entities = entitiesQuery.data || [];

  if (!user || !currentCompany) {
    return null;
  }

  if (
    viewsQuery.isLoading ||
    eventsQuery.isLoading ||
    entitiesQuery.isLoading
  ) {
    return <Loading />;
  }

  const canEdit = hasRequiredPermission("Administrator", currentCompany, user);
  const now = new Date();

  return (
    <PageWrapper>
      <div className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h1 className="tw-text-2xl tw-font-medium">
            {i18n.t("label.views")}
          </h1>
          <p className="tw-max-w-3xl tw-text-sm tw-text-secondary">
            {i18n.t("views.description")}
          </p>
        </div>
        {views.length > 0 && canEdit && (
          <Button
            variant="solid"
            color="primary"
            onClick={() => setShowDialog(true)}
          >
            {i18n.t("views.add")}
          </Button>
        )}
      </div>
      <Tab.Group>
        <Tab.List className="tw-pb-4">
          <Tab>{i18n.t("views.tab.active")}</Tab>
          <Tab
            disabled={
              views.filter((view) => new Date(view.expiryDate) < now).length ===
              0
            }
          >
            {i18n.t("views.tab.inactive")}
          </Tab>
        </Tab.List>
        <Tab.Panel>
          <ViewsTable
            views={views.filter((view) => new Date(view.expiryDate) >= now)}
            entities={entities}
            events={events}
            sortBy={sortBy}
            setSortBy={setSortBy}
            setEditView={setEditView}
            setShowCreateView={() => setShowDialog(true)}
          />
        </Tab.Panel>
        <Tab.Panel>
          <ViewsTable
            views={views.filter((view) => new Date(view.expiryDate) < now)}
            entities={entities}
            events={events}
            sortBy={sortBy}
            setSortBy={setSortBy}
            setEditView={setEditView}
            setShowCreateView={() => setShowDialog(true)}
          />
        </Tab.Panel>
      </Tab.Group>
      {showDialog && (
        <ViewDialog
          onClose={() => setShowDialog(false)}
          onSuccess={() => setShowDialog(false)}
          orgNumber={currentCompany.orgNumber}
        />
      )}
      {editView && (
        <ViewDialog
          onClose={() => setEditView("")}
          onSuccess={() => setEditView("")}
          orgNumber={currentCompany.orgNumber}
          defaultValues={views.find((x) => x.viewId === editView)}
        />
      )}
    </PageWrapper>
  );
};

export { ViewSharingPage };
