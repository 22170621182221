import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BvBanner } from "../../../components/BvBanner/BvBanner";
import {
  FormError,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { Input } from "../../../components/design-system/Input";
import useLatestVersion from "../../../hooks/useLatestVersion";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import { dateToIsoString } from "../../../utils/date";
import { formatNumber } from "../../../utils/format";
import logo from "../../companies/[companyId]/bv.svg";
import { CapitalAmount } from "./CapitalAmount";
import type { ShareIssueFormState } from "./types";

type InvestmentAmountProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  form: UseFormReturn<ShareIssueFormState>;
  maxShares?: number;
  isAutofilled: boolean;
};

const InvestmentAmount = ({
  currentCompany,
  form,
  maxShares,
  isAutofilled,
}: InvestmentAmountProps) => {
  const { t } = useTranslation();
  const lastEventDate = useLatestVersion();
  const { control, formState, register, watch } = form;

  const { shareCapital } = watch();

  return (
    <>
      <div className="tw-flex tw-flex-col tw-justify-center tw-gap-6">
        <FormGroup>
          <FormLabel
            htmlFor="formationDate"
            className="tw-flex tw-items-center tw-gap-2"
          >
            {t("label.date")}{" "}
            {isAutofilled && (
              <img src={logo} alt="bolagsverket-logo" className="tw-h-4" />
            )}
          </FormLabel>
          <Controller
            control={control}
            render={({ field: { ref, name, onChange, value }, fieldState }) => (
              <>
                <Input
                  id="formationDate"
                  value={value}
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  type="date"
                  className="tw-w-full"
                  max={dateToIsoString(new Date())}
                  min={lastEventDate && dateToIsoString(lastEventDate.date)}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            name="shareCapital.formationDate"
            rules={{ required: t("error.validation.required") }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel
            htmlFor="totalShares"
            className="tw-flex tw-items-center tw-gap-2"
          >
            {t("label.totalNrOfShares")}
            {isAutofilled && (
              <img src={logo} alt="bolagsverket-logo" className="tw-h-4" />
            )}
          </FormLabel>
          <Input
            id="totalShares"
            {...register("shareCapital.totalShares", {
              required: t("error.validation.required"),
              valueAsNumber: true,
              min: {
                value: 1,
                message: t("error.validation.range.min", { min: 1 }),
              },
            })}
            type="number"
            step={1}
          />
          <FormError>
            {formState.errors.shareCapital?.totalShares?.message}
          </FormError>
          {maxShares &&
          shareCapital.totalShares &&
          shareCapital.totalShares > maxShares ? (
            <BvBanner
              title={t("events.issue.totalShares.validation.title")}
              description={t(
                "events.issue.totalShares.validation.description",
                {
                  maxShares: formatNumber(maxShares),
                  addedShares: formatNumber(shareCapital.totalShares),
                }
              )}
            />
          ) : undefined}
        </FormGroup>
        <FormGroup>
          <FormLabel
            htmlFor="capitalAmount"
            isOptional
            className="tw-flex tw-items-center tw-gap-2"
          >
            {t("label.investmentAmount")}
            {isAutofilled && (
              <img src={logo} alt="bolagsverket-logo" className="tw-h-4" />
            )}
          </FormLabel>
          <Controller
            control={control}
            name="shareCapital.capitalAmount"
            render={({ field: { value, onChange }, fieldState }) => (
              <>
                <CapitalAmount
                  amount={value}
                  currency={currentCompany.settings?.currency}
                  totalShares={shareCapital.totalShares}
                  onChange={onChange}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
          />
        </FormGroup>
      </div>
    </>
  );
};

export default InvestmentAmount;
