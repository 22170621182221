import type { RawTxResponse } from "@capchapdev/admin-api";
import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TxRejectedError } from "postchain-client";

import { useBlockchainClient } from "../../context/blockchain";
import * as monitoring from "../../utils/monitoring";
import type { IRequestError } from "..";
import { BlockchainClient } from "../blockchain/client";
import useClient, { URL } from "./client";
import { RawTxResponseSchema } from "./users";

const completeOnboarding = async (
  blockchainClient: BlockchainClient,
  orgNumber: string
) => {
  const client = useClient({ hasAuth: true });
  const response = await client<RawTxResponse>(
    `${URL.ADMIN}/Transaction/CompleteOnboarding/${orgNumber}`
  );

  const result = RawTxResponseSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return blockchainClient.sendTransaction(response.rawTx);
  }

  return blockchainClient.sendTransaction(result.data.rawTx);
};

const useCompleteOnboardingMutation = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError | TxRejectedError>
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();

  return useMutation<unknown, IRequestError>(
    () => completeOnboarding(blockchainClient, orgNumber),
    {
      ...options,
      onSuccess: (data, vars, context) => {
        queryClient.invalidateQueries(["versions", orgNumber]);
        queryClient.invalidateQueries(["parentEvents", orgNumber]);
        queryClient.invalidateQueries(["shareTypes", orgNumber, ""]);
        queryClient.invalidateQueries(["ledger", orgNumber, ""]);
        queryClient.invalidateQueries(["companyShareholders", orgNumber]);
        queryClient.invalidateQueries(["companyShareblocks", orgNumber]);
        if (options?.onSuccess) {
          options.onSuccess(data, vars, context);
        }
      },
    }
  );
};

export { useCompleteOnboardingMutation };
