import { useVersionsQuery } from "../../api/blockchain/events";
import { useCompanyEvents } from "../../hooks/useCompanyEvents";
import type { DateVersion } from "../../types/models/shares";
import { getFormattedLedgerVersion } from "../../utils/date";

type LedgerVersionDetails = DateVersion & { isApproved: boolean };

const useLedgerVersions = (
  orgNumber = "",
  status: "all" | "approved" = "all"
): LedgerVersionDetails[] => {
  const versionsQuery = useVersionsQuery(orgNumber);
  const versions = versionsQuery.data || [];

  const { approvedEvents, pendingEvents, draftEvents, pendingRollbackEvents } =
    useCompanyEvents(orgNumber);
  const allEvents = [
    ...approvedEvents,
    ...pendingEvents,
    ...draftEvents,
    ...pendingRollbackEvents,
  ];

  // Create a set of event dates for efficient filtering
  // This ensures we only include versions that have corresponding events in the data
  const eventDates = new Set(allEvents.map((event) => event.date));

  return versions
    .map((version) => {
      const dateVersion = getFormattedLedgerVersion(version.date);
      return {
        ...dateVersion,
        isApproved:
          version.status === "Approved" || version.status === "PendingRollback",
      };
    })
    .filter((v) => eventDates.has(v.formatedValue))
    .filter((v) => status === "all" || v.isApproved);
};
export { type LedgerVersionDetails, useLedgerVersions };
