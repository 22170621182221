import { useTranslation } from "react-i18next";

import { useSession } from "../../context/session";
import { Button } from "../design-system/Button";
import { Dialog } from "../design-system/Dialog";

type LogoutConfirmationProps = {
  onClose: () => void;
};

const LogoutConfirmation = ({ onClose }: LogoutConfirmationProps) => {
  const i18n = useTranslation();
  const { onSignout } = useSession();

  return (
    <Dialog
      isOpen
      title={i18n.t("logout.confirmation")}
      actions={
        <>
          <Button onClick={onClose}>{i18n.t("label.cancel")}</Button>
          <Button variant="solid" color="primary" onClick={onSignout}>
            {i18n.t("label.logout")}
          </Button>
        </>
      }
      onClose={onClose}
    />
  );
};

export { LogoutConfirmation };
