import { useMemo } from "react";

import { useCurrentCompany } from "../../context/account";
import { useCompanyEvents } from "../../hooks/useCompanyEvents";
import type { TParentEvent } from "../../types/models/events";
import { sortVersions } from "../../utils/versions-utils";
import type { LedgerVersionOption } from "./SelectVersion";

export type TypeEvent = "approved" | "draft" | "pending";

/**
 * Counts the occurrences of a specific event within a list of events.
 * @param events - The list of events.
 * @param foundEvent - The specific event to count occurrences of.
 * @returns The number of occurrences of the specific event.
 */
export const countEventTypeOccurrences = (
  events: TParentEvent[],
  foundEvent: TParentEvent
): number => {
  const eventType = foundEvent.type;

  let count = 1;
  const eventsReversed = [...events].reverse();
  for (const event of eventsReversed) {
    if (event === foundEvent) {
      break;
    }
    if (
      event.type === eventType &&
      event.date.split(".")[0] === foundEvent.date.split(".")[0]
    ) {
      count++;
    }
  }

  return count;
};

/**
 * Provides information about events related to a given ledger version.
 * @param data - The data about the ledger version.
 * @returns An object containing various details about the events related to the ledger version.
 */
export const useEventsData = (data: LedgerVersionOption) => {
  const { eventMap, allEvents, latestApprovedEvent } = useEventMap();

  const eventDate = `${data.label}.${data.value.version}`;
  const eventData = eventMap.get(eventDate);

  return {
    allEvents,
    foundEvent: eventData?.event,
    typeEvent: eventData?.type || "draft",
    latestApprovedEvent,
  };
};

/**
 * Creates a map of events for efficient lookup by date
 * @returns A Map with event dates as keys and event data as values
 */
export const useEventMap = () => {
  const currentCompany = useCurrentCompany();
  const { draftEvents, pendingEvents, approvedEvents, pendingRollbackEvents } =
    useCompanyEvents(currentCompany?.orgNumber);

  const eventMap = useMemo(() => {
    return [
      ...draftEvents,
      ...pendingEvents,
      ...approvedEvents,
      ...pendingRollbackEvents,
    ].reduce((map, event) => {
      const type: TypeEvent =
        event.status === "PendingRollback"
          ? "approved"
          : (event.status.toLowerCase() as TypeEvent);

      map.set(event.date, { event, type });
      return map;
    }, new Map<string, { event: TParentEvent; type: TypeEvent }>());
  }, [draftEvents, pendingEvents, approvedEvents, pendingRollbackEvents]);

  const allEvents = [
    ...draftEvents,
    ...pendingEvents,
    ...approvedEvents,
    ...pendingRollbackEvents,
  ];
  const approvedAndPendingRollback = [
    ...approvedEvents,
    ...pendingRollbackEvents,
  ];
  const sortedVersions = sortVersions(
    approvedAndPendingRollback.map((e) => e.date)
  );

  // Determine the latest approved event (including those pending rollback)
  // This is used to highlight the current active version in the dropdown
  const latestApprovedEvent =
    sortedVersions.length > 0
      ? approvedAndPendingRollback.find(
          (e) => e.date === sortedVersions[sortedVersions.length - 1]
        )
      : undefined;

  return {
    eventMap,
    allEvents,
    latestApprovedEvent,
  };
};
