import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { Entity } from "../../../../../types/models/entities";
import type { Policy } from "../../../../../utils/policy";
import { assertNever } from "../../../../../utils/type";

type PolicyDetailsProps = {
  rule: Policy;
  entities: Entity[];
  approverIds?: string[];
  percentage?: number;
};

const PolicyDetails = ({
  rule,
  approverIds = [],
  entities,
  percentage = 0,
}: PolicyDetailsProps) => {
  const i18n = useTranslation();

  const [title, description] = useMemo(() => {
    function formatManualApprovers() {
      const haveEntities = entities?.length > 0;

      if (!haveEntities) {
        return "";
      }

      const approversNames =
        approverIds.map((approverId) => {
          const entityInfo = entities.find(
            (entity) => entity.id === approverId
          );
          return entityInfo?.name ?? approverId;
        }) ?? [];

      return approversNames.join(", ");
    }

    switch (rule) {
      case "All":
        return [
          i18n.t("initiatePolicy.allTitle"),
          i18n.t("initiatePolicy.allDescription"),
        ];
      case "Any":
        return [
          i18n.t("initiatePolicy.anyTitle"),
          i18n.t("initiatePolicy.anyDescription"),
        ];
      case "Manual":
        return [i18n.t("initiatePolicy.manualTitle"), formatManualApprovers()];
      case "Number":
        return [
          i18n.t("initiatePolicy.numberTitle"),
          i18n.t("initiatePolicy.numberDescription"),
        ];
      case "None":
        return [
          i18n.t("initiatePolicy.noneTitle"),
          i18n.t("initiatePolicy.noneDescription"),
        ];
      case "Percentage":
        return [
          i18n.t("initiatePolicy.percentageTitle", {
            percentage: rule === "Percentage" && percentage,
          }),
          i18n.t("initiatePolicy.percentageDescription"),
        ];
      default:
        assertNever(rule);
        return ["", ""];
    }
  }, [rule, entities, approverIds, i18n, percentage]);

  return (
    <div className="tw-flex-auto">
      <h4 className="tw-text-sm tw-font-medium tw-text-kvanta-primary">
        {title}
      </h4>
      <p className="tw-text-sm tw-leading-tight tw-text-kvanta-primary">
        {description}
      </p>
    </div>
  );
};

export default PolicyDetails;
