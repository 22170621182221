import { MagicWand } from "@phosphor-icons/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { convertErrorToTi18nKey } from "../../../../../../api";
import { useUserConfirmedLedgerAsOfficial } from "../../../../../../api/blockchain/officialLedger";
import {
  useApprovalRuleProposalQuery,
  useUsersQuery,
} from "../../../../../../api/blockchain/users";
import { getApprovalPolicyRule } from "../../../../../../api/rest/approval-rule-policy";
import authTest from "../../../../../../api/rest/auth/authTest";
import { useEntitiesQuery } from "../../../../../../api/rest/entities";
import {
  useApproveRule,
  useConfirmLedgerAsOfficialAndApproveRule,
} from "../../../../../../api/rest/events";
import BankIdSign from "../../../../../../auth/BankId/BankIdSign";
import { TruidSign } from "../../../../../../auth/Truid/TruidSign";
import { Alert } from "../../../../../../components/design-system/Alert";
import { Button } from "../../../../../../components/design-system/Button";
import {
  BankIdIcon,
  TruidIcon,
} from "../../../../../../components/design-system/icons";
import { Loading } from "../../../../../../components/design-system/Loading";
import { ProgressDialog } from "../../../../../../components/design-system/ProgressDialog";
import { PageWrapper } from "../../../../../../components/PageWrapper";
import { featureToggles } from "../../../../../../config";
import {
  getLoginTypeInStorage,
  useSession,
} from "../../../../../../context/session";
import { useToggles } from "../../../../../../hooks/useToggles";
import { APP_ROUTE } from "../../../../../../routes/constants";
import type { CompanyInformation } from "../../../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../../../types/models/company";
import * as monitoring from "../../../../../../utils/monitoring";
import { getApprovalPolicyProgressProps } from "../ApprovalPolicy.utils";
import type { NewPolicy } from "../change";
import PreviewNewPolicyProposalChanges from "../change/PreviewNewPolicyProposalChanges";
import { CancelDialog } from "../components/CancelDialog";
import Done from "../components/Done";

type Step = "PREVIEW_STEP" | "SIGN_STEP" | "DONE_STEP";

const steps: Readonly<Record<Step, number>> = Object.freeze({
  PREVIEW_STEP: 0,
  SIGN_STEP: 1,
  DONE_STEP: 2,
});

type PreviewProposalProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};

function saveStateToLocalStorage(orgNumber: string) {
  localStorage.setItem(
    "pendingApprovalPolicyReview",
    JSON.stringify({ orgNumber })
  );
}

function clearStateFromLocalStorage() {
  localStorage.removeItem("pendingApprovalPolicyReview");
}

const PreviewProposal = ({ currentCompany }: PreviewProposalProps) => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const usersQuery = useUsersQuery(currentCompany.orgNumber);
  const [searchParams] = useSearchParams();
  const [showCancelProposal, setShowCancelProposal] = useState(false);

  const approvalRuleProposal = useApprovalRuleProposalQuery(
    currentCompany.orgNumber
  );
  // # Remove once https://github.com/capchapdev/capchap-frontend/pull/1757 is completed
  const { user, loginType: loginTypeInState } = useSession();
  const loginTypeInStorage = getLoginTypeInStorage();
  const loginType = loginTypeInState ?? loginTypeInStorage;
  // #
  const [currentStep, setCurrentStep] = useState(steps.PREVIEW_STEP);
  const getMockedSignatureMutation = authTest.useGetMockedSignatureMutation();

  const userApprovedLedgerAsOfficialQuery = useUserConfirmedLedgerAsOfficial(
    currentCompany.orgNumber
  );

  const { isFeatureEnabled } = useToggles();
  const mockSignEnabled =
    isFeatureEnabled(featureToggles.ENABLE_MOCK_SIGN) &&
    user?.role === "Administrator";

  const userRoleInCompany = useMemo(() => {
    if (!usersQuery.isSuccess || !user) {
      return null;
    }
    return usersQuery.data?.find((u) => u.user.id === user.id)?.role;
  }, [user, usersQuery]);
  const mustConfirmLedgerAsOfficial = useMemo(() => {
    const userConfirmedLedgerAsOfficial =
      userApprovedLedgerAsOfficialQuery.data === 1;
    const ledgerConfirmedAsOfficial =
      currentCompany.confirmedAsOfficial.status === "Completed";
    return !userConfirmedLedgerAsOfficial && !ledgerConfirmedAsOfficial;
  }, [
    currentCompany.confirmedAsOfficial.status,
    userApprovedLedgerAsOfficialQuery.data,
  ]);

  const approveRule = useApproveRule(currentCompany.orgNumber, {
    onSuccess: () => {
      setCurrentStep(steps.DONE_STEP);
    },
  });

  const confirmLedgerAndApproveRule = useConfirmLedgerAsOfficialAndApproveRule(
    currentCompany.orgNumber,
    {
      onSuccess: () => {
        setCurrentStep(steps.DONE_STEP);
      },
    }
  );

  useEffect(() => {
    const code = searchParams.get("truidCode");
    const signatureRequestState = searchParams.get("truidState");
    if (!!code && !!signatureRequestState) {
      setCurrentStep(steps.SIGN_STEP);
    }
  }, [searchParams]);

  useEffect(() => {
    if (currentStep === steps.SIGN_STEP && loginType === "Truid") {
      saveStateToLocalStorage(currentCompany.orgNumber);
    }
  }, [currentStep, loginType]);

  const approvalPolicyProgressProps = useMemo(() => {
    if (
      approvalRuleProposal.isSuccess &&
      approvalRuleProposal.data &&
      entitiesQuery.isSuccess &&
      usersQuery.isSuccess &&
      user
    ) {
      return getApprovalPolicyProgressProps(
        approvalRuleProposal.data,
        entitiesQuery.data,
        usersQuery.data,
        user.id
      );
    }
    return null;
  }, [approvalRuleProposal, entitiesQuery, usersQuery, user]);

  const policyFromProposal: NewPolicy | null = useMemo(() => {
    if (approvalRuleProposal.isSuccess && approvalRuleProposal.data) {
      return {
        rule: getApprovalPolicyRule(approvalRuleProposal.data),
        percentage:
          approvalRuleProposal.data.rule === "BoardPercentage"
            ? approvalRuleProposal.data.percentage / 10
            : 0,
        approvers:
          approvalRuleProposal.data.rule === "SpecificUsers"
            ? approvalRuleProposal.data.users.map(({ id }) => id)
            : [],
      };
    }
    return null;
  }, [approvalRuleProposal]);

  const error = useMemo(() => {
    return (
      approvalRuleProposal.error ||
      entitiesQuery.error ||
      usersQuery.error ||
      approveRule.error ||
      getMockedSignatureMutation.error
    );
  }, [
    approvalRuleProposal.error,
    approveRule.error,
    entitiesQuery.error,
    usersQuery.error,
    getMockedSignatureMutation.error,
  ]);

  const isLoading = useMemo(
    () =>
      approvalRuleProposal.isLoading ||
      entitiesQuery.isLoading ||
      userApprovedLedgerAsOfficialQuery.isLoading ||
      usersQuery.isLoading,
    [
      approvalRuleProposal,
      entitiesQuery,
      usersQuery,
      userApprovedLedgerAsOfficialQuery,
    ]
  );

  const onSign = useCallback(
    (signature: string) => {
      if (userRoleInCompany !== "BoardMember" && !mockSignEnabled) {
        return;
      }
      // Risk of triggering this callback multiple times because of rerenderings
      // so we need to check if there is a request in the flight
      if (mustConfirmLedgerAsOfficial) {
        if (confirmLedgerAndApproveRule.isIdle) {
          confirmLedgerAndApproveRule.mutate(signature);
        } else {
          console.warn("skipping confirmLedgerAndApproveRule mutation");
        }
      } else if (approveRule.isIdle) {
        approveRule.mutate(signature);
      } else {
        console.warn("skipping approveRule mutation");
      }
      clearStateFromLocalStorage();
    },
    [
      approveRule,
      confirmLedgerAndApproveRule,
      mustConfirmLedgerAsOfficial,
      mockSignEnabled,
      userRoleInCompany,
    ]
  );

  const stepComponent = useMemo(() => {
    if (error) {
      return (
        <div>
          <Alert className="tw-my-2" type="error">
            {i18n.t(convertErrorToTi18nKey(error))}
          </Alert>
        </div>
      );
    }
    const language: "sv" | "en" = i18n.i18n.language.split("-")[0] as
      | "sv"
      | "en";
    switch (currentStep) {
      case steps.PREVIEW_STEP:
        if (policyFromProposal) {
          return (
            <PreviewNewPolicyProposalChanges
              policy={policyFromProposal}
              acceptLedgerAsOfficial={
                currentCompany.confirmedAsOfficial?.status !== "Completed"
              }
              entities={entitiesQuery.data ?? []}
              showProgress
              progress={
                approvalPolicyProgressProps?.isPendingApproval
                  ? approvalPolicyProgressProps?.progress
                  : undefined
              }
            />
          );
        }
        return null;
      case steps.SIGN_STEP: {
        const manualApprovers =
          policyFromProposal?.approvers?.map((approver) => {
            const entity = entitiesQuery.data?.find((e) => e.id === approver);
            if (!entity) {
              monitoring.captureException(
                "Could not find entity for manual approver",
                {
                  extra: {
                    approver,
                    entities: entitiesQuery.data,
                  },
                }
              );
              throw new Error("Could not find entity for manual approver");
            }
            return entity.name;
          }) ?? [];

        const payload = {
          language,
          confirmAsOfficial: mustConfirmLedgerAsOfficial,
          manualApprovers,
          percentage:
            policyFromProposal?.rule === "All"
              ? 100
              : policyFromProposal?.rule === "Any"
              ? 0
              : policyFromProposal?.percentage,
        };
        if (loginType === "Truid") {
          return (
            <TruidSign
              request={payload}
              onSuccess={({ signature }) => onSign(signature)}
              onCancel={() => clearStateFromLocalStorage()}
            />
          );
        }
        return (
          <BankIdSign
            request={payload}
            onSuccess={({ signature }) => onSign(signature)}
            onCancel={() => setCurrentStep(currentStep - 1)}
          />
        );
      }
      case steps.DONE_STEP:
        return <Done orgNumber={currentCompany.orgNumber} />;
      default:
        return null;
    }
  }, [
    error,
    i18n,
    currentStep,
    policyFromProposal,
    loginType,
    onSign,
    currentCompany.orgNumber,
    mustConfirmLedgerAsOfficial,
    entitiesQuery.data,
    approvalPolicyProgressProps,
  ]);

  return (
    <ProgressDialog
      onClose={() => {
        navigate("../");
      }}
      isLoading={isLoading}
      totalSteps={
        approvalPolicyProgressProps?.isPendingApproval &&
        approvalPolicyProgressProps.isPendingCurrentUserApproval
          ? 1
          : 0
      }
      currentStep={currentStep}
      actions={
        <>
          {currentStep === steps.SIGN_STEP && (
            <Button
              className="tw-my-2 tw-mr-2 tw-w-full md:tw-w-auto"
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
              disabled={isLoading}
            >
              {i18n.t("label.back")}
            </Button>
          )}
          {currentStep === steps.PREVIEW_STEP && (
            <>
              {["BoardMember", "Administrator"].includes(
                userRoleInCompany ?? ""
              ) && (
                <>
                  <Button
                    className="tw-w-full md:tw-w-auto"
                    onClick={() => setShowCancelProposal(true)}
                  >
                    {i18n.t("approvalPolicy.cancel")}
                  </Button>
                  <Link
                    className="tw-w-full md:tw-w-auto"
                    to={`${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/settings/policy/change`}
                  >
                    <Button className="tw-w-full">
                      {i18n.t("label.changeProposal")}
                    </Button>
                  </Link>
                </>
              )}
              {approvalPolicyProgressProps?.isPendingApproval &&
                approvalPolicyProgressProps.isPendingCurrentUserApproval && (
                  <Button
                    className="tw-w-full md:tw-w-auto"
                    color="primary"
                    variant="solid"
                    prefix={
                      loginType === "Truid" ? (
                        <TruidIcon color="white" />
                      ) : (
                        <BankIdIcon />
                      )
                    }
                    onClick={() => setCurrentStep(steps.SIGN_STEP)}
                    isLoading={isLoading}
                  >
                    {i18n.t("approvalPolicy.approvePolicy")}
                  </Button>
                )}
            </>
          )}
          {currentStep === steps.PREVIEW_STEP &&
            mockSignEnabled &&
            approvalPolicyProgressProps?.isPendingApproval &&
            approvalPolicyProgressProps.isPendingCurrentUserApproval && (
              <Button
                className="tw-w-full md:tw-w-auto"
                color="kvanta-primary"
                variant="solid"
                prefix={<MagicWand />}
                onClick={() => {
                  getMockedSignatureMutation
                    .mutateAsync()
                    .then((mockedSignature) => {
                      if (mockedSignature) {
                        onSign(mockedSignature);
                      }
                      return mockedSignature;
                    })
                    .catch(() => {});
                }}
                isLoading={isLoading}
              >
                {i18n.t("approvalPolicy.mockSignAndApprovePolicy")}
              </Button>
            )}
          {currentStep === steps.DONE_STEP && (
            <Button
              className="tw-w-full md:tw-w-auto"
              color="primary"
              variant="solid"
              disabled={isLoading}
              onClick={() => {
                navigate("../");
              }}
            >
              {i18n.t("label.done")}
            </Button>
          )}
        </>
      }
    >
      <PageWrapper
        className={`tw-flex tw-h-full tw-max-w-xl tw-flex-col tw-pb-12 tw-pt-6 ${
          [steps.DONE_STEP, steps.SIGN_STEP].includes(currentStep)
            ? "tw-justify-center"
            : "tw-justify-start"
        }`}
      >
        {isLoading ? <Loading /> : stepComponent}
      </PageWrapper>
      {showCancelProposal && (
        <CancelDialog
          currentCompany={currentCompany}
          onClose={() => setShowCancelProposal(false)}
          onSuccess={() => navigate("../")}
        />
      )}
    </ProgressDialog>
  );
};

export default PreviewProposal;
