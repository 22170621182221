import type React from "react";

import { clsxm } from "../../utils/tailwind";
import { ErrorBoundary } from "../ErrorBoundary";

type PageWrapperProps = {
  children: React.ReactNode;
  className?: string;
  "data-testid"?: string;
};

const PageWrapper = ({
  children,
  className,
  "data-testid": testId,
}: PageWrapperProps) => (
  <div className={clsxm("tw-mx-auto", className)} data-testid={testId}>
    <ErrorBoundary>{children}</ErrorBoundary>
  </div>
);

PageWrapper.Header = ({ children }: { children: React.ReactNode }) => (
  <h1 className="tw-mb-3">{children}</h1>
);

export { PageWrapper };
