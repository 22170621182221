import type { UseTranslationResponse } from "react-i18next";

import { getEventTypeFormatted } from "../components/ShareBlocks/DetailedBlockRow";
import { ShareHolderWithDilution } from "../pages/CompanyShares/ShareHolders.utils";
import { EntitiesMap } from "../types/models/entities";
import { RestrictionCase } from "../types/models/restriction-case";
import {
  Shareblock,
  ShareBlockHistory,
  ShareTypeClause,
  ShareTypesMap,
} from "../types/models/shares";
import { getCountryName } from "./country";
import { getFormattedDate } from "./date";
import {
  formatAddress,
  formatCurrency,
  formatNumber,
  formatRefId,
} from "./format";
import { SpreadsheetData } from "./xlsx";

type ShareholderDataTotals = {
  sharesByType: Record<string, number>;
  shares: number;
  votes: number;
  dilutedShares: number;
  dilutedVotes: number;
};

const generateShareholderData = (
  shareholders: ShareHolderWithDilution[],
  displayDiluted: boolean,
  ledger: ShareholderDataTotals,
  i18n: UseTranslationResponse<"translation">,
  lng?: string
): SpreadsheetData => {
  const sharesLabel = i18n.t("label.shares", { lng }).toLowerCase();
  return shareholders.map((s) => ({
    [i18n.t("label.shareholder", { lng })]: s.entity.name,
    [i18n.t("label.type", { lng })]: i18n.t(
      s.entity.type === "Private" ? "label.private" : "label.company",
      { lng }
    ),
    [i18n.t("label.shareholderSince", { lng })]:
      getFormattedDate(s.holder.since) || "",
    [i18n.t("label.id", { lng })]: formatRefId({
      refId: s.entity.refId,
      countryCode: s.entity.countryCode,
      birthDate: s.entity.birthDate,
      type: s.entity.type,
    }),
    [i18n.t("label.country", { lng })]:
      getCountryName(s.entity.countryCode) || "",
    [i18n.t("label.address", { lng })]: s.entity.contact.address
      ? formatAddress(s.entity.contact.address, lng)
      : "",
    ...Object.fromEntries(
      Object.keys(ledger.sharesByType).map((key) => [
        `${key} ${sharesLabel}`,
        s.shareTypes[key] || 0,
      ])
    ),
    [i18n.t("label.shares", { lng })]: s.totalShares || 0,
    ...(displayDiluted &&
      ledger.dilutedShares && {
        [i18n.t("label.diluted.shares", { lng })]: ledger.dilutedShares,
      }),
    [i18n.t("label.votes", { lng })]: s.totalVotes || 0,
    ...(displayDiluted &&
      ledger.dilutedVotes && {
        [i18n.t("label.diluted.votes", { lng })]: ledger.dilutedVotes,
      }),
  }));
};

const generateShareledgerData = (
  shareblocks: Shareblock[],
  notes: { [shareholder: string]: number },
  entitiesMap: EntitiesMap,
  shareTypesMap: ShareTypesMap,
  shareBlockHistory: ShareBlockHistory[],
  conditionOptions: {
    value: ShareTypeClause;
    label: string;
  }[],
  i18n: UseTranslationResponse<"translation">,
  lng?: string
): SpreadsheetData => {
  return shareblocks
    .filter((x) => !x.cancelled)
    .map((block) => {
      if (!block) {
        return undefined;
      }
      const holder = entitiesMap[block.holder.id];
      if (!holder) {
        return undefined;
      }
      const shareType = shareTypesMap[block.type];
      if (!shareType) {
        return undefined;
      }

      const creditor = entitiesMap[block.creditor?.id || ""];
      const matchingHistory = shareBlockHistory.find(
        (history) =>
          (history.holder.id === block.holder.id ||
            history.holder.refId === entitiesMap[block.holder.id]?.refId) &&
          history.start === block.start &&
          history.end === block.end
      );
      const eventType =
        matchingHistory &&
        getEventTypeFormatted(
          i18n,
          matchingHistory.eventType,
          matchingHistory.eventDetails
        );

      const { start, end, type } = block;
      return {
        [i18n.t("label.from", { lng })]: formatNumber(start),
        [i18n.t("label.to", { lng })]: formatNumber(end),
        [i18n.t("label.shareType", { lng })]: type,
        [i18n.t("label.shareholder", { lng })]: holder.name,
        [i18n.t("label.id", { lng })]: formatRefId({
          refId: holder.refId,
          countryCode: holder.countryCode,
          birthDate: holder.birthDate,
          type: holder.type,
        }),
        ...(notes[holder.id] !== undefined && {
          Note: {
            t: "s",
            v: `Note ${notes[holder.id]! + 1}`,
            l: { Target: `#Notes!A${notes[holder.id]! + 2}` },
          },
        }),
        [i18n.t("label.ownershipInfo", { lng })]: holder.contact.address
          ? formatAddress(holder.contact.address, lng)
          : "",
        [i18n.t("label.entry", { lng })]: matchingHistory
          ? getFormattedDate(matchingHistory.date) || ""
          : "",
        [i18n.t("shares.history.end", { lng })]: eventType || "",
        ...(block.hasCertificateSince && {
          [i18n.t("shares.certificates", { lng })]: i18n.t(
            "shares.certificates.issued",
            {
              lng,
              date: getFormattedDate(block.hasCertificateSince),
            }
          ),
        }),
        ...(creditor && {
          [i18n.t("label.creditor", { lng })]: creditor ? creditor.name : "",
        }),
        ...(Object.values(shareType.condition).some(Boolean) && {
          [i18n.t("shares.restrictiveConditions", { lng })]: conditionOptions
            .filter((option) => !!shareType.condition[option.value])
            .map((x) => x.label)
            .join(", "),
        }),
        [i18n.t("shares.history", { lng })]:
          matchingHistory && matchingHistory.previousHolders
            ? matchingHistory.previousHolders
                .map(
                  (x) =>
                    `${entitiesMap[x.holder.id]?.name} (${getFormattedDate(
                      x.date
                    )})`
                )
                .join(", ")
            : "",
        ...(holder?.type === "Private" &&
          holder?.children && {
            [i18n.t("label.trustees", { lng })]: holder.children
              .map((x) => entitiesMap[x.id]?.name)
              .join(", "),
          }),
        [i18n.t("label.shares", { lng })]: end - start + 1,
        [i18n.t("label.votes", { lng })]:
          shareType.voteValue * (end - start + 1),
      };
    })
    .filter((x) => x !== undefined);
};

const generateNotesData = (
  notes: RestrictionCase[],
  entitiesMap: EntitiesMap,
  i18n: UseTranslationResponse<"translation">,
  lng?: string
) => {
  return notes.map((note, i) => ({
    [i18n.t("label.id")]: i + 1,
    [i18n.t("restrictionCase.type")]: i18n.t(
      `restrictionCase.type.${note.type}`
    ),
    [i18n.t("restrictionCase.totalShares", { lng })]: formatNumber(
      note.totalShares
    ),
    [`${i18n.t("restrictionCase.shareholder.current")} / ${i18n.t(
      "restrictionCase.shareholder.previous"
    )}`]: entitiesMap[note.shareholder.id]?.name || "",
    ...(note.buyer && {
      [i18n.t("restrictionCase.buyer")]: entitiesMap[note.buyer]?.name || "",
    }),
    [i18n.t("restrictionCase.date", { lng })]:
      getFormattedDate(note.date) || "",
    [i18n.t("restrictionCase.expiryDate", { lng })]:
      getFormattedDate(note.expiryDate) || "",
    [i18n.t("label.shareClass", { lng })]: note.shareType,
    ...(note.shareRanges.length > 0 && {
      [i18n.t("label.shareRanges", { lng })]: note.shareRanges
        .map((r) => `${r.start}-${r.end}`)
        .join(", "),
    }),
    [i18n.t("restrictionCase.pricePerShare")]: formatCurrency(
      note.pricePerShare,
      note.currency
    ),
    ...(note.responses.filter((r) => r.requestedShares > 0).length > 0 && {
      [i18n.t("restrictionCase.response.responded.yes")]: note.responses
        .filter((r) => r.requestedShares > 0)
        .map(
          (r) =>
            `${entitiesMap[r.shareholder.id]?.name} (${formatNumber(
              r.requestedShares
            )})`
        )
        .join(", "),
    }),
    ...(note.litigation.filter((l) => l.status === "ACTIVE").length > 0 && {
      [i18n.t("restrictionCase.litigation.title")]: note.litigation
        .filter((l) => l.status === "ACTIVE")
        .map(
          (l) =>
            `${entitiesMap[l.filedBy.id]?.name} (${getFormattedDate(
              l.filedDate
            )})`
        )
        .join(", "),
    }),
  }));
};

export { generateNotesData, generateShareholderData, generateShareledgerData };
export type { ShareholderDataTotals };
