import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  useCompanyDetailsQuery,
  useCompanyShareCapitalEventsQuery,
} from "../../../api/rest/administration";
import { useUpdateCapitalMutation } from "../../../api/rest/draft";
import { Button } from "../../../components/design-system/Button";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { Input } from "../../../components/design-system/Input";
import { TooltipV2 } from "../../../components/design-system/Tooltip/TooltipV2";
import { SelectCurrency } from "../../../components/SelectCurrency";
import { APP_ROUTE } from "../../../routes/constants";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import { dateToIsoString } from "../../../utils/date";
import { draftName } from "./constants";
import type { FormChangeEvent, FormState } from "./types";

type CompanyShareCapitalProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  initialData: FormState;
  onFormChange: FormChangeEvent;
  onSuccess: () => void;
};

const ShareCapital = ({
  currentCompany,
  initialData,
  onFormChange,
  onSuccess,
}: CompanyShareCapitalProps) => {
  const { register, handleSubmit, formState, control, setValue } = useForm({
    mode: "onChange",
    defaultValues: initialData.shareCapital,
  });

  const companyDetailsQuery = useCompanyDetailsQuery(currentCompany.orgNumber);
  const companyDetails = companyDetailsQuery.data || undefined;

  const shareCapitalEventsQuery = useCompanyShareCapitalEventsQuery(
    currentCompany.orgNumber
  );
  const shareCapitalEvents = shareCapitalEventsQuery.data?.history || [];
  const formation = shareCapitalEvents.find(
    (e) => e.type === "CompanyFoundation"
  );

  const { t } = useTranslation();
  const updateCapitalMutation = useUpdateCapitalMutation(
    currentCompany.orgNumber,
    draftName,
    {
      onSuccess: (_, formData) => {
        onFormChange({ shareCapital: formData });
        onSuccess();
      },
    }
  );
  const onSubmit = (data: FormState["shareCapital"]) => {
    updateCapitalMutation.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="tw-space-y-6">
      <FormGroup>
        <FormLabel htmlFor="formationDate">
          {t("label.companyFormationDate")}
        </FormLabel>
        <Controller
          control={control}
          render={({ field: { ref, name, onChange, value }, fieldState }) => (
            <>
              <div className="tw-flex tw-space-x-2">
                <div className="tw-w-full">
                  <Input
                    id="formationDate"
                    value={value}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    type="date"
                    className="tw-w-full"
                    max={dateToIsoString(new Date())}
                  />
                </div>
                {companyDetails?.formationDate && (
                  <TooltipV2 content={t("importDateFormation.description")}>
                    <Button
                      onClick={() => {
                        if (companyDetails?.formationDate) {
                          setValue(
                            "formationDate",
                            companyDetails.formationDate,
                            {
                              shouldValidate: true,
                              shouldTouch: true,
                              shouldDirty: true,
                            }
                          );
                        }
                        if (formation) {
                          setValue("capitalAmount", formation.capital, {
                            shouldValidate: true,
                          });
                          setValue("totalShares", formation.shares, {
                            shouldValidate: true,
                          });
                        }
                      }}
                    >
                      {t("label.import")}
                    </Button>
                  </TooltipV2>
                )}
              </div>
              <FormError>{fieldState.error?.message}</FormError>
            </>
          )}
          name="formationDate"
          rules={{ required: t("error.validation.required") }}
        />
      </FormGroup>
      <div className="tw-grid tw-gap-2 md:tw-grid-cols-2">
        <FormGroup>
          <FormLabel htmlFor="capitalAmount">
            {t("label.shareCapitalAmount")}
          </FormLabel>
          <div className="tw-flex tw-space-x-1">
            <Input
              id="capitalAmount"
              {...register("capitalAmount", {
                required: t("error.validation.required"),
                valueAsNumber: true,
                min: {
                  value: 1,
                  message: t("error.validation.range.min", { min: 1 }),
                },
              })}
              className="tw-flex-1"
              type="number"
              step={1}
            />
          </div>
          <FormError>{formState.errors.capitalAmount?.message}</FormError>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="currency">{t("label.currency")}</FormLabel>
          <Controller
            name="currency"
            control={control}
            render={({ field: { onChange, value }, fieldState }) => (
              <>
                <SelectCurrency value={value} onChange={onChange} />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            rules={{ required: t("error.validation.required") }}
          />
        </FormGroup>
      </div>
      <FormGroup>
        <FormLabel htmlFor="totalShares">
          {t("label.totalNrOfShares")}
        </FormLabel>
        <Input
          id="totalShares"
          {...register("totalShares", {
            required: t("error.validation.required"),
            valueAsNumber: true,
            min: {
              value: 1,
              message: t("error.validation.range.min", { min: 1 }),
            },
          })}
          type="number"
          step={1}
        />
        <FormError>{formState.errors.totalShares?.message}</FormError>
      </FormGroup>

      {updateCapitalMutation.error && (
        <FormErrorList error={updateCapitalMutation.error} />
      )}
      <div className="tw-full tw-flex tw-justify-end tw-space-x-2 tw-py-4">
        <Button>
          <Link
            to={`${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/onboarding`}
          >
            {t("label.back")}
          </Link>
        </Button>

        <Button
          isLoading={updateCapitalMutation.isLoading}
          variant="solid"
          color="primary"
          type="submit"
        >
          {t("label.continue")}
        </Button>
      </div>
    </form>
  );
};

export default ShareCapital;
