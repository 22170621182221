import {
  Briefcase,
  DotsThreeVertical,
  Sidebar as SidebarIcon,
} from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";

import { useCurrentCompany } from "../../../context/account";
import { APP_ROUTE } from "../../../routes/constants";
import { clsxm } from "../../../utils/tailwind";
import { AddCompany } from "../../AddCompany";
import { LogoutConfirmation } from "../../LogoutConfirmation";
import { LogoIcon } from "../icons";
import { Menu } from "../Menu";
import bg from "./background.svg";
import { CompanySwitcher } from "./CompanySwitcher";
import { MenuLink } from "./MenuLink";
import {
  SidebarOption,
  useCompanyLinks,
  useGlobalLinks,
} from "./SidebarOption";

const Sidebar = () => {
  const i18n = useTranslation();
  const [showAddCompany, setShowAddCompany] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  // Applies mobile only
  const [open, setOpen] = useState(false);
  const [showSignout, setShowSignout] = useState(false);
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const currentCompany = useCurrentCompany();
  const globalLinks = useGlobalLinks(() => setShowSignout(true));
  const companyLinks = useCompanyLinks();
  const tiers = [
    ...new Set(companyLinks.map((l) => l.tier).filter((l) => l !== undefined)),
  ];

  useEffect(() => {
    if (!isTabletOrMobileDevice) {
      return undefined;
    }

    // If user clicks away from the navbar on mobile, close it
    function handleClickOutside(event: MouseEvent) {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isTabletOrMobileDevice]);

  if (!currentCompany) {
    return <></>;
  }

  const isOpen = !isTabletOrMobileDevice || open;

  return (
    <>
      <div className="tw-static tw-fixed tw-z-50 tw-w-screen tw-bg-white tw-bg-opacity-50 tw-backdrop-blur-md lg:tw-hidden">
        <div className="tw-flex tw-items-center">
          <button
            type="button"
            onClick={() => setOpen(true)}
            className="tw-p-4"
          >
            <SidebarIcon />
          </button>
          <h3 className="tw-text-base tw-font-medium">
            {
              companyLinks.find(
                (c) => c.to && location.pathname.startsWith(c.to)
              )?.label
            }
          </h3>
        </div>
      </div>
      {isTabletOrMobileDevice && isOpen && (
        // Mobile background overlay
        <div className="tw-fixed tw-inset-0 tw-z-50 tw-flex tw-items-center tw-justify-center tw-bg-gray-800 tw-bg-opacity-30" />
      )}
      <nav
        ref={sidebarRef}
        className={clsxm(
          "tw-fixed tw-z-50 tw-mb-0 tw-block tw-h-full tw-min-h-screen tw-w-[240px] tw-border-r tw-border-neutral-300 tw-transition-transform tw-duration-300 tw-ease-in-out",
          {
            "tw--translate-x-full": !isOpen,
            "tw-translate-x-0": isOpen,
          }
        )}
      >
        {/* Background Image */}
        <img
          src={bg}
          className={clsxm(
            "tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover",
            { "tw-hidden": !isOpen }
          )}
          alt="background"
        />

        <div className="tw-h-full tw-min-h-screen tw-bg-white tw-bg-opacity-70 tw-backdrop-blur-xl">
          {/* Logo */}
          <Link to="/">
            <div className="tw-border-b tw-border-neutral-300 tw-px-4 tw-py-3">
              <LogoIcon className="tw-h-6" />
            </div>
          </Link>

          {/* Navigation Content */}
          <div className="tw-relative tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-pb-[200px]">
            <CompanySwitcher
              onClick={
                isTabletOrMobileDevice ? () => setOpen(false) : undefined
              }
              showAddCompany={() => setShowAddCompany(true)}
            />

            <div className="tw-flex tw-h-full tw-flex-col tw-gap-6 tw-overflow-y-auto">
              {tiers.map((tier) => (
                <div className="tw-flex tw-flex-col" key={tier}>
                  {companyLinks
                    .filter((l) => l.tier === tier)
                    .map((link) => (
                      <SidebarOption
                        link={link}
                        key={link.key}
                        onClick={() => setOpen(false)}
                      />
                    ))}
                </div>
              ))}
            </div>
          </div>

          {/* Footer Links */}
          <div className="tw-absolute tw-bottom-0 tw-w-full tw-border-t tw-border-neutral-300">
            <div className="tw-flex tw-w-full">
              <Link
                to={APP_ROUTE.OWNERSHIP}
                className="tw-flex tw-items-center tw-gap-2 tw-p-4 tw-text-sm tw-text-secondary"
              >
                <Briefcase className="tw-h-5 tw-w-5" />
                {i18n.t("dashboard.switch.ownership")}
              </Link>
              <Menu className="tw-border-l">
                <Menu.Button isDropdown={false} className="tw-border-none">
                  <DotsThreeVertical />
                </Menu.Button>
                <Menu.Items align="topLeft" className="tw-ml-[8px]">
                  {globalLinks.map((l) => (
                    <Menu.Item key={l.key}>
                      <MenuLink link={l} />
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Menu>
            </div>
          </div>
        </div>
      </nav>
      {showSignout && (
        <LogoutConfirmation onClose={() => setShowSignout(false)} />
      )}
      {showAddCompany && (
        <AddCompany onClose={() => setShowAddCompany(false)} />
      )}
    </>
  );
};

export { Sidebar };
