import type React from "react";
import { useTranslation } from "react-i18next";

import type { Condition } from "../../../types/models/shares";
import { formatNumber } from "../../../utils/format";
import { Badge } from "../../design-system/Badge";
import { Button } from "../../design-system/Button";
import { Description } from "../../design-system/Description";
import { Dialog } from "../../design-system/Dialog";
import { TooltipV2 } from "../../design-system/Tooltip/TooltipV2";
import { useRestrictiveConditionOptions } from "../../ShareTypes/SelectRestrictiveConditions";

type DialogProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  shares: number;
  votes: number;
  conditions: Condition;
  size?: "sm" | "md" | "lg";
};

const ShareBlockDialog: React.FC<DialogProps> = ({
  isOpen,
  onClose,
  title,
  shares,
  votes,
  conditions,
  size = "md",
}) => {
  const { t } = useTranslation();
  const conditionOptions = useRestrictiveConditionOptions();
  const hasTrueConditions = Object.values(conditions).some((value) => value);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title=""
      size={size}
      actions={<Button onClick={onClose}>{t("label.close")}</Button>}
      canClose={false}
      className="tw-p-2"
    >
      <div className="tw-flex tw-flex-col tw-gap-6">
        <h3>{title}</h3>
        <Description
          title={t("label.numberOfShares")}
          description={`${formatNumber(shares)} ${t("myShares.sharesSumUnit")}`}
          theme="grayBlack"
        />
        <Description
          title={t("label.numberOfVotesPerShare")}
          description={`${formatNumber(votes)}`}
          theme="grayBlack"
        />
        <Description
          title={t("shares.restrictiveConditions")}
          description={
            <div>
              {hasTrueConditions ? (
                <div>
                  {Object.entries(conditions).map(([key, value]) => {
                    const condition = conditionOptions.find(
                      (option) => option.value === key
                    );
                    return value ? (
                      <TooltipV2 content={condition?.description}>
                        <Badge
                          key={condition?.value}
                          color="none"
                          className="tw-mr-2"
                        >
                          {condition?.label}
                        </Badge>
                      </TooltipV2>
                    ) : null;
                  })}
                </div>
              ) : (
                <p>{t("shares.noConditions")}</p>
              )}
            </div>
          }
          theme="grayBlack"
        />
      </div>
    </Dialog>
  );
};

export { ShareBlockDialog };
