import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useShareholderBlocksQuery } from "../../../../api/blockchain/company";
import { useEntitiesQuery } from "../../../../api/rest/entities";
import { useRegisterCaseLitigation } from "../../../../api/rest/restriction-case";
import { Button } from "../../../../components/design-system/Button";
import { Dialog } from "../../../../components/design-system/Dialog";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../../components/design-system/FormGroup";
import { Input } from "../../../../components/design-system/Input";
import { SelectEntity } from "../../../../components/design-system/SelectEntity";
import { CompanyInformation } from "../../../../types/models/administration";
import { CompanyInvolvement } from "../../../../types/models/company";
import { CreateRestrictionCaseLitigation } from "../../../../types/models/restriction-case";

type RestrictionCaseLitigationFormProps = {
  company: CompanyInformation | CompanyInvolvement;
  caseId: string;
  minDate: string;
  onClose: () => void;
};

const formId = "case-litigation-response";

const RestrictionCaseLitigationForm = ({
  company,
  caseId,
  minDate,
  onClose,
}: RestrictionCaseLitigationFormProps) => {
  const i18n = useTranslation();
  const { handleSubmit, control } = useForm<CreateRestrictionCaseLitigation>({
    mode: "onSubmit",
  });

  const shareholdersQuery = useShareholderBlocksQuery(company.orgNumber);
  const entitiesQuery = useEntitiesQuery(company.orgNumber);
  const entities = entitiesQuery.data || [];
  const shareholders = shareholdersQuery.data || [];
  const shareholderEntities = entities.filter((e) =>
    shareholders.some((s) => s.id === e.id)
  );

  const mutation = useRegisterCaseLitigation(company.orgNumber, caseId, {
    onSuccess: onClose,
  });

  return (
    <Dialog
      isOpen
      title={i18n.t("restrictionCase.litigation.add")}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} disabled={mutation.isLoading}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            form={formId}
            isLoading={mutation.isLoading}
          >
            {i18n.t("label.save")}
          </Button>
        </>
      }
      isLoading={mutation.isLoading}
    >
      <form
        className="tw-space-y-4 tw-pt-4"
        onSubmit={(event) => {
          event.stopPropagation();

          return handleSubmit((data) => {
            mutation.mutate(data);
          })(event);
        }}
        id={formId}
      >
        <FormGroup>
          <FormLabel htmlFor="filedBy">
            {i18n.t("restrictionCase.litigation.filedBy")}
          </FormLabel>
          <Controller
            control={control}
            name="filedBy"
            render={({ field: { onChange, value }, fieldState }) => (
              <>
                <SelectEntity
                  id="filedBy"
                  options={shareholderEntities}
                  value={value}
                  onChange={onChange}
                  menuPosition="fixed"
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="date">
            {i18n.t("restrictionCase.litigation.filedDate")}
          </FormLabel>
          <Controller
            control={control}
            name="filedDate"
            render={({ field: { ref, name, onChange, value }, fieldState }) => (
              <>
                <Input
                  id="filedDate"
                  value={value}
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  type="date"
                  className="tw-w-full"
                  min={minDate}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        {mutation.error && <FormErrorList error={mutation.error} />}
      </form>
    </Dialog>
  );
};

export { RestrictionCaseLitigationForm };
