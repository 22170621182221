import { ArrowRight, Notepad } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import { useShareTypesQuery } from "../../../../api/blockchain/company";
import { useRestrictionCaseQuery } from "../../../../api/blockchain/restrictionCase";
import { Button } from "../../../../components/design-system/Button";
import { EmptyState } from "../../../../components/design-system/EmptyState";
import { Loading } from "../../../../components/design-system/Loading";
import { Tab } from "../../../../components/design-system/Tab";
import { TableV2 } from "../../../../components/design-system/TableV2";
import { TooltipV2 } from "../../../../components/design-system/Tooltip/TooltipV2";
import { PageWrapper } from "../../../../components/PageWrapper";
import { useCurrentCompany } from "../../../../context/account";
import { useSession } from "../../../../context/session";
import { APP_ROUTE } from "../../../../routes/constants";
import { RestrictionCase } from "../../../../types/models/restriction-case";
import { getFormattedDate } from "../../../../utils/date";
import { formatNumber } from "../../../../utils/format";
import { hasRequiredPermission } from "../../../../utils/permissions";
import { RestrictionCaseForm } from "./RestrictionCaseForm";

type RestrictionCaseTableProps = {
  cases: RestrictionCase[];
  sortBy: string;
  setSortBy: (value: string) => void;
  orgNumber: string;
};

const RestrictionCaseTable = ({
  cases,
  sortBy,
  setSortBy,
  orgNumber,
}: RestrictionCaseTableProps) => {
  const i18n = useTranslation();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    <TableV2
      columns={[
        {
          name: "type",
          title: i18n.t("restrictionCase.type"),
          key: true,
          sortable: true,
        },
        {
          name: "totalShares",
          title: i18n.t("restrictionCase.totalShares"),
          key: !isTabletOrMobileDevice,
          sortable: true,
          className: "tw-flex tw-justify-end",
        },
        {
          name: "date",
          title: i18n.t("restrictionCase.date"),
          key: !isTabletOrMobileDevice,
          sortable: true,
        },
        {
          name: "actions",
          title: "",
          key: true,
          sortable: false,
        },
      ]}
      data={cases.map((n) => ({
        key: n.id,
        link: `${APP_ROUTE.COMPANIES}/${orgNumber}/restriction-cases/${n.id}`,
        type: (
          <p className="tw-text-sm">
            {i18n.t(`restrictionCase.type.${n.type}`)}
          </p>
        ),
        totalShares: (
          <p className="tw-text-right tw-text-sm">
            {formatNumber(n.totalShares)}
          </p>
        ),
        date: <p className="tw-text-sm">{getFormattedDate(n.date)}</p>,
        actions: (
          <div className="tw-flex tw-justify-end">
            <Link
              to={`${APP_ROUTE.COMPANIES}/${orgNumber}/restriction-cases/${n.id}`}
            >
              <ArrowRight />
            </Link>
          </div>
        ),
      }))}
      sortBy={sortBy}
      setSortBy={setSortBy}
    />
  );
};

const RestrictionCasesPage = () => {
  const i18n = useTranslation();
  const currentCompany = useCurrentCompany();
  const { user } = useSession();
  const [showForm, setShowForm] = useState(false);
  const [sortBy, setSortBy] = useState<string>("date-desc");
  const restrictionCasesQuery = useRestrictionCaseQuery(
    currentCompany?.orgNumber
  );
  const restrictionCases = restrictionCasesQuery.data || [];
  const shareTypesQuery = useShareTypesQuery(currentCompany?.orgNumber, "");
  const shareTypes = shareTypesQuery.data || [];
  const relevantShareTypes = shareTypes.filter(
    (s) => s.condition.offerOfFirstRefusal || s.condition.preemption
  );

  const sortShareholders = (cases: RestrictionCase[]) => {
    const [sort, sortOrder] = sortBy.split("-");
    const isAscending = sortOrder === "asc";
    const order = isAscending ? -1 : 1;

    if (sort === "type") {
      return cases.sort((a, b) => (a.type > b.type ? -1 * order : order));
    }

    if (sort === "totalShares") {
      return cases.sort((a, b) =>
        a.totalShares > b.totalShares ? -1 * order : order
      );
    }

    if (sort === "date") {
      return cases.sort((a, b) => (a.date > b.date ? -1 * order : order));
    }

    return cases.sort((a, b) => (a.status > b.status ? -1 * order : order));
  };

  const sortedCases = sortShareholders(restrictionCases);

  if (
    !currentCompany ||
    restrictionCasesQuery.isLoading ||
    shareTypesQuery.isLoading
  ) {
    return <Loading />;
  }

  return (
    <PageWrapper>
      <header className="tw-flex tw-justify-between tw-pb-6">
        <h1 className="tw-text-2xl tw-font-medium">
          {i18n.t("restrictionCase.title")}
        </h1>
        {hasRequiredPermission("Editor", currentCompany, user) && (
          <TooltipV2
            content={
              relevantShareTypes.length === 0 &&
              i18n.t("restrictionCase.create.disabled")
            }
          >
            <Button
              onClick={() => setShowForm(true)}
              color="primary"
              variant="solid"
              className="tw-hidden md:tw-block"
              disabled={relevantShareTypes.length === 0}
            >
              {i18n.t("restrictionCase.create")}
            </Button>
          </TooltipV2>
        )}
      </header>
      <Tab.Group>
        <Tab.List className="tw-pb-4">
          <Tab>{i18n.t("restrictionCase.status.active")}</Tab>
          <Tab>{i18n.t("restrictionCase.status.archived")}</Tab>
        </Tab.List>
        <Tab.Panel>
          <RestrictionCaseTable
            cases={sortedCases.filter((c) => c.status === "ACTIVE")}
            sortBy={sortBy}
            setSortBy={setSortBy}
            orgNumber={currentCompany.orgNumber}
          />
          {sortedCases.filter((c) => c.status === "ACTIVE").length === 0 && (
            <EmptyState
              icon={<Notepad size={32} />}
              title={i18n.t("restrictionCase.emptyState.title")}
              description={i18n.t("restrictionCase.emptyState.description")}
            />
          )}
        </Tab.Panel>
        <Tab.Panel>
          <RestrictionCaseTable
            cases={sortedCases.filter((c) => c.status === "ARCHIVED")}
            sortBy={sortBy}
            setSortBy={setSortBy}
            orgNumber={currentCompany.orgNumber}
          />
          {sortedCases.filter((c) => c.status === "ARCHIVED").length === 0 && (
            <EmptyState
              icon={<Notepad size={32} />}
              title={i18n.t("restrictionCase.emptyState.archived.title")}
              description={i18n.t(
                "restrictionCase.emptyState.archived.description"
              )}
            />
          )}
        </Tab.Panel>
      </Tab.Group>
      <div className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-hidden tw-gap-2 tw-border-t-[1px] tw-bg-white tw-px-4 tw-py-3 max-md:tw-block">
        <Button
          onClick={() => setShowForm(true)}
          color="primary"
          variant="solid"
          className="tw-w-full"
        >
          {i18n.t("restrictionCase.create")}
        </Button>
      </div>
      {showForm && (
        <RestrictionCaseForm
          company={currentCompany}
          onClose={() => setShowForm(false)}
        />
      )}
    </PageWrapper>
  );
};

export default RestrictionCasesPage;
