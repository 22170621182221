const gcd = (x: number, y: number): number => {
  return y === 0 ? x : gcd(y, x % y);
};

const calculateRatio = (before: number, after: number) => {
  const divisor = gcd(after, before);
  return { x: before / divisor, y: after / divisor };
};

export { calculateRatio };
