import { FilePdf } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useParams, useSearchParams } from "react-router-dom";

import { getAuthHeader } from "../../api/auth";
import { UNAUTHORIZED } from "../../api/rest/utils";
import {
  sharedViewDocumentDownload,
  useRefreshTokenMutation,
  useSharedViewQuery,
  useVerifyViewMutation,
} from "../../api/rest/views";
import { Badge } from "../../components/design-system/Badge";
import { Button } from "../../components/design-system/Button";
import { SearchIcon } from "../../components/design-system/icons";
import { Input } from "../../components/design-system/Input";
import { Loading } from "../../components/design-system/Loading";
import { Tab } from "../../components/design-system/Tab";
import { TooltipV2 } from "../../components/design-system/Tooltip/TooltipV2";
import { PublicPageLayout } from "../../components/PublicPageLayout";
import { Token } from "../../types/models/views";
import { downloadBlob } from "../../utils/download";
import * as monitoring from "../../utils/monitoring";
import {
  ShareholdersTable,
  SortBy,
} from "../CompanyShares/ShareholdersTable/ShareholdersTable";
import { AccessDenied } from "./AccessDenied";
import { AnimatedDot } from "./AnimatedDot/AnimatedDot";
import { Details } from "./Details";
import { ExternalViewEmailVerification } from "./EmailVerification";
import { ShareClasses } from "./ShareClasses";
import { TotalShares } from "./TotalShares";

const LOCALSTORAGE_KEY = "viewToken";

function loadTokenFromLocalStorage(viewId?: string): Token | undefined {
  const state =
    localStorage.getItem(LOCALSTORAGE_KEY) &&
    JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) ?? "{}");
  return state && viewId && state[viewId] && JSON.parse(state[viewId]);
}

function saveTokenToLocalStorage(viewId: string, token?: Token) {
  const current: { [v: string]: string } =
    localStorage.getItem(LOCALSTORAGE_KEY) &&
    JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) ?? "{}");
  if (token) {
    localStorage.setItem(
      LOCALSTORAGE_KEY,
      JSON.stringify({ ...current, [viewId]: JSON.stringify(token) })
    );
  } else {
    localStorage.setItem(
      LOCALSTORAGE_KEY,
      JSON.stringify(
        Object.fromEntries(
          Object.entries(current).filter(([key]) => key !== viewId)
        )
      )
    );
  }
}

const ExternalViewPage = () => {
  const i18n = useTranslation();
  const kvantaAuth = getAuthHeader();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<SortBy>("shares-desc");
  const params = useParams();
  const { companyId, viewId } = params;
  const [token, setToken] = useState<Token | undefined>(
    loadTokenFromLocalStorage(viewId)
  );
  const [searchParams] = useSearchParams();
  const encodedEmail = searchParams.get("encodedEmail");
  const verifyMutation = useVerifyViewMutation();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const mountedRef = useRef(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const updateToken = (t?: Token) => {
    if (!viewId) {
      throw new Error("No viewId");
    }
    saveTokenToLocalStorage(viewId, t);
    if (mountedRef.current) {
      setToken(t);
    }
  };
  const refreshTokenMutation = useRefreshTokenMutation({
    onSuccess: (data) => {
      updateToken(data.token);
      // Set isRefreshing flag to false when token refresh completes,
      // which will trigger the useEffect to refetch data if component is mounted
      if (mountedRef.current) {
        setIsRefreshing(false);
      }
    },
    onError: () => {
      updateToken(undefined);
      if (mountedRef.current) {
        setIsRefreshing(false);
      }
    },
  });

  const viewQuery = useSharedViewQuery(
    companyId,
    viewId,
    encodedEmail ? token?.accessToken : "",
    {
      onError: ({ status }) => {
        if (status === UNAUTHORIZED && token?.refreshToken && !isRefreshing) {
          if (mountedRef.current) {
            setIsRefreshing(true);
            refreshTokenMutation.mutate({ refreshToken: token.refreshToken });
          }
        }
      },
      enabled: !!kvantaAuth || (!!token?.accessToken && !isRefreshing),
    }
  );

  useEffect(() => {
    if (!isRefreshing && token?.accessToken && mountedRef.current) {
      viewQuery.refetch();
    }
  }, [isRefreshing, token?.accessToken]);

  const init = () => {
    if (companyId && viewId && encodedEmail && !token) {
      verifyMutation.mutate({
        orgNumber: companyId,
        viewId,
        email: encodedEmail,
      });
    }
  };

  useEffect(() => {
    init();
  }, [companyId, viewId, encodedEmail]);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // Invalid URL
  if (
    !companyId ||
    !viewId ||
    (!encodedEmail && !kvantaAuth) ||
    verifyMutation.error ||
    (viewQuery.error &&
      (kvantaAuth || !token || viewQuery.error.status !== UNAUTHORIZED))
  ) {
    return <AccessDenied />;
  }

  if (verifyMutation.isLoading) {
    return <Loading />;
  }

  if (!token && encodedEmail) {
    return (
      <ExternalViewEmailVerification
        orgNumber={companyId}
        viewId={viewId}
        encodedEmail={encodedEmail}
        onSuccess={(t) => updateToken(t)}
        newCode={init}
      />
    );
  }

  if (viewQuery.isLoading || !viewQuery.data) {
    return <Loading />;
  }

  const view = viewQuery.data;
  const shareTypeTotals = view.shareholders.reduce((acc, obj) => {
    Object.keys(obj.shareTypes).forEach((key) => {
      acc[key] = (acc[key] || 0) + (obj.shareTypes[key] || 0);
    });
    return acc;
  }, {} as Record<string, number>);

  const downloadView = async () => {
    setDownloadLoading(true);
    const response = await sharedViewDocumentDownload(
      companyId,
      viewId,
      i18n.i18n.language.split("-")[0] || "",
      encodedEmail ? token?.accessToken : ""
    );
    if (response.status === 200) {
      const blob = await response.blob();
      downloadBlob(
        blob,
        `${i18n.t("label.shareholders")}_${view.companyName}_${companyId}_${
          view.version
        }.pdf`
      );
    } else {
      console.error(response);
      monitoring.captureException(
        new Error("Error downloading view document"),
        {
          extra: {
            status: response.status,
            text: response.statusText,
            company: companyId,
            view: viewId,
          },
        }
      );
    }
    setDownloadLoading(false);
  };

  const shareholders = view.shareholders.filter(
    (x) =>
      !search ||
      x.entity.name.toLowerCase().includes(search.toLowerCase()) ||
      x.entity.refId.toLowerCase().includes(search.toLowerCase()) ||
      Object.keys(x.shareTypes).some((share_type) =>
        share_type.toLowerCase().includes(search.toLowerCase())
      )
  );

  return (
    <PublicPageLayout className="tw-w-full tw-pt-4 max-md:tw-px-4">
      <div className="tw-flex tw-flex-col tw-gap-4 max-md:tw-pb-4 md:tw-flex-row">
        <div className="tw-flex tw-h-fit tw-w-full tw-flex-col md:tw-rounded-lg md:tw-border">
          <div className="tw-flex tw-flex-col tw-justify-between tw-gap-2 md:tw-flex-row md:tw-items-center md:tw-border-b md:tw-p-4">
            <div className="tw-flex tw-flex-col tw-gap-2">
              <div>
                <h3 className="tw-text-xl ">{i18n.t("label.shareholders")}</h3>
                <h1 className="tw-text-2xl tw-font-medium ">
                  {view.companyName}
                </h1>
              </div>
              <TooltipV2
                className="tw-w-fit"
                content={i18n.t(
                  `externalView.status.${view.status}.description`
                )}
              >
                <Badge
                  className="tw-flex tw-items-center tw-gap-2"
                  color={view.status === "Approved" ? "success" : "none"}
                >
                  {view.status === "Approved" && <AnimatedDot />}
                  {i18n.t(`externalView.status.${view.status}`)}
                </Badge>
              </TooltipV2>
            </div>
            <div className="tw-flex tw-gap-2">
              <Input
                className="tw-flex-1"
                placeholder={i18n.t("label.search")}
                prefix={<SearchIcon />}
                type="search"
                value={search}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
              />
              <Button
                onClick={() => downloadView()}
                isLoading={downloadLoading}
                size={isTabletOrMobileDevice ? "md" : "lg"}
              >
                <FilePdf className="tw-h-6 tw-w-6" />
              </Button>
            </div>
          </div>
          <div className="tw-hidden md:tw-block">
            <ShareholdersTable
              displayDiluted={false}
              searchValue={search}
              shareholders={shareholders}
              totalShares={view.totalShares}
              totalVotes={view.totalVotes}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          </div>
        </div>
        <div className="tw-hidden tw-flex-col tw-gap-4 md:tw-flex">
          <Details
            companyId={companyId}
            created={view.createdAt}
            version={view.version}
          />
          <TotalShares
            shareholders={shareholders}
            totalShares={view.totalShares}
          />
          <ShareClasses
            shareClasses={view.shareTypes}
            shareTotals={shareTypeTotals}
          />
        </div>
        <div className="md:tw-hidden">
          <Tab.Group>
            <Tab.List className="tw-pb-4 md:tw-border-b">
              <Tab>{i18n.t("externalview.tab.shareholders")}</Tab>
              <Tab>{i18n.t("externalview.tab.overview")}</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <ShareholdersTable
                  displayDiluted={false}
                  searchValue={search}
                  shareholders={shareholders}
                  totalShares={view.totalShares}
                  totalVotes={view.totalVotes}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </Tab.Panel>
              <Tab.Panel>
                <div className="tw-flex tw-flex-col tw-gap-4">
                  <Details
                    companyId={companyId}
                    created={view.createdAt}
                    version={view.version}
                  />
                  <TotalShares
                    shareholders={shareholders}
                    totalShares={view.totalShares}
                  />
                  <ShareClasses
                    shareClasses={view.shareTypes}
                    shareTotals={shareTypeTotals}
                  />
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </PublicPageLayout>
  );
};

export { ExternalViewPage };
