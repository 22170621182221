import { IconContext } from "@phosphor-icons/react";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";

import { NotificationsContainer } from "./components/design-system/Notifications";
import BlockchainClientProvider from "./context/blockchain";
import { SessionProvider } from "./context/session";
import Routes from "./routes";
import {
  captureException,
  captureMessage,
  withScope,
} from "./utils/monitoring";

const queryClient = new QueryClient({
  /**
   * Using QueryCache for error handling instead of defaultOptions.onError ensures
   * the error handling cannot be overridden by individual useQuery/useMutation calls.
   *
   * @see https://tkdodo.eu/blog/react-query-error-handling
   * @see https://tanstack.com/query/v3/docs/react/reference/QueryCache#global-callbacks
   */
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      const mutationKey =
        Array.isArray(mutation.options.mutationKey) &&
        mutation.options.mutationKey.length > 0
          ? typeof mutation.options.mutationKey[0] === "string"
            ? mutation.options.mutationKey[0]
            : "unknown"
          : "unknown";

      console.error(`[Mutation Error] ${mutationKey}:`, {
        error,
        mutationKey: mutation.options.mutationKey,
        variables: mutation.state.variables,
      });

      withScope((scope) => {
        scope.setTag("type", "mutation");
        scope.setTag("mutationKey", mutationKey);
        scope.setExtra("fullMutationKey", mutation.options.mutationKey);

        if (mutation.state.variables) {
          scope.setExtra("variables", mutation.state.variables);
        }

        scope.setExtra("mutationState", {
          status: mutation.state.status,
          failureCount: mutation.state.failureCount,
          failureReason: mutation.state.failureReason,
        });

        if (error instanceof Error) {
          captureException(error);
        } else {
          captureMessage(`Unknown mutation error in ${mutationKey}`);
        }
      });
    },
  }),

  queryCache: new QueryCache({
    onError: (error, query) => {
      const queryKey =
        Array.isArray(query.queryKey) && query.queryKey.length > 0
          ? typeof query.queryKey[0] === "string"
            ? query.queryKey[0]
            : "unknown"
          : "unknown";

      console.error(`[Query Error] ${queryKey}:`, {
        error,
        queryKey: query.queryKey,
        queryHash: query.queryHash,
      });

      withScope((scope) => {
        scope.setTag("type", "query");
        scope.setTag("queryKey", queryKey);
        scope.setExtra("fullQueryKey", query.queryKey);
        scope.setExtra("queryHash", query.queryHash);

        scope.setExtra("queryState", {
          status: query.state.status,
          dataUpdatedAt: query.state.dataUpdatedAt,
          errorUpdatedAt: query.state.errorUpdatedAt,
        });

        if (error instanceof Error) {
          captureException(error);
        } else {
          captureMessage(`Unknown query error in ${queryKey}`);
        }
      });
    },
  }),

  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60, // 1 hour
      retry: false,
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <BlockchainClientProvider>
      <BrowserRouter>
        <IconContext.Provider value={{ weight: "regular", size: "1.5rem" }}>
          <SessionProvider>
            <Routes />
            <NotificationsContainer />
          </SessionProvider>
        </IconContext.Provider>
      </BrowserRouter>
    </BlockchainClientProvider>
  </QueryClientProvider>
);

export default App;
