import type { EntitiesMap } from "../../types/models/entities";
import type { Shareblock } from "../../types/models/shares";
import { captureMessage } from "../../utils/monitoring";
import { Select } from "../design-system/Select";
import { MultiValue } from "./MultiValue";
import { Option } from "./Option";

const SelectShareBlocks = ({
  options,
  value,
  onChange,
  entitiesMap,
}: {
  options: Shareblock[];
  value?: Shareblock[];
  onChange: (newValue?: Shareblock[]) => void;
  entitiesMap: EntitiesMap;
}) => (
  <Select
    isMulti
    name="shareBlocks"
    components={{
      Option: (props) => <Option {...props} entitiesMap={entitiesMap} />,
      MultiValue: (props) => (
        <MultiValue {...props} entitiesMap={entitiesMap} />
      ),
    }}
    getOptionLabel={({ start, end, type, holder: { id } }) => {
      const optionHolderName = entitiesMap[id]?.name;
      if (optionHolderName === undefined) {
        captureMessage(
          `Missing holder name for shareblock: ${JSON.stringify(id)}`
        );

        return `${start} ${end} ${type}`;
      }

      return `${start} ${end} ${type} ${optionHolderName}`;
    }}
    getOptionValue={(option) => option.start.toString()}
    options={options}
    value={value}
    onChange={(newValue = []) => {
      onChange([...newValue]);
    }}
  />
);

export { SelectShareBlocks };
