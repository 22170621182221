import {
  Archive,
  ArrowRight,
  ArrowsHorizontal,
  CalendarBlank,
  CalendarSlash,
  CheckSquare,
  Gavel,
  HandCoins,
  HandDeposit,
  Hourglass,
} from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link, useParams } from "react-router-dom";

import { useShareholdersQuery } from "../../../../api/blockchain/company";
import { useRestrictionCaseQuery } from "../../../../api/blockchain/restrictionCase";
import { useEntitiesQuery } from "../../../../api/rest/entities";
import { Badge } from "../../../../components/design-system/Badge";
import { Breadcrumb } from "../../../../components/design-system/Breadcrumb";
import { Button } from "../../../../components/design-system/Button";
import { Description } from "../../../../components/design-system/Description";
import { EmptyState } from "../../../../components/design-system/EmptyState";
import { EntityItem } from "../../../../components/design-system/EntityItem";
import {
  PencilIcon,
  PersonIcon,
  ThreeDotsIcon,
} from "../../../../components/design-system/icons";
import { Loading } from "../../../../components/design-system/Loading";
import { Menu } from "../../../../components/design-system/Menu";
import { Tab } from "../../../../components/design-system/Tab";
import { TableV2 } from "../../../../components/design-system/TableV2";
import { NoData } from "../../../../components/NoData";
import { PageWrapper } from "../../../../components/PageWrapper";
import { useCurrentCompany } from "../../../../context/account";
import { useSession } from "../../../../context/session";
import { APP_ROUTE } from "../../../../routes/constants";
import { CompanyInformation } from "../../../../types/models/administration";
import { CompanyInvolvement } from "../../../../types/models/company";
import { Entity } from "../../../../types/models/entities";
import { getFormattedDate } from "../../../../utils/date";
import { formatCurrency, formatNumber } from "../../../../utils/format";
import { hasRequiredPermission } from "../../../../utils/permissions";
import { ResponseDeleteDialog } from "./ResponseDeleteDialog";
import { RestrictionCaseForm } from "./RestrictionCaseForm";
import { RestrictionCaseLitigationForm } from "./RestrictionCaseLitigationForm";
import { RestrictionCaseResponseDialog } from "./RestrictionCaseResponseDialog";
import { RestrictionCaseStatusDialog } from "./RestrictionCaseStatusDialog";

type ResponsesTableProps = {
  currentCompany: CompanyInformation | CompanyInvolvement;
  caseId: string;
  responses: {
    shareholder: { id: string };
    date?: string;
    requestedShares?: number;
  }[];
  entitiesMap: Record<string, Entity>;
  type: "pending" | "yes" | "no";
  update: (id: string) => void;
};

const ResponsesTable = ({
  currentCompany,
  caseId,
  responses,
  entitiesMap,
  type,
  update,
}: ResponsesTableProps) => {
  const i18n = useTranslation();
  const { user } = useSession();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [sortBy, setSortBy] = useState<string>("requestedShares-desc");
  const [expanded, setExpanded] = useState<Record<number, boolean>>({});
  const [shareholderToDelete, setShareholderToDelete] = useState("");

  const sortShareholders = (
    r: {
      shareholder: { id: string };
      date?: string;
      requestedShares?: number;
    }[]
  ) => {
    const [sort, sortOrder] = sortBy.split("-");
    const isAscending = sortOrder === "asc";
    const order = isAscending ? -1 : 1;

    if (sort === "shareholder") {
      return r.sort((a, b) =>
        isAscending
          ? (entitiesMap[a.shareholder.id]?.name || "").localeCompare(
              entitiesMap[b.shareholder.id]?.name || ""
            )
          : (entitiesMap[b.shareholder.id]?.name || "").localeCompare(
              entitiesMap[a.shareholder.id]?.name || ""
            )
      );
    }

    if (sort === "date") {
      return responses.sort((a, b) =>
        (a.date || "") > (b.date || "") ? -1 * order : order
      );
    }

    return responses.sort((a, b) =>
      (a.requestedShares || 0) > (b.requestedShares || 0) ? -1 * order : order
    );
  };

  const sortedShareholders = sortShareholders(responses);

  return (
    <>
      <TableV2
        columns={[
          {
            name: "shareholder",
            title: i18n.t("label.shareholder"),
            key: true,
            sortable: true,
          },
          ...(type !== "pending"
            ? [
                {
                  name: "date",
                  title: i18n.t("restrictionCase.response.date"),
                  key: !isTabletOrMobileDevice,
                  sortable: true,
                },
              ]
            : []),
          ...(type === "yes"
            ? [
                {
                  name: "requestedShares",
                  title: i18n.t("restrictionCase.response.shares"),
                  key: !isTabletOrMobileDevice,
                  sortable: true,
                },
              ]
            : []),
          {
            name: "actions",
            title: "",
            key: true,
            sortable: false,
          },
        ]}
        sortBy={sortBy}
        setSortBy={setSortBy}
        expandedRows={expanded}
        setExpandedRows={setExpanded}
        data={sortedShareholders.map((r) => ({
          key: r.shareholder.id,
          shareholder: entitiesMap[r.shareholder.id] ? (
            <EntityItem
              value={entitiesMap[r.shareholder.id]!}
              displayIcon={!isTabletOrMobileDevice}
            />
          ) : undefined,
          date: r.date ? (
            <div className="tw-text-sm">{getFormattedDate(r.date)}</div>
          ) : undefined,
          requestedShares: r.requestedShares ? (
            <div className="tw-text-sm">{formatNumber(r.requestedShares)}</div>
          ) : undefined,
          actions: (
            <div className="tw-flex tw-justify-end">
              {hasRequiredPermission("Editor", currentCompany, user) &&
                (r.date ? (
                  <Menu as="div">
                    <Menu.Button
                      variant="clean"
                      isDropdown={false}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ThreeDotsIcon />
                    </Menu.Button>
                    <Menu.Items
                      align={
                        isTabletOrMobileDevice ? "topRight" : "bottomRight"
                      }
                      className="tw-absolute tw-z-50"
                    >
                      <Menu.Item
                        closeOnClick={false}
                        onClick={() => update(r.shareholder.id)}
                      >
                        {i18n.t("restrictionCase.response.update")}
                      </Menu.Item>
                      <Menu.Item
                        className="tw-text-error"
                        closeOnClick={false}
                        onClick={() => setShareholderToDelete(r.shareholder.id)}
                      >
                        {i18n.t("restrictionCase.response.delete")}
                      </Menu.Item>
                    </Menu.Items>
                  </Menu>
                ) : (
                  <Button onClick={() => update(r.shareholder.id)} size="md">
                    {i18n.t("restrictionCase.response.add")}
                  </Button>
                ))}
            </div>
          ),
        }))}
      />
      {sortedShareholders.length === 0 && (
        <EmptyState
          icon={
            type === "pending" ? (
              <CheckSquare size={32} />
            ) : (
              <Hourglass size={32} />
            )
          }
          title={i18n.t(`restrictionCase.response.empty.${type}.title`)}
          description={i18n.t(
            `restrictionCase.response.empty.${type}.description`
          )}
        />
      )}
      {shareholderToDelete && (
        <ResponseDeleteDialog
          company={currentCompany}
          caseId={caseId}
          shareholderId={shareholderToDelete}
          onClose={() => setShareholderToDelete("")}
        />
      )}
    </>
  );
};

const RestrictionCaseDetailsPage = () => {
  const i18n = useTranslation();
  const { user } = useSession();
  const { caseId } = useParams<{ caseId?: string }>();
  const currentCompany = useCurrentCompany();
  const [showResponseForm, setShowResponseForm] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showToggleStatus, setShowToggleStatus] = useState(false);
  const [showAddLitigation, setShowAddLitigation] = useState(false);
  const restrictionCaseQuery = useRestrictionCaseQuery(
    currentCompany?.orgNumber
  );
  const restrictionCases = restrictionCaseQuery.data || [];
  const restrictionCase = restrictionCases.find((n) => n.id === caseId);

  const entitiesQuery = useEntitiesQuery(currentCompany?.orgNumber || "");
  const entities = entitiesQuery.data || [];
  const entitiesMap = Object.fromEntries(entities.map((e) => [e.id, e]));
  const shareholdersQuery = useShareholdersQuery(currentCompany?.orgNumber, "");
  const shareholders = shareholdersQuery.data?.shareholders || [];

  const notResponded = shareholders.filter(
    (s) =>
      !restrictionCase?.responses.some(
        (x) => x.shareholder.id === s.holder.id
      ) && s.holder.id !== restrictionCase?.shareholder.id
  );

  const tabs: {
    name: string;
    data: {
      shareholder: { id: string };
      date?: string;
      requestedShares?: number;
    }[];
    type: "pending" | "yes" | "no";
  }[] = [
    {
      name: i18n.t("restrictionCase.response.pending"),
      data: notResponded.map((s) => ({ shareholder: { id: s.holder.id } })),
      type: "pending",
    },
    {
      name: i18n.t("restrictionCase.response.responded.no"),
      data:
        restrictionCase?.responses.filter((r) => r.requestedShares === 0) || [],
      type: "no",
    },
    {
      name: i18n.t("restrictionCase.response.responded.yes"),
      data:
        restrictionCase?.responses.filter((r) => r.requestedShares > 0) || [],
      type: "yes",
    },
  ];

  if (!caseId) {
    return <NoData />;
  }

  if (
    !currentCompany ||
    !restrictionCase ||
    shareholdersQuery.isLoading ||
    entitiesQuery.isLoading
  ) {
    return <Loading />;
  }

  return (
    <PageWrapper>
      <div className="tw-pb-4">
        <Breadcrumb
          links={[
            {
              url: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/restriction-cases`,
              name: i18n.t("restrictionCase.title"),
            },
          ]}
          current={i18n.t(`restrictionCase.type.${restrictionCase.type}`)}
        />
      </div>
      <header className="tw-flex tw-flex-col tw-justify-between tw-gap-2 tw-pb-4 md:tw-flex-row">
        <div className="tw-flex tw-items-center tw-gap-4">
          <h1 className="tw-text-2xl tw-font-medium">
            {i18n.t(`restrictionCase.type.${restrictionCase.type}`)}
          </h1>
          {restrictionCase.status !== "ACTIVE" && (
            <Badge className="tw-text-base">
              {i18n.t("restrictionCase.status.archived")}
            </Badge>
          )}
        </div>
        {hasRequiredPermission("Editor", currentCompany, user) && (
          <div className="tw-flex tw-flex-col tw-gap-2 md:tw-flex-row">
            <Button
              onClick={() => setShowToggleStatus(true)}
              prefix={<Archive />}
            >
              {restrictionCase.status === "ACTIVE"
                ? i18n.t("restrictionCase.status.archived.update")
                : i18n.t("restrictionCase.status.active.update")}
            </Button>
            <Button
              onClick={() => setShowAddLitigation(true)}
              prefix={<Gavel />}
            >
              {i18n.t("restrictionCase.litigation.add")}
            </Button>
          </div>
        )}
      </header>
      <hr className="tw-absolute tw-left-0 tw-h-[1px] tw-w-screen tw-bg-neutral-200" />
      <div className="tw-flex tw-w-full max-md:tw-flex-col max-md:tw-flex-col-reverse">
        <div className="tw-w-full tw-pt-8">
          <Tab.Group>
            <Tab.List className="tw-pb-4">
              {tabs.map(({ name, data }) => (
                <Tab key={name}>
                  <div className="tw-flex tw-items-center tw-gap-2">
                    {name} <div>{data.length}</div>
                  </div>
                </Tab>
              ))}
            </Tab.List>
            {tabs.map(({ name, data, type }) => (
              <Tab.Panel key={name}>
                <ResponsesTable
                  currentCompany={currentCompany}
                  caseId={caseId}
                  responses={data}
                  entitiesMap={entitiesMap}
                  type={type}
                  update={setShowResponseForm}
                />
              </Tab.Panel>
            ))}
          </Tab.Group>
        </div>
        <hr className="tw-mx-8 tw-h-screen tw-w-[1px] tw-bg-gray-200 max-md:tw-hidden" />
        <div className="tw-flex tw-flex-col tw-gap-4 tw-pt-8 lg:tw-w-1/3">
          <div className="tw-flex tw-items-center tw-justify-between tw-gap-4">
            <h2 className="tw-text-xl tw-font-medium">
              {i18n.t("restrictionCase.details.title")}
            </h2>
            {hasRequiredPermission("Editor", currentCompany, user) && (
              <Button size="md" onClick={() => setShowForm(true)}>
                <PencilIcon />
              </Button>
            )}
          </div>
          {restrictionCase.litigation.length > 0 && (
            <div className="tw-flex tw-flex-col tw-gap-2 tw-rounded tw-border tw-p-4">
              <div>
                <h4 className="tw-text-semibold tw-text-sm tw-font-medium">
                  {i18n.t("restrictionCase.litigation.title")}
                </h4>
                <p className="tw-text-sm tw-text-secondary">
                  {i18n.t("restrictionCase.litigation.banner", {
                    total: restrictionCase.litigation.length,
                  })}
                </p>
              </div>
              <Link to="litigation">
                <Button className="tw-w-full" suffix={<ArrowRight />} size="md">
                  {i18n.t("restrictionCase.litigiation.link")}
                </Button>
              </Link>
            </div>
          )}
          <div className="tw-flex tw-items-center tw-gap-4 tw-whitespace-nowrap">
            <PersonIcon />
            <Description
              title={i18n.t(
                restrictionCase.type === "preemption"
                  ? "restrictionCase.shareholder.current"
                  : "restrictionCase.shareholder.previous"
              )}
              description={entitiesMap[restrictionCase.shareholder.id]?.name}
              theme="grayBlack"
            />
          </div>
          {restrictionCase.buyer && (
            <div className="tw-flex tw-items-center tw-gap-4 tw-whitespace-nowrap">
              <PersonIcon />
              <Description
                title={i18n.t("restrictionCase.buyer")}
                description={entitiesMap[restrictionCase.buyer]?.name}
                theme="grayBlack"
              />
            </div>
          )}
          <div className="tw-flex tw-items-center tw-gap-4">
            <CalendarBlank />
            <Description
              title={i18n.t("restrictionCase.date")}
              description={getFormattedDate(restrictionCase.date)}
              theme="grayBlack"
            />
          </div>
          <div className="tw-flex tw-items-center tw-gap-4">
            <CalendarSlash />
            <Description
              title={i18n.t("restrictionCase.expiryDate")}
              description={getFormattedDate(restrictionCase.expiryDate)}
              theme="grayBlack"
            />
          </div>
          <div className="tw-flex tw-items-center tw-gap-4">
            <HandDeposit />
            <Description
              title={i18n.t("restrictionCase.totalShares")}
              description={`${formatNumber(restrictionCase.totalShares)} (${
                restrictionCase.shareType
              })`}
              theme="grayBlack"
            />
          </div>
          {restrictionCase.shareRanges &&
            restrictionCase.shareRanges.length > 0 && (
              <div className="tw-flex tw-items-center tw-gap-4">
                <ArrowsHorizontal />
                <Description
                  title={i18n.t("label.shareRanges")}
                  description={restrictionCase.shareRanges
                    .map((r) => `${r.start}-${r.end}`)
                    .join(", ")}
                  theme="grayBlack"
                />
              </div>
            )}
          <div className="tw-flex tw-items-center tw-gap-4">
            <HandCoins />
            <Description
              title={i18n.t("restrictionCase.pricePerShare")}
              description={formatCurrency(
                restrictionCase.pricePerShare,
                restrictionCase.currency
              )}
              theme="grayBlack"
            />
          </div>
        </div>
      </div>
      {showForm && (
        <RestrictionCaseForm
          company={currentCompany}
          restrictionCase={restrictionCase}
          onClose={() => setShowForm(false)}
        />
      )}
      {showResponseForm && restrictionCase && (
        <RestrictionCaseResponseDialog
          company={currentCompany}
          caseId={caseId}
          shareholder={showResponseForm}
          response={restrictionCase.responses.find(
            (r) => r.shareholder.id === showResponseForm
          )}
          minDate={restrictionCase.date}
          maxDate={restrictionCase.expiryDate}
          maxShares={restrictionCase.totalShares}
          type={restrictionCase.type}
          onClose={() => setShowResponseForm("")}
        />
      )}
      {showToggleStatus && (
        <RestrictionCaseStatusDialog
          orgNumber={currentCompany.orgNumber}
          caseId={caseId}
          onClose={() => setShowToggleStatus(false)}
          status={restrictionCase.status}
        />
      )}
      {showAddLitigation && (
        <RestrictionCaseLitigationForm
          company={currentCompany}
          caseId={caseId}
          minDate={restrictionCase.date}
          onClose={() => setShowAddLitigation(false)}
        />
      )}
    </PageWrapper>
  );
};

export default RestrictionCaseDetailsPage;
