import type React from "react";

import type { LedgerVersionDetails } from "../../../pages/CompanyShares/useLedgerVersions";
import { CompanyInformation } from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import { Entity } from "../../../types/models/entities";
import { OptionsProgram } from "../../../types/models/options";
import { Shareholder } from "../../../types/models/shares";
import { ShareholderDataTotals } from "../../../utils/excel-utils";
import { ExportMenuShareholders } from "../../ExportMenu";
import { SelectVersion } from "../../SelectVersion";
import { ShareView } from "../../ShareView";

type BottomBarProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  selectedVersion?: LedgerVersionDetails;
  setSelectedVersion: (version?: LedgerVersionDetails) => void;
  ledgerVersions: LedgerVersionDetails[];
  enableViewShare: boolean;
  optionPrograms: OptionsProgram[];
  selectedPrograms: string[];
  shareholders: (Shareholder & { entity: Entity })[];
  entities: Record<string, Entity>;
  shareTypesByName: Record<string, number>;
  totals: ShareholderDataTotals;
};

const ShareholdersBottomBar: React.FC<BottomBarProps> = ({
  currentCompany,
  selectedVersion,
  setSelectedVersion,
  ledgerVersions,
  enableViewShare,
  optionPrograms,
  selectedPrograms,
  shareholders,
  entities,
  shareTypesByName,
  totals,
}) => {
  return (
    <div
      id="bottom-bar"
      className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-gap-2 tw-border-t-[1px] tw-bg-white tw-px-4 tw-py-3 md:tw-hidden"
    >
      <SelectVersion
        selectedVersion={selectedVersion}
        availableVersions={ledgerVersions}
        onChange={setSelectedVersion}
        menuPlacement="top"
        className="tw-w-full"
      />
      {enableViewShare && (
        <ShareView
          orgNumber={currentCompany.orgNumber}
          selectedVersion={selectedVersion}
        />
      )}
      <ExportMenuShareholders
        currentCompany={currentCompany}
        ledgerVersions={ledgerVersions}
        selectedVersion={selectedVersion}
        optionPrograms={optionPrograms}
        selectedPrograms={selectedPrograms}
        shareholders={shareholders}
        shareTypesByName={shareTypesByName}
        entities={entities}
        totals={totals}
      />
    </div>
  );
};

export { ShareholdersBottomBar };
