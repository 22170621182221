import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  useLedgerQuery,
  useShareholderBlocksQuery,
  useShareTypesQuery,
} from "../../../api/blockchain/company";
import { useEntitiesQuery } from "../../../api/rest/entities";
import { useSplitShares } from "../../../api/rest/events";
import { EventFormProps } from "../../../components/AddEvents/EventsWizard.utils";
import { Alert } from "../../../components/design-system/Alert";
import { DistributionProgress } from "../../../components/design-system/DistributionProgress";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { ArrowDownIcon } from "../../../components/design-system/icons";
import { Input } from "../../../components/design-system/Input";
import { RadioGroup } from "../../../components/design-system/RadioGroup";
import { DistributionSelect } from "../../../components/DistributionSelect";
import type { TEventSummaryMetric } from "../../../components/EventSummary";
import {
  EventFormWrapper,
  EventSummarySectionList,
} from "../../../components/EventSummary";
import { ShareBlocksList } from "../../../components/ShareBlocks/List";
import useLatestVersion from "../../../hooks/useLatestVersion";
import type { TDraftShareBlock } from "../../../types/models/draft";
import { dateToIsoString } from "../../../utils/date";
import {
  generateNewBlocksProrata,
  getSplitMetrics,
  sumRanges,
} from "../../../utils/shares";
import {
  DistributionMethod,
  getBlocksWithDistribution,
} from "../Distribution.utils";
import { calculateRatio } from "./SplitShares.utils";

type FormProps = {
  ratioX: number;
  ratioY: number;
  result: number;
  date: string;
  shareBlocks: TDraftShareBlock[];
};

const formId = "split-shares-form";

const getShareBlocksValidation = (
  shareBlocks: TDraftShareBlock[],
  sharesToAdd: number
) => {
  const numberOfSharesDiff = sumRanges(shareBlocks) - sharesToAdd;

  return { numberOfSharesDiff };
};

const SplitShares = ({
  currentCompany,
  onSuccess,
  setFormData,
}: EventFormProps) => {
  const [ratioMethod, setRatioMethod] = useState<"manual" | "auto">("manual");
  const [distributionMethod, setDistributionMethod] =
    useState<DistributionMethod>("manual");
  const i18n = useTranslation();

  const form = useForm<FormProps>({
    mode: "onChange",
    defaultValues: { shareBlocks: [] },
  });
  const lastEventDate = useLatestVersion();
  const ledgerQuery = useLedgerQuery(currentCompany.orgNumber, "");
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const shareTypesQuery = useShareTypesQuery(currentCompany.orgNumber, "");
  const shareTypes = (shareTypesQuery.data || []).map((shareType) => ({
    ...shareType,
    numberOfShares: 0,
  }));

  const shareHoldersQuery = useShareholderBlocksQuery(currentCompany.orgNumber);
  const allEntities = entitiesQuery.data || [];
  const shareHolders = shareHoldersQuery.data || [];
  const existingHolders = allEntities.filter((entity) =>
    shareHolders.some((element) => element.id === entity.id)
  );

  function gcd(input_a: number, input_b: number): number | undefined {
    if (!input_a || !input_b) {
      return undefined;
    }

    let a = input_a;
    let b = input_b;

    while (b !== 0) {
      const temp = b;
      b = a % b;
      a = temp;
    }

    return a;
  }

  const mutation = useSplitShares(currentCompany.orgNumber, {
    onSuccess: (eventId) => onSuccess(eventId),
  });

  useEffect(() => {
    setFormData((data) => ({ ...data, formId }));
  }, [setFormData]);

  useEffect(() => {
    setFormData((data) => ({
      ...data,
      loading: mutation.isLoading,
    }));
  }, [mutation.isLoading, setFormData]);

  const handleSubmit = (data: FormProps) => {
    if (!isModifierValid) {
      if (ratioMethod === "auto") {
        form.setError("result", {
          type: "manual",
          message: i18n.t("error.validation.split.increase"),
        });
      } else {
        form.setError("ratioX", {
          type: "manual",
          message: i18n.t("error.validation.split.increase"),
        });
        form.setError("ratioY", {
          type: "manual",
          message: i18n.t("error.validation.split.increase"),
        });
      }
      return;
    }
    if (shareBlocksValidation.numberOfSharesDiff !== 0) {
      form.setError("shareBlocks", {
        type: "manual",
        message: i18n.t("error.validation.split.ratio"),
      });
      return;
    }

    let ratio = { x: data.ratioX, y: data.ratioY };
    if (ratioMethod === "auto") {
      if (!ledgerQuery.data?.shares.total) {
        form.setError("result", {
          type: "manual",
          message: i18n.t("error.general"),
        });
        return;
      }
      ratio = calculateRatio(ledgerQuery.data?.shares.total, data.result);
    }

    mutation.mutate({
      date: data.date,
      ratio,
      shareRanges: data.shareBlocks,
    });
  };
  const { ratioX, ratioY, result, shareBlocks } = form.watch();
  const autoRatio = result
    ? ledgerQuery.data && calculateRatio(ledgerQuery.data?.shares.total, result)
    : undefined;
  const x = (ratioMethod === "manual" ? ratioX : autoRatio?.x) || 1;
  const y = (ratioMethod === "manual" ? ratioY : autoRatio?.y) || 1;
  const modifier = y / x;

  const metrics = getSplitMetrics(modifier, ledgerQuery.data);
  const metricsWithLabel: Record<string, TEventSummaryMetric> = {
    shareCapital: {
      label: `${i18n.t("label.shareCapital")} (${
        currentCompany.settings?.currency
      })`,
      format: "number",
      ...metrics.shareCapital,
    },
    quotaValue: {
      label: `${i18n.t("label.quotaValue")} (${
        currentCompany.settings?.currency
      })`,
      format: "number",
      ...metrics.quotaValue,
    },
    numberOfShares: {
      label: i18n.t("label.shares"),
      format: "number",
      ...metrics.numberOfShares,
    },
  };

  const isModifierValid = modifier > 1;
  const greatestCommonDenominator = isModifierValid ? gcd(x, y) : undefined;

  useEffect(() => {
    const newBlocks = generateNewBlocksProrata(
      modifier,
      shareHoldersQuery.data || [],
      ledgerQuery.data?.shares.lastNumber || 0
    );
    const newBlocksDistributed = getBlocksWithDistribution(
      newBlocks,
      metrics.numberOfShares.after - metrics.numberOfShares.before,
      distributionMethod
    );

    form.setValue("shareBlocks", newBlocksDistributed);
  }, [modifier, distributionMethod]);

  const shareBlocksValidation = getShareBlocksValidation(
    shareBlocks,
    metrics.numberOfShares.after - metrics.numberOfShares.before
  );

  return (
    <div>
      <header className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h4>{i18n.t("events.split.title")}</h4>
        </div>
      </header>
      <EventFormWrapper
        summary={
          <EventSummarySectionList metrics={Object.values(metricsWithLabel)} />
        }
      >
        <form
          className="tw-space-y-6"
          onSubmit={form.handleSubmit(handleSubmit)}
          id={formId}
        >
          <FormGroup>
            <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
            <Controller
              control={form.control}
              render={({
                field: { ref, name, onChange, value },
                fieldState,
              }) => (
                <>
                  <Input
                    id="date"
                    value={value}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    type="date"
                    className="tw-w-full"
                    max={dateToIsoString(new Date())}
                    min={lastEventDate && dateToIsoString(lastEventDate.date)}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              name="date"
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          <RadioGroup value={ratioMethod} onChange={setRatioMethod}>
            <RadioGroup.Label>
              <FormLabel className="tw-mb-4">
                {i18n.t("events.split.method")}
              </FormLabel>
            </RadioGroup.Label>
            <div className="tw-flex tw-flex-col tw-gap-2 md:tw-flex-row">
              <RadioGroup.Option value="manual">
                {({ checked }) => (
                  <RadioGroup.OptionContent checked={checked}>
                    <p className="tw-text-sm">
                      {i18n.t("events.split.method.manual")}
                    </p>
                  </RadioGroup.OptionContent>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="auto">
                {({ checked }) => (
                  <RadioGroup.OptionContent checked={checked}>
                    <p className="tw-text-sm">
                      {i18n.t("events.split.method.auto")}
                    </p>
                  </RadioGroup.OptionContent>
                )}
              </RadioGroup.Option>
            </div>
          </RadioGroup>
          <div className="tw-space-y-2">
            {ratioMethod === "manual" && (
              <>
                <FormGroup>
                  <FormLabel htmlFor="ratioX">
                    {i18n.t("events.split.ratioX")}
                  </FormLabel>
                  <Input
                    id="ratioX"
                    {...form.register("ratioX", {
                      required: i18n.t("error.validation.required"),
                      valueAsNumber: true,
                      min: {
                        value: 1,
                        message: i18n.t(
                          "error.validation.range.min.exclusive",
                          {
                            min: 0,
                          }
                        ),
                      },
                      max: {
                        value: metrics.numberOfShares.before,
                        message: i18n.t("error.validation.range.max", {
                          max: metrics.numberOfShares.before,
                        }),
                      },
                      validate: (val) => {
                        if (Number.isNaN(val)) {
                          return i18n.t("error.validation.required");
                        }
                        return true;
                      },
                    })}
                    type="number"
                  />
                  <FormError>{form.formState.errors.ratioX?.message}</FormError>
                </FormGroup>
                <ArrowDownIcon className="tw-h-7 tw-w-7 tw-text-secondary" />
                <FormGroup>
                  <FormLabel htmlFor="ratioY">
                    {i18n.t("events.split.ratioY")}
                  </FormLabel>
                  <Input
                    id="ratioY"
                    {...form.register("ratioY", {
                      required: i18n.t("error.validation.required"),
                      valueAsNumber: true,
                      min: {
                        value: ratioX + 1,
                        message: i18n.t("error.validation.range.min", {
                          min: ratioX + 1,
                        }),
                      },
                      validate: (val) => {
                        if (Number.isNaN(val)) {
                          return i18n.t("error.validation.required");
                        }
                        return true;
                      },
                    })}
                    type="number"
                  />
                  <FormError>{form.formState.errors.ratioY?.message}</FormError>
                </FormGroup>
              </>
            )}
            {ratioMethod === "auto" && (
              <FormGroup>
                <FormLabel htmlFor="result">
                  {i18n.t("events.split.result")}
                </FormLabel>
                <Input
                  id="result"
                  {...form.register("result", {
                    required: i18n.t("error.validation.required"),
                    valueAsNumber: true,
                    min: {
                      value: metrics.numberOfShares.before + 1,
                      message: i18n.t("error.validation.range.min", {
                        min: metrics.numberOfShares.before + 1,
                      }),
                    },
                    validate: (val) => {
                      if (Number.isNaN(val)) {
                        return i18n.t("error.validation.required");
                      }
                      return true;
                    },
                  })}
                  type="number"
                />
                <FormError>{form.formState.errors.result?.message}</FormError>
              </FormGroup>
            )}
            {isModifierValid && (
              <Alert type="neutral">
                {i18n.t("events.split.form.description", {
                  x: x / greatestCommonDenominator!,
                  y: y / greatestCommonDenominator!,
                })}
              </Alert>
            )}
          </div>
          {isModifierValid && (
            <>
              <Controller
                control={form.control}
                name="shareBlocks"
                render={({ field: { onChange, value } }) => (
                  <>
                    <ShareBlocksList
                      value={value}
                      onChange={onChange}
                      entitiesQuery={
                        {
                          ...entitiesQuery,
                          data: existingHolders,
                        } as ReturnType<typeof useEntitiesQuery>
                      }
                      currentCompany={currentCompany}
                      shareTypes={shareTypes}
                      offset={0}
                      disableNewEntity
                    />
                    <FormError>
                      {form.formState.errors.shareBlocks?.message}
                    </FormError>
                  </>
                )}
                rules={{ required: i18n.t("error.validation.required") }}
              />
              {(distributionMethod !== "manual" ||
                shareBlocksValidation.numberOfSharesDiff < 0) && (
                <DistributionSelect
                  distributionMethod={distributionMethod}
                  setDistributionMethod={setDistributionMethod}
                />
              )}
            </>
          )}
          {isModifierValid && (
            <DistributionProgress
              diff={shareBlocksValidation.numberOfSharesDiff}
            />
          )}
          {mutation.error && <FormErrorList error={mutation.error} />}
        </form>
      </EventFormWrapper>
    </div>
  );
};

export default SplitShares;
