import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  useLedgerQuery,
  useShareblocksQuery,
} from "../../../api/blockchain/company";
import { useEntitiesQuery } from "../../../api/rest/entities";
import { usePledgedSharesMutation } from "../../../api/rest/events";
import { EventFormProps } from "../../../components/AddEvents/EventsWizard.utils";
import { Button } from "../../../components/design-system/Button";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { TrashIcon } from "../../../components/design-system/icons";
import { Input } from "../../../components/design-system/Input";
import { List, ListItem } from "../../../components/design-system/List";
import { ListHeader } from "../../../components/design-system/ListHeader";
import { Loading } from "../../../components/design-system/Loading";
import { SelectEntity } from "../../../components/design-system/SelectEntity";
import { AddEntity } from "../../../components/Entities/AddEntity";
import { NoData } from "../../../components/NoData";
import { SelectShareBlocks } from "../../../components/SelectShareBlocks";
import useLatestVersion from "../../../hooks/useLatestVersion";
import { Shareblock } from "../../../types/models/shares";
import { dateToIsoString } from "../../../utils/date";
import {
  hasOverlappingRanges,
  isRangeValid,
  sumRanges,
} from "../../../utils/shares";

type ShareRange = {
  start?: number;
  end?: number;
  creditorId?: string;
};

type FormProps = {
  date: string;
  shareRanges: ShareRange[];
};

const isValidRow = (value: ShareRange) =>
  isRangeValid(value) && value.creditorId;
const validRows = (data: ShareRange[]) => data.filter(isValidRow);

const pledgedSharesUpdateFormId = "pledged-shares-update-form";

const PledgedSharesUpdate = ({
  currentCompany,
  onSuccess,
  setFormData,
}: EventFormProps) => {
  const i18n = useTranslation();
  const { watch, control, setError, handleSubmit, setValue } =
    useForm<FormProps>({
      mode: "onSubmit",
      defaultValues: { shareRanges: [] },
    });
  const { shareRanges } = watch();

  const shareBlocksQuery = useShareblocksQuery(currentCompany.orgNumber, "");
  const shareBlocks = (shareBlocksQuery.data || []).filter((b) => !b.cancelled);
  const ledgerQuery = useLedgerQuery(currentCompany.orgNumber, "");
  const lastEventDate = useLatestVersion();
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const entitiesData = entitiesQuery.data || [];
  const entitiesMap = Object.fromEntries(entitiesData.map((e) => [e.id, e]));

  useEffect(() => {
    setValue(
      "shareRanges",
      (shareBlocksQuery.data || [])
        .filter((b) => !b.cancelled && !!b.creditor)
        .map((block) => ({
          start: block.start,
          end: block.end,
          creditorId: block.creditor!.id,
        }))
    );
  }, [shareBlocksQuery.data]);

  const mutation = usePledgedSharesMutation(currentCompany.orgNumber, {
    onSuccess: (eventId) => onSuccess(eventId),
  });

  const onSubmit = (data: FormProps) => {
    if (
      !shareBlocks.some((b) => !!b.creditor) &&
      data.shareRanges.length === 0
    ) {
      setError("shareRanges", {
        type: "manual",
        message: i18n.t("error.verification.certificate.none"),
      });
      return;
    }

    const blocksWithCreditor = shareBlocks.filter((b) => !!b.creditor);
    const areSelectedSharesPledged =
      data.shareRanges.length === blocksWithCreditor.length &&
      data.shareRanges.every((selectedShare) =>
        blocksWithCreditor.some(
          (b) =>
            b.start >= selectedShare.start! &&
            b.end <= selectedShare.end! &&
            b.creditor?.id === selectedShare.creditorId
        )
      );

    if (areSelectedSharesPledged) {
      setError("shareRanges", {
        type: "manual",
        message: i18n.t("error.verification.pledge.alreadyIssued"),
      });
      return;
    }
    mutation.mutate({
      date: data.date,
      shareRanges: validRows(data.shareRanges),
    });
  };

  useEffect(() => {
    setFormData((data) => ({ ...data, formId: pledgedSharesUpdateFormId }));
  }, [setFormData]);

  useEffect(() => {
    setFormData((data) => ({
      ...data,
      loading: mutation.isLoading,
    }));
  }, [mutation.isLoading, setFormData]);

  useEffect(() => {
    const selectedPledgeBlocks = shareBlocks.filter((block) =>
      shareRanges.some((range) => isRangeInBlock(range, block))
    );
    setFormData((data) => ({
      ...data,
      selectedShareTypes: selectedPledgeBlocks.map((block) => block.type),
    }));
  }, [shareRanges, setFormData]);

  const isRangeInBlock = (range: ShareRange, block: Shareblock) =>
    !!(
      range.start &&
      range.end &&
      range.end <= block.end &&
      range.start >= block.start
    );

  if (shareBlocksQuery.isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <header className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h4>{i18n.t("events.pledgedSharesUpdate.title")}</h4>
        </div>
      </header>
      <form
        id={pledgedSharesUpdateFormId}
        className="tw-space-y-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormGroup>
          <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
          <Controller
            control={control}
            render={({ field: { ref, name, onChange, value }, fieldState }) => (
              <>
                <Input
                  id="date"
                  value={value}
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  type="date"
                  className="tw-w-full"
                  max={dateToIsoString(new Date())}
                  min={lastEventDate && dateToIsoString(lastEventDate.date)}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            name="date"
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>

        <Controller
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormGroup>
              <List
                header={
                  <ListHeader
                    title={i18n.t(
                      "events.pledgedSharesUpdate.form.ranges.label"
                    )}
                    description={i18n.t(
                      "events.pledgedSharesUpdate.form.ranges.description"
                    )}
                  />
                }
                footer={
                  <div className="tw-p-4">
                    <Button onClick={() => onChange([...value, {}])}>
                      {i18n.t("label.addRange")}
                    </Button>
                  </div>
                }
              >
                <FormGroup className="tw-space-y-2 tw-px-4 tw-py-2">
                  <FormLabel htmlFor="pledgeSelect">
                    {i18n.t("label.pledgeSelect")}
                  </FormLabel>
                  <SelectShareBlocks
                    value={shareBlocks.filter((block) =>
                      value.some((range) => isRangeInBlock(range, block))
                    )}
                    options={shareBlocks}
                    onChange={(blocks) => {
                      const existingBlocks = value.filter((r) =>
                        (blocks || []).some((b) => isRangeInBlock(r, b))
                      );
                      const newBlocks = (blocks || []).filter(
                        (b) => !value.some((r) => isRangeInBlock(r, b))
                      );
                      onChange([
                        ...existingBlocks,
                        ...newBlocks.map((b) => ({
                          start: b.start,
                          end: b.end,
                        })),
                      ]);
                    }}
                    entitiesMap={entitiesMap}
                  />
                </FormGroup>

                {value.map((range, index) => (
                  <ListItem key={range.start}>
                    <div className="tw-flex tw-items-center tw-gap-4 tw-pb-1">
                      <div className="tw-grid tw-flex-1 tw-auto-cols-fr tw-gap-4 md:tw-grid-cols-2">
                        <Controller
                          name={`shareRanges.${index}.start`}
                          control={control}
                          rules={{
                            required: i18n.t("error.validation.required"),
                            min: {
                              value: 1,
                              message: i18n.t("error.validation.range.min", {
                                min: 1,
                              }),
                            },
                            max: {
                              value: range.end || Number.MAX_SAFE_INTEGER,
                              message: i18n.t(
                                "error.validation.shareRange.from"
                              ),
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <FormGroup>
                              <FormLabel htmlFor={`shareRanges.${index}.start`}>
                                {i18n.t("label.from")}
                              </FormLabel>
                              <Input
                                id={`shareRanges.${index}.start`}
                                value={field.value}
                                onChange={(e) => {
                                  if (Number.isNaN(e.target.valueAsNumber)) {
                                    field.onChange("");
                                  } else {
                                    field.onChange(e.target.valueAsNumber);
                                  }
                                }}
                                type="number"
                                step={1}
                                min={1}
                              />
                              <FormError>
                                {fieldState.error && fieldState.error?.message}
                              </FormError>
                            </FormGroup>
                          )}
                        />
                        <Controller
                          name={`shareRanges.${index}.end`}
                          control={control}
                          rules={{
                            required: i18n.t("error.validation.required"),
                            min: {
                              value: range.start || 1,
                              message: range.start
                                ? i18n.t("error.validation.shareRange.to")
                                : i18n.t("error.validation.range.min", {
                                    min: 1,
                                  }),
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <FormGroup>
                              <FormLabel htmlFor={`shareRanges.${index}.end`}>
                                {i18n.t("label.to")}
                              </FormLabel>
                              <Input
                                id={`shareRanges.${index}.end`}
                                value={field.value}
                                onChange={(e) => {
                                  if (Number.isNaN(e.target.valueAsNumber)) {
                                    field.onChange("");
                                  } else {
                                    field.onChange(e.target.valueAsNumber);
                                  }
                                }}
                                type="number"
                                step={1}
                                min={1}
                              />
                              <FormError>
                                {fieldState.error && fieldState.error?.message}
                              </FormError>
                            </FormGroup>
                          )}
                        />
                        <div className="tw-col-span-2">
                          <FormGroup>
                            <div className="tw-flex tw-items-end tw-justify-between">
                              <FormLabel htmlFor="creditor">
                                {i18n.t("label.creditor")}
                              </FormLabel>
                              <AddEntity
                                currentCompany={currentCompany}
                                onSuccess={() => {
                                  entitiesQuery.refetch();
                                }}
                              />
                            </div>
                            <Controller
                              control={control}
                              name={`shareRanges.${index}.creditorId`}
                              render={({ field, fieldState }) => (
                                <>
                                  <SelectEntity
                                    id="creditor"
                                    options={entitiesData}
                                    value={field.value}
                                    onChange={(id) => field.onChange(id)}
                                  />
                                  <FormError>
                                    {fieldState.error?.message}
                                  </FormError>
                                </>
                              )}
                              rules={{
                                required: i18n.t("error.validation.required"),
                              }}
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="tw-flex tw-items-center md:tw-items-end">
                        <Button
                          variant="clean"
                          onClick={() =>
                            onChange(value.filter((_, i) => i !== index))
                          }
                        >
                          <TrashIcon className="tw-h-6 tw-w-6" />
                        </Button>
                      </div>
                    </div>
                  </ListItem>
                ))}
                {value.length === 0 && <NoData />}
              </List>
              <FormError>{error?.message}</FormError>
            </FormGroup>
          )}
          name="shareRanges"
          rules={{
            validate: (value) => {
              const totalShares = ledgerQuery.data?.shares.total || 1;
              const ranges = validRows(value);
              const rangeTotal = sumRanges(ranges);
              if (rangeTotal > totalShares) {
                return i18n.t("error.validation.range.max", {
                  max: totalShares,
                });
              }
              if (hasOverlappingRanges(ranges)) {
                return i18n.t("error.validation.overlaps");
              }

              return true;
            },
          }}
        />
        {mutation.error && <FormErrorList error={mutation.error} />}
      </form>
    </div>
  );
};

export default PledgedSharesUpdate;
