import { useTranslation } from "react-i18next";

import { StatusBadge } from "../../../../../../components/StatusBadge";
import type { ProgressItem } from "../../../../../../types/models/approval";
import type { Entity } from "../../../../../../types/models/entities";
import PolicyDetails from "../PolicyDetails";
import type { NewPolicy } from ".";

type PreviewNewPolicyProposalChangesProps = {
  policy: NewPolicy;
  acceptLedgerAsOfficial?: boolean;
  entities: Entity[];
  showProgress?: boolean;
  progress?: [ProgressItem, ...ProgressItem[]];
};

const PreviewNewPolicyProposalChanges = ({
  policy,
  acceptLedgerAsOfficial,
  entities,
  showProgress,
  progress,
}: PreviewNewPolicyProposalChangesProps) => {
  const i18n = useTranslation();

  const haveBoardMembersWithoutEntities = progress?.some(
    (p) => p.key === p.name
  );

  return (
    <div className="tw-pb-12">
      <h3>{i18n.t("settings.shareLedger.previewNewPolicyStep.title")}</h3>
      <p className="tw-mt-2 tw-text-sm">
        {i18n.t("settings.shareLedger.previewNewPolicyStep.description")}
      </p>
      <div className="tw-mt-12 tw-rounded tw-border tw-px-6 tw-py-8">
        {acceptLedgerAsOfficial && (
          <div className="tw-mb-10">
            <h4>
              {i18n.t(
                "settings.shareLedger.previewNewPolicyStep.approveAsOfficialLedger.title"
              )}
            </h4>
            <div className="tw-mt-4 tw-bg-kvanta-secondary tw-p-4 tw-text-sm">
              <p className="tw-font-medium tw-text-kvanta-primary">
                {i18n.t(
                  "settings.shareLedger.previewNewPolicyStep.approveAsOfficialLedger.description1"
                )}
              </p>
              <p className="tw-text-kvanta-primary">
                {i18n.t(
                  "settings.shareLedger.previewNewPolicyStep.approveAsOfficialLedger.description2"
                )}
              </p>
            </div>
          </div>
        )}
        <div>
          <h4>{i18n.t("label.approvalPolicy")}</h4>
          <div className="tw-mt-4 tw-bg-kvanta-secondary tw-p-4 tw-text-sm">
            <PolicyDetails
              rule={policy.rule}
              approverIds={policy.approvers}
              percentage={policy.percentage}
              entities={entities}
            />
          </div>
        </div>
      </div>
      {showProgress && progress && (
        <div className="tw-mb-4 tw-mt-12 tw-flex tw-flex-col tw-gap-4">
          <h4 className="tw-text-sm tw-font-medium tw-text-secondary">
            {i18n.t("settings.shareLedger.previewNewPolicyStep.status.title")}
          </h4>
          {progress.map(({ key, name, isCurrentUser, status }) => (
            <div key={key} className="tw-flex tw-justify-between tw-text-sm">
              <div>
                {name}
                {isCurrentUser ? ` (${i18n.t("label.you")})` : null}
              </div>
              <div>
                <StatusBadge status={status} />
              </div>
            </div>
          ))}
          {haveBoardMembersWithoutEntities && (
            <div className="tw-bg-amber-100 tw-px-4 tw-py-4 tw-text-black">
              {i18n.t("approvalPolicy.haveBoardMembersWithoutEntities")}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PreviewNewPolicyProposalChanges;
