import type React from "react";

import type { LedgerVersionDetails } from "../../../pages/CompanyShares/useLedgerVersions";
import { CompanyInformation } from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import { EntitiesMap } from "../../../types/models/entities";
import { RestrictionCase } from "../../../types/models/restriction-case";
import {
  Shareblock,
  ShareBlockHistory,
  ShareTypesMap,
} from "../../../types/models/shares";
import { ExportMenuShareledger } from "../../ExportMenu";
import { SelectVersion } from "../../SelectVersion";
import { ShareView } from "../../ShareView";

type BottomBarProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  selectedVersion?: LedgerVersionDetails;
  setSelectedVersion: (version?: LedgerVersionDetails) => void;
  ledgerVersions: LedgerVersionDetails[];
  enableViewShare: boolean;
  shareblocks: Shareblock[];
  entitiesMap: EntitiesMap;
  shareTypesMap: ShareTypesMap;
  shareBlockHistory: ShareBlockHistory[];
  notes: Record<string, RestrictionCase[]>;
};

const BottomBar: React.FC<BottomBarProps> = ({
  currentCompany,
  selectedVersion,
  setSelectedVersion,
  ledgerVersions,
  enableViewShare,
  shareblocks,
  entitiesMap,
  shareTypesMap,
  shareBlockHistory,
  notes,
}) => {
  return (
    <div
      id="bottom-bar"
      className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-gap-2 tw-border-t-[1px] tw-bg-white tw-px-4 tw-py-3 md:tw-hidden"
    >
      <SelectVersion
        selectedVersion={selectedVersion}
        availableVersions={ledgerVersions}
        onChange={setSelectedVersion}
        menuPlacement="top"
        className="tw-w-full"
      />
      {enableViewShare && (
        <ShareView
          orgNumber={currentCompany.orgNumber}
          selectedVersion={selectedVersion}
        />
      )}
      <ExportMenuShareledger
        currentCompany={currentCompany}
        ledgerVersions={ledgerVersions}
        selectedVersion={selectedVersion}
        shareblocks={shareblocks}
        entitiesMap={entitiesMap}
        shareTypesMap={shareTypesMap}
        shareBlockHistory={shareBlockHistory}
        notes={notes}
      />
    </div>
  );
};

export { BottomBar };
