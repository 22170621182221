import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  useCreateShareholderResponse,
  useUpdateShareholderResponse,
} from "../../../../api/rest/restriction-case";
import { Button } from "../../../../components/design-system/Button";
import { Description } from "../../../../components/design-system/Description";
import { Dialog } from "../../../../components/design-system/Dialog";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../../components/design-system/FormGroup";
import { Input } from "../../../../components/design-system/Input";
import { RadioGroup } from "../../../../components/design-system/RadioGroup";
import { CompanyInformation } from "../../../../types/models/administration";
import { CompanyInvolvement } from "../../../../types/models/company";
import {
  CreateRestrictionCaseResponse,
  RestrictionCase,
  RestrictionCaseResponse,
} from "../../../../types/models/restriction-case";

type RestrictionCaseType = RestrictionCase["type"];

type ResponseDialogProps = {
  company: CompanyInformation | CompanyInvolvement;
  caseId: string;
  response?: RestrictionCaseResponse;
  shareholder: string;
  minDate: string;
  maxDate: string;
  maxShares: number;
  type: RestrictionCaseType;
  onClose: () => void;
};

const formId = "case-response";

const RestrictionCaseResponseDialog = ({
  company,
  caseId,
  response,
  shareholder,
  minDate,
  maxDate,
  maxShares,
  type,
  onClose,
}: ResponseDialogProps) => {
  const i18n = useTranslation();
  const [hasRequestedShares, setHasRequestedShares] = useState(
    response ? response.requestedShares > 0 : true
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<CreateRestrictionCaseResponse>({
    mode: "onSubmit",
    defaultValues: response
      ? {
          date: response.date,
          requestedShares: response.requestedShares,
          shareholder: response.shareholder.id,
        }
      : undefined,
  });
  const getResponseOptions = () => {
    const responseOptionsByType: Record<
      RestrictionCaseType,
      Array<{ value: string; title: string }>
    > = {
      preemption: [
        {
          value: "yes",
          title: i18n.t("restrictionCase.preemption.response.yes"),
        },
        {
          value: "no",
          title: i18n.t("restrictionCase.preemption.response.no"),
        },
      ],
      offerOfFirstRefusal: [
        {
          value: "yes",
          title: i18n.t("restrictionCase.offerOfFirstRefusal.response.yes"),
        },
        {
          value: "no",
          title: i18n.t("restrictionCase.offerOfFirstRefusal.response.no"),
        },
      ],
    };

    return responseOptionsByType[type];
  };
  const responseOptions = getResponseOptions();

  const updateMutation = useUpdateShareholderResponse(
    company.orgNumber,
    caseId,
    {
      onSuccess: onClose,
    }
  );
  const createMutation = useCreateShareholderResponse(
    company.orgNumber,
    caseId,
    {
      onSuccess: onClose,
    }
  );
  const mutation = response ? updateMutation : createMutation;
  const realMaxDate =
    new Date() < new Date(maxDate)
      ? new Date().toISOString().split("T")[0]
      : maxDate;

  return (
    <Dialog
      isOpen
      title={i18n.t("restrictionCase.response.add")}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} disabled={mutation.isLoading}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            form={formId}
            isLoading={mutation.isLoading}
          >
            {i18n.t("label.save")}
          </Button>
        </>
      }
      isLoading={mutation.isLoading}
    >
      <form
        className="tw-space-y-4 tw-pt-4"
        onSubmit={(event) => {
          event.stopPropagation();

          return handleSubmit((data) => {
            mutation.mutate({
              ...data,
              shareholder,
              requestedShares: hasRequestedShares ? data.requestedShares : 0,
            });
          })(event);
        }}
        id={formId}
      >
        <RadioGroup
          value={hasRequestedShares ? "yes" : "no"}
          onChange={(newValue: "yes" | "no") => {
            setHasRequestedShares(newValue === "yes");
          }}
          name="type"
        >
          <RadioGroup.Label>
            <FormLabel htmlFor="type" className="tw-mb-2">
              {i18n.t("restrictionCase.response.responded.title")}
            </FormLabel>
          </RadioGroup.Label>
          <div className="tw-flex tw-flex-col tw-gap-2">
            {responseOptions.map((option) => (
              <RadioGroup.Option key={option.value} value={option.value}>
                {({ checked }) => (
                  <RadioGroup.OptionContent checked={checked}>
                    <Description title={option.title} />
                  </RadioGroup.OptionContent>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
        <FormGroup>
          <FormLabel htmlFor="date">
            {i18n.t("restrictionCase.response.date")}
          </FormLabel>
          <Controller
            control={control}
            name="date"
            render={({ field: { ref, name, onChange, value }, fieldState }) => (
              <>
                <Input
                  id="date"
                  value={value}
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  type="date"
                  className="tw-w-full"
                  min={minDate}
                  max={realMaxDate}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        {hasRequestedShares && (
          <FormGroup>
            <FormLabel htmlFor="requestedShares">
              {i18n.t("restrictionCase.response.shares")}
            </FormLabel>
            <Input
              id="requestedShares"
              type="number"
              step={1}
              {...register("requestedShares", {
                required: i18n.t("error.validation.required"),
                valueAsNumber: true,
                min: {
                  value: 1,
                  message: i18n.t("error.validation.range.min", { min: 1 }),
                },
                max: {
                  value: maxShares,
                  message: i18n.t("error.validation.range.max", {
                    max: maxShares,
                  }),
                },
              })}
            />
            <FormError>{errors.requestedShares?.message}</FormError>
          </FormGroup>
        )}
        {mutation.error && <FormErrorList error={mutation.error} />}
      </form>
    </Dialog>
  );
};

export { RestrictionCaseResponseDialog };
