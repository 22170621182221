import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { CSSObjectWithLabel, MenuPosition } from "react-select";

import type { LedgerVersionDetails } from "../../pages/CompanyShares/useLedgerVersions";
import type { TParentEvent } from "../../types/models/events";
import { dateToIsoString } from "../../utils/date";
import { formatEventLabel } from "../../utils/events-utils";
import { Badge } from "../design-system/Badge";
import type { OptionProps, SingleValueProps } from "../design-system/Select";
import {
  components,
  Option as SelectOption,
  Select,
} from "../design-system/Select";
import {
  countEventTypeOccurrences,
  TypeEvent,
  useEventMap,
  useEventsData,
} from "./SelectVersion.utils";

type LedgerVersionOption = {
  value: LedgerVersionDetails;
  label: string;
  eventLabel: string;
};

const Version = ({
  foundEvent,
  typeEvent,
  latestApprovedEvent,
  allEvents,
}: {
  data: LedgerVersionOption;
  foundEvent: TParentEvent;
  typeEvent?: TypeEvent;
  latestApprovedEvent?: TParentEvent;
  allEvents: TParentEvent[];
}) => {
  const i18n = useTranslation();

  const showBadge =
    typeEvent !== "approved" ||
    (typeEvent === "approved" && foundEvent === latestApprovedEvent);
  const badgeColor =
    typeEvent === "approved" && foundEvent === latestApprovedEvent
      ? "success"
      : typeEvent === "pending"
      ? "warning"
      : "none";

  const eventCount = countEventTypeOccurrences(allEvents, foundEvent);

  return (
    <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
      <div className="tw-flex tw-min-w-0 tw-items-center tw-overflow-hidden">
        <h6 className="tw-w-full tw-flex-grow tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
          {formatEventLabel(foundEvent, eventCount)}
        </h6>
      </div>
      {showBadge && typeEvent && (
        <Badge color={badgeColor} className="tw-ml-2 tw-capitalize">
          {i18n.t(`version.status.${typeEvent}`)}
        </Badge>
      )}
    </div>
  );
};

const Option = (props: OptionProps<LedgerVersionOption, false>) => {
  const { data } = props;

  const { allEvents, foundEvent, typeEvent, latestApprovedEvent } =
    useEventsData(data);

  if (!foundEvent) {
    console.warn("No event found for version", data);

    return null;
  }

  return (
    <SelectOption {...props}>
      <div className="tw-py-1">
        <Version
          data={data}
          foundEvent={foundEvent}
          typeEvent={typeEvent}
          latestApprovedEvent={latestApprovedEvent}
          allEvents={allEvents}
        />
      </div>
    </SelectOption>
  );
};

const SingleValue = (props: SingleValueProps<LedgerVersionOption, false>) => {
  const { data } = props;
  const { allEvents, foundEvent, typeEvent, latestApprovedEvent } =
    useEventsData(data);

  if (!foundEvent) {
    console.warn("No event found for version", data);

    return null;
  }

  return (
    <components.SingleValue {...props}>
      <Version
        data={data}
        foundEvent={foundEvent}
        typeEvent={typeEvent}
        latestApprovedEvent={latestApprovedEvent}
        allEvents={allEvents}
      />
    </components.SingleValue>
  );
};

type SelectVersionProps = {
  className?: string;
  availableVersions: LedgerVersionDetails[];
  onChange: (version?: LedgerVersionDetails) => void;
  selectedVersion?: LedgerVersionDetails;
  menuPlacement?: "auto" | "bottom" | "top";
  menuPosition?: MenuPosition;
  isDisabled?: boolean;
};

const SelectVersion: React.FunctionComponent<SelectVersionProps> = ({
  className,
  availableVersions,
  onChange,
  selectedVersion,
  menuPlacement = "auto",
  menuPosition,
  isDisabled,
}) => {
  const { eventMap } = useEventMap();

  const options: LedgerVersionOption[] = useMemo(
    () =>
      availableVersions.map((version) => {
        const dateLabel = dateToIsoString(version.date);

        const eventDate = `${dateLabel}.${version.version}`;
        const eventData = eventMap.get(eventDate);

        const eventLabel = eventData?.event
          ? formatEventLabel(eventData.event, 1)
          : dateLabel;

        return {
          label: dateLabel,
          value: version,
          eventLabel,
        };
      }),
    [availableVersions, eventMap]
  );

  const selectedOption = useMemo(
    () =>
      selectedVersion
        ? options.find(
            ({ value }) => value.formatedValue === selectedVersion.formatedValue
          )
        : null,
    [options, selectedVersion]
  );

  const colorStyles = {
    control: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: "black",
      border: "1px solid #e5e5e5",
      boxShadow: "none",
      minWidth: "280px",
    }),
    singleValue: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: "black",
    }),
  };

  return (
    <div className={className}>
      <Select
        styles={colorStyles}
        value={selectedOption}
        options={options}
        onChange={(newValue) => onChange(newValue?.value)}
        components={{ Option, SingleValue }}
        menuPlacement={menuPlacement}
        menuPosition={menuPosition}
        isDisabled={isDisabled}
        filterOption={(option, inputValue) => {
          return option.data.eventLabel
            .toLowerCase()
            .includes(inputValue.toLowerCase());
        }}
      />
    </div>
  );
};

export { SelectVersion };
export type { LedgerVersionOption };
