import { useTranslation } from "react-i18next";

import { PageWrapper } from "../../../../components/PageWrapper";
import { useCurrentCompany } from "../../../../context/account";
import { CompanyInformation } from "../../../../types/models/administration";
import { CompanyInvolvement } from "../../../../types/models/company";
import logo from "./logo.png";
import { PolicySection } from "./policy";
import { SettingsRoutes } from "./routes";
import { SupportSection } from "./support";
import { UserSection } from "./users";

const SettingsPage = ({
  currentCompany,
}: {
  currentCompany: CompanyInvolvement | CompanyInformation;
}) => {
  const i18n = useTranslation();

  return (
    <div
      className="tw-mx-auto tw-flex tw-max-w-3xl tw-flex-col tw-gap-6"
      data-testid="company-settings"
    >
      <h1 className="tw-text-2xl tw-font-medium">{i18n.t("settings.title")}</h1>
      <div className="tw-flex tw-flex-col tw-gap-6">
        <div className="tw-relative tw-overflow-hidden tw-rounded-lg tw-border tw-p-0 tw-p-4 md:tw-p-6">
          <PolicySection currentCompany={currentCompany} />
          <img
            src={logo}
            alt="logo"
            className="tw-absolute tw-bottom-0 tw-right-0 tw-hidden tw-h-20 tw-w-20 md:tw-block"
          />
        </div>
        <div className="tw-rounded-lg tw-border tw-p-0 tw-p-4 md:tw-p-6">
          <SupportSection
            currentCompany={currentCompany as CompanyInvolvement}
          />
        </div>
      </div>
      <div className="tw-rounded-lg tw-border tw-p-0 tw-p-4 md:tw-p-6">
        <UserSection currentCompany={currentCompany} />
      </div>
    </div>
  );
};

const CompanySettingsPage = () => {
  const currentCompany = useCurrentCompany();

  if (!currentCompany) {
    return <></>;
  }

  return (
    <PageWrapper>
      <SettingsRoutes currentCompany={currentCompany} />
    </PageWrapper>
  );
};

export { CompanySettingsPage, SettingsPage };
