import { TDraftShareBlock } from "../../types/models/draft";
import { sumRanges } from "../../utils/shares";

const allocateDistributedShares = (
  allocations: (TDraftShareBlock & { allocated: number })[]
) => {
  return allocations
    .sort((a, b) => a.start - b.start)
    .map((b, i) => {
      const offset = allocations
        .slice(0, i)
        .reduce((sum, a) => sum + a.allocated, 0);
      return {
        ...b,
        start: b.start + offset,
        end: b.end + b.allocated + offset,
      };
    });
};

const sainteLagueMethod = (
  blocks: TDraftShareBlock[],
  remainingShares: number
) => {
  // Sainte-Laguë method
  const allocations = blocks.map((b) => ({
    ...b,
    divisor: 1,
    allocated: 0,
  }));

  for (let i = 0; i < remainingShares; i++) {
    // Sort blocks by Sainte-Laguë quotient (current shares / divisor)
    allocations.sort(
      (a, b) =>
        (b.end - b.start + 1) / b.divisor - (a.end - a.start + 1) / a.divisor
    );

    // Allocate a share to the block with the highest quotient
    allocations[0]!.allocated += 1;

    // Increase their divisor for the next round
    allocations[0]!.divisor += 2;
  }

  return allocateDistributedShares(allocations);
};

const modifiedSainteLagueMethod = (
  blocks: TDraftShareBlock[],
  remainingShares: number,
  initialDivisor: number = 1.4
) => {
  // Modified Sainte-Laguë method
  const allocations = blocks.map((b) => ({
    ...b,
    divisor: initialDivisor,
    allocated: 0,
  }));

  for (let i = 0; i < remainingShares; i++) {
    // Sort blocks by Sainte-Laguë quotient (current shares / divisor)
    allocations.sort(
      (a, b) =>
        (b.end - b.start + 1) / b.divisor - (a.end - a.start + 1) / a.divisor
    );

    // Allocate a share to the block with the highest quotient
    allocations[0]!.allocated += 1;

    // If it's the first allocation for this block, set divisor to 3
    // Otherwise, increase divisor by 2 (5, 7, 9, ...)
    allocations[0]!.divisor =
      allocations[0]!.allocated === 1 ? 3 : allocations[0]!.divisor + 2;
  }

  return allocateDistributedShares(allocations);
};

const dHondtMethod = (blocks: TDraftShareBlock[], remainingShares: number) => {
  // D'Hondt method
  const allocations = blocks.map((b) => ({
    ...b,
    divisor: 1,
    allocated: 0,
  }));

  for (let i = 0; i < remainingShares; i++) {
    // Sort blocks by D'Hondt quotient (current shares / divisor)
    allocations.sort(
      (a, b) =>
        (b.end - b.start + 1) / b.divisor - (a.end - a.start + 1) / a.divisor
    );

    // Allocate a share to the block with the highest quotient
    allocations[0]!.allocated += 1;

    // Increase their divisor for the next round (1 → 2 → 3 → 4 → ...)
    allocations[0]!.divisor += 1;
  }

  return allocateDistributedShares(allocations);
};

const getBlocksWithDistribution = (
  blocks: TDraftShareBlock[],
  sharesToAdd: number,
  distributionMethod: DistributionMethod
) => {
  if (blocks.length === 0) {
    return [];
  }
  const leftoverShares = sharesToAdd - sumRanges(blocks);
  if (leftoverShares === 0) {
    return blocks;
  }
  switch (distributionMethod) {
    case "manual":
      return blocks;
    case "saintLague":
      return sainteLagueMethod(blocks, leftoverShares);
    case "modifiedSaintLague1.2":
      return modifiedSainteLagueMethod(blocks, leftoverShares, 1.2);
    case "modifiedSaintLague1.4":
      return modifiedSainteLagueMethod(blocks, leftoverShares, 1.4);
    case "dHondt":
      return dHondtMethod(blocks, leftoverShares);
    default:
      throw new Error(`Unhandled distribution method: ${distributionMethod}`);
  }
};

type DistributionMethod =
  | "manual"
  | "saintLague"
  | "modifiedSaintLague1.2"
  | "modifiedSaintLague1.4"
  | "dHondt";

export {
  dHondtMethod,
  getBlocksWithDistribution,
  modifiedSainteLagueMethod,
  sainteLagueMethod,
};
export type { DistributionMethod };
