import type React from "react";
import { forwardRef } from "react";

import { clsxm } from "../../../utils/tailwind";
import { CheckIcon } from "../icons";

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  children?: React.ReactNode;
};

const Checkbox = forwardRef(
  (
    { children, disabled, className, ...rest }: CheckboxProps,
    ref: React.Ref<HTMLInputElement>
  ) => (
    <label
      className={clsxm(
        "tw-relative tw-flex tw-cursor-pointer tw-flex-row tw-items-center tw-gap-2",
        { "tw-cursor-not-allowed": disabled },
        className
      )}
    >
      <input
        {...rest}
        ref={ref}
        className={clsxm(
          "tw-peer tw-h-6 tw-w-6 tw-shrink-0 tw-appearance-none tw-rounded tw-border tw-border-secondary",
          {
            "tw-bg-gray-100": disabled,
            "checked:tw-border-primary checked:tw-bg-primary": !disabled,
          }
        )}
        disabled={disabled}
        type="checkbox"
      />
      <CheckIcon
        aria-hidden="true"
        className="tw-absolute tw-left-0.5 tw-hidden tw-h-5 tw-w-5 tw-text-white peer-checked:tw-block peer-disabled:tw-text-secondary"
      />
      {children && (
        <span className="tw-text-body peer-disabled:tw-text-secondary">
          {children}
        </span>
      )}
    </label>
  )
);

export { Checkbox };
export type { CheckboxProps };
