import { Link } from "react-router-dom";

import { NavItem } from "./SidebarOption";

const MenuLink = ({ link }: { link: NavItem }) => {
  return link.to ? (
    <Link
      to={link.to}
      className="tw-flex tw-w-full tw-items-center tw-gap-2 tw-text-sm tw-text-secondary"
    >
      {link.icon}
      {link.label}
    </Link>
  ) : (
    <button
      onClick={(e) => {
        e.preventDefault();
        if (link.onClick) {
          link.onClick();
        }
      }}
      type="button"
      className="tw-flex tw-w-full tw-items-center tw-gap-2 tw-text-sm tw-text-secondary"
    >
      {link.icon}
      {link.label}
    </button>
  );
};

export { MenuLink };
