import { clsxm } from "../../../utils/tailwind";

type ToggleProps = React.HTMLAttributes<HTMLSpanElement> & {
  label: string;
  className?: string;
  isActive?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

const Toggle = ({
  label,
  className,
  isActive = false,
  disabled = false,
  onClick = () => {},
}: ToggleProps) => (
  <button
    onClick={onClick}
    type="button"
    className={clsxm(
      "tw-flex tw-items-center tw-gap-2",
      { "tw-cursor-not-allowed": disabled },
      className
    )}
    disabled={disabled}
  >
    <div
      className={clsxm(
        "tw-flex tw-h-6 tw-w-10 tw-min-w-[2.5rem] tw-items-center tw-rounded-full tw-p-0.5",
        {
          "tw-bg-green-500": isActive,
          "tw-bg-gray-300": !isActive,
          "tw-bg-gray-100": disabled,
        }
      )}
    >
      <input
        type="checkbox"
        className="tw-peer tw-sr-only"
        checked={isActive}
        readOnly
      />
      <div
        className={clsxm(
          "tw-h-5 tw-w-5 tw-transform tw-rounded-full tw-bg-white tw-shadow-md tw-transition-transform tw-duration-200",
          {
            "tw-translate-x-4": isActive,
          }
        )}
      />
    </div>
    <span
      className={clsxm("tw-align-baseline tw-text-sm", {
        "tw-text-neutral-400": disabled,
      })}
    >
      {label}
    </span>
  </button>
);

export { Toggle };
export type { ToggleProps };
