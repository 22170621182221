import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import bankId from "../../api/rest/auth/BankID";
import { notify } from "../../components/design-system/Notifications";
import isMobileDevice from "../../utils/isMobileDevice";
import { SignMethodProps } from "..";
import BankIdDeviceSign from "./BankIdDeviceSign";
import BankIdMobileSign from "./BankIdMobileSign";
import { cancelBankIdQueries, handleBankIdCancel } from "./utils";

const BankIdSign = (props: SignMethodProps) => {
  const i18n = useTranslation();
  const mobile = isMobileDevice();
  const [device, setDevice] = useState(false);
  const queryClient = useQueryClient();
  const [isCanceling, setIsCanceling] = useState(false);

  const initMutation = bankId.useInitSignMutation({
    onError: () => {
      notify(i18n.t("error.general"), { type: "error" });
    },
  });
  const cancelMutation = bankId.useCancelMutation();

  const onInit = () => {
    initMutation.mutate(props.request);
  };

  const onCancel = () => {
    if (isCanceling) {
      return;
    }

    handleBankIdCancel(
      queryClient,
      initMutation.data?.orderRef,
      cancelMutation,
      initMutation.reset,
      props.onCancel,
      setIsCanceling
    );
  };

  // Init
  useEffect(() => {
    onInit();
    return () => {
      if (initMutation.data?.orderRef) {
        cancelBankIdQueries(queryClient, initMutation.data?.orderRef);
        cancelMutation.mutate(initMutation.data.orderRef);
      }
    };
  }, []);

  // If on a mobile device or the "Same device" option is clicked
  if (mobile || device) {
    return (
      <BankIdDeviceSign
        initMutation={initMutation}
        onRetry={onInit}
        onSuccess={props.onSuccess}
        onCancel={onCancel}
      />
    );
  }

  return (
    <BankIdMobileSign
      initMutation={initMutation}
      onRetry={onInit}
      onSuccess={props.onSuccess}
      onCancel={onCancel}
      onClickSameDevice={() => setDevice(true)}
    />
  );
};

export default BankIdSign;
