import { erf } from "mathjs";

// Normal cumulative distribution
function normalCDF(x: number) {
  return 0.5 * (1 + erf(x / Math.sqrt(2)));
}

function blackScholes(
  stockPrice: number,
  strikePrice: number,
  riskFreeRate: number,
  yearsToExpiration: number,
  volatility: number
) {
  const d1 =
    (Math.log(stockPrice / strikePrice) +
      (riskFreeRate + 0.5 * volatility ** 2) * yearsToExpiration) /
    (volatility * Math.sqrt(yearsToExpiration));
  const d2 = d1 - volatility * Math.sqrt(yearsToExpiration);
  const Nd1 = normalCDF(d1);
  const Nd2 = normalCDF(d2);

  // Call option price
  return (
    stockPrice * Nd1 -
    strikePrice * Math.exp(-riskFreeRate * yearsToExpiration) * Nd2
  );
}

function calculateYearsBetweenDates(start: Date, end: Date) {
  const timeDifference = end.getTime() - start.getTime();
  const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
  return daysDifference / 365;
}

export { blackScholes, calculateYearsBetweenDates };
