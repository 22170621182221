import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useAdminCompaniesQuery } from "../api/blockchain/administration";
import { useCompaniesQuery } from "../api/blockchain/company";
import { updateLanguage, useMyLanguageQuery } from "../api/rest/person";
import type { CompanyInformation } from "../types/models/administration";
import type { CompanyInvolvement } from "../types/models/company";
import { formatCurrency } from "../utils/format";
import { useSession } from "./session";

const useCurrentCompany = (orgNumber?: string) => {
  const params = useParams();
  const companyId = params.companyId || orgNumber;
  const companies = useCompaniesQuery();
  const { user } = useSession();
  const adminCompaniesQuery = useAdminCompaniesQuery();

  let currentCompany: CompanyInformation | CompanyInvolvement | undefined = (
    companies?.data || []
  ).find((company) => company.orgNumber === companyId);

  if (
    user?.role === "Administrator" &&
    !currentCompany &&
    adminCompaniesQuery.isSuccess
  ) {
    currentCompany = adminCompaniesQuery.data?.find(
      (company) => company.orgNumber === companyId
    );
  }
  return currentCompany;
};

const useCompanyUtils = (orgNumber?: string) => {
  const currentCompany = useCurrentCompany(orgNumber);

  return {
    formatCurrency: (value: number | string) =>
      formatCurrency(
        typeof value === "string" ? parseFloat(value) : value,
        currentCompany?.settings?.currency
      ),
  };
};

const AccountProvider = ({ children }: { children: React.ReactNode }) => {
  const i18n = useTranslation();
  const myLanguage = useMyLanguageQuery();

  useEffect(() => {
    if (
      myLanguage.isSuccess &&
      myLanguage.data?.language !== i18n.i18n.language
    ) {
      updateLanguage(i18n.i18n.language)
        .then(() => myLanguage.refetch())
        .catch((e) => {
          throw e;
        });
    }
  }, [i18n.i18n.language, myLanguage.data?.language]);

  return <>{children}</>;
};

export { AccountProvider, useCompanyUtils, useCurrentCompany };
