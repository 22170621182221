import { useQuery } from "@tanstack/react-query";

import { useBlockchainClient } from "../../context/blockchain";
import { RestrictionCase } from "../../types/models/restriction-case";
import type { IRequestError } from "..";
import { getAuthHeader } from "../auth";

const useRestrictionCaseQuery = (orgNumber: string | undefined) => {
  const authHeader = getAuthHeader();
  const blockchainClient = useBlockchainClient();

  return useQuery<unknown, IRequestError, RestrictionCase[]>(
    ["restrictionCases", orgNumber || ""],
    () =>
      blockchainClient.query<RestrictionCase[]>("restriction_case.get_cases", {
        auth: authHeader!,
        org_no: orgNumber!,
      }),
    {
      enabled: !!orgNumber && !!authHeader,
    }
  );
};

export { useRestrictionCaseQuery };
