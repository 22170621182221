import { MenuPlacement } from "react-select";

import type { OptionProps, SingleValueProps } from "../design-system/Select";
import {
  components,
  Option as SelectOption,
  Select,
} from "../design-system/Select";

const currencyOptions: { value: string; label: string }[] = [
  { value: "SEK", label: "SEK" },
  { value: "EUR", label: "EUR" },
];

type Option = { value: string; label: string };

const CurrencyOption = (props: OptionProps<Option, false>) => {
  const { data } = props;

  return <SelectOption {...props}>{data.label}</SelectOption>;
};

const SingleValue = (props: SingleValueProps<Option, false>) => {
  const { data } = props;

  return (
    <components.SingleValue {...props}>{data.label}</components.SingleValue>
  );
};

const SelectCurrency = ({
  value,
  onChange,
  menuPlacement,
}: {
  value?: string;
  onChange: (value?: string) => void;
  menuPlacement?: MenuPlacement;
}) => (
  <Select
    menuPlacement={menuPlacement}
    value={currencyOptions.find((c) => c.value === value)}
    options={currencyOptions}
    onChange={(newValue) => onChange(newValue?.value)}
    components={{ Option: CurrencyOption, SingleValue }}
  />
);

export { SelectCurrency };
