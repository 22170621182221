import { useTranslation } from "react-i18next";

import { useDeleteShareholderResponse } from "../../../../api/rest/restriction-case";
import { Button } from "../../../../components/design-system/Button";
import { Dialog } from "../../../../components/design-system/Dialog";
import { CompanyInformation } from "../../../../types/models/administration";
import { CompanyInvolvement } from "../../../../types/models/company";

type ResponseDeleteDialogProps = {
  company: CompanyInformation | CompanyInvolvement;
  caseId: string;
  shareholderId: string;
  onClose: () => void;
};

const ResponseDeleteDialog = ({
  company,
  caseId,
  shareholderId,
  onClose,
}: ResponseDeleteDialogProps) => {
  const i18n = useTranslation();
  const mutation = useDeleteShareholderResponse(company.orgNumber, caseId, {
    onSuccess: onClose,
  });

  return (
    <Dialog
      isOpen
      title={i18n.t("restrictionCase.response.deleteConfirmation.title")}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} disabled={mutation.isLoading}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            variant="solid"
            color="primary"
            onClick={() => mutation.mutate(shareholderId)}
            isLoading={mutation.isLoading}
          >
            {i18n.t("restrictionCase.response.deleteConfirmation.title")}
          </Button>
        </>
      }
      isLoading={mutation.isLoading}
    >
      {i18n.t("restrictionCase.response.deleteConfirmation.description")}
    </Dialog>
  );
};

export { ResponseDeleteDialog };
