import { DownloadSimple } from "@phosphor-icons/react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { clsxm } from "../../utils/tailwind";
import { Button } from "../design-system/Button";
import { FormError, FormGroup, FormLabel } from "../design-system/FormGroup";
import { Input } from "../design-system/Input";
import { Menu } from "../design-system/Menu";
import { Select } from "../design-system/Select";

type FormData = {
  type: ExportType;
  language: string;
  date: string;
};

type ExportMenuProps = {
  downloadPdf: (data: FormData) => Promise<void>;
  downloadXlsx: (data: FormData) => void;
  disabled: boolean;
};

type ExportType = "pdf" | "xlsx";

const ExportMenuVotingRegister = ({
  downloadPdf,
  downloadXlsx,
  disabled,
}: ExportMenuProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const i18n = useTranslation();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const exportTypeOptions: {
    label: string;
    value: ExportType;
  }[] = [
    { label: i18n.t("exportMenu.type.pdf"), value: "pdf" },
    { label: i18n.t("exportMenu.type.xlsx"), value: "xlsx" },
  ];

  const languageOptions = [
    { label: i18n.t("language.swedish"), value: "sv-SE" },
    { label: i18n.t("language.english"), value: "en-US" },
  ];

  const { control, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      date: new Date().toISOString().split("T")[0]!,
      type: "pdf" as ExportType,
      language: i18n.i18n.language,
    },
  });

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    if (data.type === "pdf") {
      await downloadPdf(data);
    } else {
      downloadXlsx(data);
    }
    setIsLoading(false);
  };

  return (
    <Menu className="tw-w-full">
      <Menu.Button
        type="button"
        className="tw-flex tw-w-full tw-items-center tw-rounded-md tw-p-4 max-md:tw-p-3"
        aria-label="Export"
        variant="solid"
        color="primary"
        isDropdown={false}
        disabled={disabled}
        suffix={<DownloadSimple className="tw-h-6 tw-w-6" />}
      >
        {i18n.t("votingRegister.button")}
      </Menu.Button>
      <Menu.Items
        className={clsxm(
          "max-md:tw-mx-4 max-md:tw-w-[calc(100%-32px)] md:tw-mr-6 md:tw-min-w-[350px]",
          { "md:tw--ml-[calc(350px-58px)]": !isTabletOrMobileDevice }
        )}
        align={isTabletOrMobileDevice ? "bottomLeft" : "bottomRight"}
      >
        <div className="tw-p-4">
          <form
            className="tw-space-y-4"
            onSubmit={(event) => handleSubmit((data) => onSubmit(data))(event)}
          >
            <FormGroup>
              <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
              <Controller
                control={control}
                name="date"
                render={({
                  field: { ref, name, onChange, value },
                  fieldState,
                }) => (
                  <>
                    <Input
                      id="date"
                      value={value}
                      ref={ref}
                      name={name}
                      onChange={onChange}
                      type="date"
                      className="tw-w-full"
                    />
                    <FormError>{fieldState.error?.message}</FormError>
                  </>
                )}
                rules={{ required: i18n.t("error.validation.required") }}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="type">{i18n.t("exportMenu.type")}</FormLabel>
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <>
                    <Select
                      name="type"
                      options={exportTypeOptions}
                      value={exportTypeOptions.find((o) => value === o.value)}
                      onChange={(t) => onChange(t?.value)}
                    />
                    <FormError>{fieldState.error?.message}</FormError>
                  </>
                )}
                name="type"
                rules={{ required: i18n.t("error.validation.required") }}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="language">
                {i18n.t("exportMenu.language")}
              </FormLabel>
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <>
                    <Select
                      name="language"
                      options={languageOptions}
                      value={languageOptions.find((o) => value === o.value)}
                      onChange={(l) => onChange(l?.value)}
                    />
                    <FormError>{fieldState.error?.message}</FormError>
                  </>
                )}
                name="language"
                rules={{ required: i18n.t("error.validation.required") }}
              />
            </FormGroup>
            <Button
              type="submit"
              color="primary"
              variant="solid"
              className="tw-w-full"
              prefix={<DownloadSimple />}
              isLoading={isLoading}
            >
              {i18n.t("exportMenu.button")}
            </Button>
          </form>
        </div>
      </Menu.Items>
    </Menu>
  );
};

export { ExportMenuVotingRegister };
export type { FormData };
