import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";

import { useRestrictionCaseQuery } from "../../../../api/blockchain/restrictionCase";
import { useEntitiesQuery } from "../../../../api/rest/entities";
import { Badge } from "../../../../components/design-system/Badge";
import { Breadcrumb } from "../../../../components/design-system/Breadcrumb";
import { Button } from "../../../../components/design-system/Button";
import { EntityItem } from "../../../../components/design-system/EntityItem";
import { PencilIcon } from "../../../../components/design-system/icons";
import { Loading } from "../../../../components/design-system/Loading";
import { TableV2 } from "../../../../components/design-system/TableV2";
import { NoData } from "../../../../components/NoData";
import { PageWrapper } from "../../../../components/PageWrapper";
import { useCurrentCompany } from "../../../../context/account";
import { useSession } from "../../../../context/session";
import { APP_ROUTE } from "../../../../routes/constants";
import { RestrictionCaseLitigation } from "../../../../types/models/restriction-case";
import { getFormattedDate } from "../../../../utils/date";
import { hasRequiredPermission } from "../../../../utils/permissions";
import { RestrictionCaseLitigationForm } from "./RestrictionCaseLitigationForm";
import { RestrictionCaseLitigationUpdateForm } from "./RestrictionCaseLitigationUpdateForm";

const RestrictionCaseLitigationPage = () => {
  const i18n = useTranslation();
  const { user } = useSession();
  const { caseId } = useParams<{ caseId?: string }>();
  const [expanded, setExpanded] = useState<Record<number, boolean>>({});
  const [sortBy, setSortBy] = useState<string>("date-desc");
  const currentCompany = useCurrentCompany();
  const [showAddLitigation, setShowAddLitigation] = useState(false);
  const [showUpdateLitigation, setShowUpdateLitigation] = useState<
    RestrictionCaseLitigation | undefined
  >();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const restrictionCaseQuery = useRestrictionCaseQuery(
    currentCompany?.orgNumber
  );
  const restrictionCases = restrictionCaseQuery.data || [];
  const restrictionCase = restrictionCases.find((n) => n.id === caseId);

  const entitiesQuery = useEntitiesQuery(currentCompany?.orgNumber || "");
  const entities = entitiesQuery.data || [];
  const entitiesMap = Object.fromEntries(entities.map((e) => [e.id, e]));

  if (!caseId) {
    return <NoData />;
  }

  if (!currentCompany || !restrictionCase || entitiesQuery.isLoading) {
    return <Loading />;
  }

  const sortLitigation = (l: RestrictionCaseLitigation[]) => {
    const [sort, sortOrder] = sortBy.split("-");
    const isAscending = sortOrder === "asc";
    const order = isAscending ? -1 : 1;

    if (sort === "filedBy") {
      return l.sort((a, b) =>
        isAscending
          ? (entitiesMap[a.filedBy.id]?.name || "").localeCompare(
              entitiesMap[b.filedBy.id]?.name || ""
            )
          : (entitiesMap[b.filedBy.id]?.name || "").localeCompare(
              entitiesMap[a.filedBy.id]?.name || ""
            )
      );
    }

    if (sort === "status") {
      l.sort((a, b) => (a.status > b.status ? -1 * order : order));
    }

    return l.sort((a, b) => (a.filedDate > b.filedDate ? -1 * order : order));
  };

  const litigation = sortLitigation(restrictionCase.litigation);

  return (
    <PageWrapper>
      <div className="tw-pb-4">
        <Breadcrumb
          links={[
            {
              url: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/restriction-cases`,
              name: i18n.t("restrictionCase.title"),
            },
            {
              url: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/restriction-cases/${caseId}`,
              name: i18n.t(`restrictionCase.type.${restrictionCase.type}`),
            },
          ]}
          current={i18n.t("restrictionCase.litigation.title")}
        />
      </div>
      <header className="tw-flex tw-flex-col tw-justify-between tw-gap-2 tw-pb-6 md:tw-flex-row md:tw-items-center">
        <h1 className="tw-text-2xl tw-font-medium">
          {i18n.t("restrictionCase.litigation.title")}
        </h1>
        {hasRequiredPermission("Editor", currentCompany, user) && (
          <div className="tw-flex tw-gap-2">
            <Button
              onClick={() => setShowAddLitigation(true)}
              className="tw-w-full"
            >
              {i18n.t("restrictionCase.litigation.add")}
            </Button>
          </div>
        )}
      </header>
      <TableV2
        columns={[
          {
            name: "filedBy",
            title: i18n.t("restrictionCase.litigation.filedBy"),
            key: true,
            sortable: true,
          },
          {
            name: "date",
            title: i18n.t("restrictionCase.litigation.filedDate"),
            key: !isTabletOrMobileDevice,
            sortable: true,
          },
          {
            name: "status",
            title: i18n.t("restrictionCase.litigation.status"),
            key: !isTabletOrMobileDevice,
            sortable: true,
          },
          {
            name: "actions",
            title: "",
            key: true,
            sortable: false,
          },
        ]}
        data={litigation.map((l) => ({
          key: l.id,
          filedBy: <EntityItem value={entitiesMap[l.filedBy.id]!} />,
          date: (
            <div className="tw-text-sm">{getFormattedDate(l.filedDate)}</div>
          ),
          status:
            l.status === "ACTIVE" ? (
              <Badge color="blue">
                {i18n.t("restrictionCase.litigation.status.active")}
              </Badge>
            ) : (
              <Badge color="success">
                {i18n.t("restrictionCase.litigation.status.resolved")}
              </Badge>
            ),
          actions: hasRequiredPermission("Editor", currentCompany, user) ? (
            <div className="tw-flex tw-justify-end">
              <Button
                size="md"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowUpdateLitigation(l);
                }}
              >
                <PencilIcon />
              </Button>
            </div>
          ) : undefined,
        }))}
        sortBy={sortBy}
        setSortBy={setSortBy}
        expandedRows={expanded}
        setExpandedRows={setExpanded}
      />
      {showAddLitigation && (
        <RestrictionCaseLitigationForm
          company={currentCompany}
          caseId={caseId}
          minDate={restrictionCase.date}
          onClose={() => setShowAddLitigation(false)}
        />
      )}
      {showUpdateLitigation && (
        <RestrictionCaseLitigationUpdateForm
          company={currentCompany}
          caseId={caseId}
          litigationId={showUpdateLitigation.id}
          defaultValues={showUpdateLitigation}
          onClose={() => setShowUpdateLitigation(undefined)}
        />
      )}
    </PageWrapper>
  );
};

export default RestrictionCaseLitigationPage;
