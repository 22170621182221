import { CopySimple } from "@phosphor-icons/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { convertErrorToTi18nKey } from "../../../../../api";
import { useShareholderBlocksQuery } from "../../../../../api/blockchain/company";
import { useUsersQuery } from "../../../../../api/blockchain/users";
import { useEntitiesQuery } from "../../../../../api/rest/entities";
import { Button } from "../../../../../components/design-system/Button";
import { Chip } from "../../../../../components/design-system/Chip";
import { EntityItem } from "../../../../../components/design-system/EntityItem";
import { Loading } from "../../../../../components/design-system/Loading";
import { notify } from "../../../../../components/design-system/Notifications";
import { TableV2 } from "../../../../../components/design-system/TableV2";
import { AddEntity } from "../../../../../components/Entities/AddEntity";
import { EditEntity } from "../../../../../components/Entities/Edit";
import { NoData } from "../../../../../components/NoData";
import { VerifiedEmail } from "../../../../../components/VerifiedEmail";
import { useSession } from "../../../../../context/session";
import type { CompanyInformation } from "../../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../../types/models/company";
import { formatAddress } from "../../../../../utils/format";
import { hasRequiredPermission } from "../../../../../utils/permissions";

type EntitiesProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};
const EntitiesPage = ({ currentCompany }: EntitiesProps) => {
  const i18n = useTranslation();
  const { user } = useSession();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [sortBy, setSortBy] = useState("entity-desc");
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const usersQuery = useUsersQuery(currentCompany.orgNumber);
  const shareHoldersQuery = useShareholderBlocksQuery(currentCompany.orgNumber);
  const handleEntitiesChange = () => {
    entitiesQuery.refetch();
  };
  const isLoading =
    entitiesQuery.isLoading ||
    usersQuery.isLoading ||
    shareHoldersQuery.isLoading;
  const errorCode =
    entitiesQuery.error && convertErrorToTi18nKey(entitiesQuery.error);

  type Filter =
    | "shareholders"
    | "users"
    | "representatives"
    | "trustees"
    | "inactive";
  const [display, setDisplay] = useState<Record<Filter, boolean>>({
    shareholders: true,
    users: false,
    representatives: false,
    trustees: false,
    inactive: false,
  });
  const displayMenuItems: Record<Filter, string> = {
    shareholders: i18n.t("label.shareholders"),
    users: i18n.t("label.users"),
    representatives: i18n.t("entities.filter.representatives"),
    trustees: i18n.t("entities.filter.trustees"),
    inactive: i18n.t("entities.filter.inactive"),
  };

  const entites = useMemo(() => {
    const shareholderIds = shareHoldersQuery.data.map((x) => x.id);
    const shareHolderRefIds = shareHoldersQuery.data.map(
      (x) => x.unmaskedRefId
    );
    const userIds = (usersQuery.data || []).map((x) => x.user.id);

    return (entitiesQuery.data || []).filter((e) => {
      const isShareholder =
        shareholderIds.includes(e.id) || shareHolderRefIds.includes(e.refId);
      const isUser = userIds.includes(e.id);

      return (
        (display.shareholders && isShareholder) ||
        (display.users && isUser) ||
        (display.representatives &&
          e.parents &&
          e.parents?.filter((x) => x.type === "Company").length > 0) ||
        (display.trustees &&
          e.parents &&
          e.parents?.filter((x) => x.type === "Private").length > 0) ||
        (display.inactive &&
          !isShareholder &&
          !isUser &&
          e.parents?.length === 0)
      );
    });
  }, [entitiesQuery.data, usersQuery.data, shareHoldersQuery.data, display]);

  const entitiesSorted = useMemo(() => {
    const [sortType, order] = sortBy.split("-");
    const sortOrder = order === "desc" ? 1 : -1;
    if (sortType === "entity") {
      return entites.sort((a, b) => {
        if (a.name === b.name) {
          return 0;
        }
        return a.name > b.name ? sortOrder : -1 * sortOrder;
      });
    }
    return entites;
  }, [entites, sortBy]);

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <div className="tw-flex tw-flex-col tw-justify-between tw-gap-4 md:tw-flex-row md:tw-items-center">
        <div>
          <h3 className="tw-font-medium">{i18n.t("label.entities")}</h3>
          <p className="tw-max-w-3xl tw-text-sm tw-text-secondary">
            {i18n.t("entity.list.description2")}
          </p>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-2 md:tw-flex-row">
          <Button
            onClick={async () => {
              await navigator.clipboard.writeText(
                entitiesSorted
                  .filter((e) => e.contact.email)
                  .map((e) => e.contact.email)
                  .join(", ")
              );
              notify(i18n.t("copyEmail.success"));
            }}
            suffix={<CopySimple />}
          >
            {i18n.t("copyEmail.all")}
          </Button>
          {hasRequiredPermission("Editor", currentCompany, user) && (
            <AddEntity
              currentCompany={currentCompany}
              onSuccess={handleEntitiesChange}
              customButtonProps={{
                children: i18n.t("label.addEntity"),
                color: "primary",
                variant: "solid",
              }}
            />
          )}
        </div>
      </div>
      <div className="tw-flex tw-flex-wrap tw-gap-2">
        {Object.entries(displayMenuItems).map(([key, label]) => {
          const value = display[key as Filter];
          return (
            <Chip
              key={key}
              isActive={value}
              onClick={() => setDisplay({ ...display, [key]: !value })}
            >
              {label}
            </Chip>
          );
        })}
      </div>
      {isLoading && <Loading />}
      {entites.length > 0 && (
        <TableV2
          id="entities"
          columns={[
            {
              name: "entity",
              title: i18n.t("label.entity"),
              key: true,
            },
            {
              name: "address",
              title: i18n.t("label.address"),
              key: !isTabletOrMobileDevice,
              sortable: false,
            },
            {
              name: "email",
              title: i18n.t("label.email"),
              key: !isTabletOrMobileDevice,
              sortable: false,
            },
            {
              name: "controls",
              title: "",
              key: !isTabletOrMobileDevice,
              sortable: false,
            },
          ]}
          data={entitiesSorted.map((e) => ({
            key: e.id,
            entity: <EntityItem value={e} hasFlag={false} />,
            address: e.contact.address ? (
              <div className="tw-text-sm">
                {formatAddress(e.contact.address, i18n.i18n.language)}
              </div>
            ) : (
              ""
            ),
            email: e.contact.email ? (
              <div className="tw-flex tw-gap-2">
                <a
                  href={`mailto:${e.contact.email}`}
                  rel="noreferrer noopener"
                  target="_blank"
                  className="tw-flex tw-items-center tw-text-sm tw-text-secondary md:tw-text-primary"
                >
                  {e.contact.email}
                  <VerifiedEmail emailVerification={e.emailVerification} />
                </a>
                <button
                  onClick={async () => {
                    await navigator.clipboard.writeText(e.contact.email!);
                    notify(i18n.t("copyEmail.success"));
                  }}
                  type="button"
                >
                  <CopySimple />
                </button>
              </div>
            ) : (
              ""
            ),
            controls: (
              <div className="tw-flex tw-items-center tw-justify-end tw-gap-4">
                {hasRequiredPermission("Editor", currentCompany, user) && (
                  <EditEntity
                    currentCompany={currentCompany}
                    value={e}
                    onSuccess={handleEntitiesChange}
                  />
                )}
              </div>
            ),
          }))}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      )}
      {errorCode && (
        <NoData
          type="error"
          title={i18n.t("error.fetch")}
          description={i18n.t(errorCode)}
        />
      )}
    </div>
  );
};

export { EntitiesPage };
