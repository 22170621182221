import { ListDashes } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useParentEventsQuery } from "../../api/blockchain/events";
import { useSession } from "../../context/session";
import { useCompanyEvents } from "../../hooks/useCompanyEvents";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import type { EntitiesMap } from "../../types/models/entities";
import type { TParentEvent } from "../../types/models/events";
import { hasRequiredPermission } from "../../utils/permissions";
import { EventsBanner } from "../AddEvents/Banner/EventsBanner";
import { EventsWizard } from "../AddEvents/EventsWizard";
import { EmptyState } from "../design-system/EmptyState";
import { EventActionsBar } from "../EventActionsBar/EventActionsBar";
import { EventsTable } from "../EventList/EventsTable";
import { useApproversStatus } from "./History.utils";

const useExpandEvents = (events: TParentEvent[]) => {
  const getEventsWithState = (
    filteredEvents: TParentEvent[],
    isExpanded: boolean
  ): Record<string, boolean> =>
    Object.fromEntries(
      Object.values(filteredEvents).map(({ id }) => [id, isExpanded])
    );

  const allEventsCollapsed = getEventsWithState(events, false);

  const [expandedEvents, setExpandedEvents] =
    useState<ReturnType<typeof getEventsWithState>>(allEventsCollapsed);

  return {
    expandedEvents,
    setExpandedEvents,
  };
};

const History = ({
  currentCompany,
  entitiesMap,
}: {
  currentCompany: CompanyInvolvement | CompanyInformation;
  entitiesMap: EntitiesMap;
}) => {
  const i18n = useTranslation();
  const { user } = useSession();
  const [showEventsWizard, setShowEventsWizard] = useState(false);

  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const events = eventsQuery.data?.data || [];
  const { draftEvents, pendingEvents, pendingRollbackEvents, approvedEvents } =
    useCompanyEvents(currentCompany.orgNumber);
  const allApprovedEvents = [...pendingRollbackEvents, ...approvedEvents];
  const { expandedEvents, setExpandedEvents } =
    useExpandEvents(allApprovedEvents);

  const { pendingApprovalBy, approvedBy } = useApproversStatus(
    currentCompany.orgNumber,
    events.find(
      (x) =>
        x.type === "LedgerApprovalInitialized" ||
        x.type === "LedgerRollbackPending"
    )?.date,
    entitiesMap
  );

  return (
    <div className="md:tw-space-y-4">
      <div className="tw-flex tw-items-center tw-justify-between tw-gap-2 print:tw-hidden max-sm:tw-hidden">
        <h1 className="tw-text-2xl tw-font-medium ">
          {i18n.t("label.corporateActions")}
        </h1>
        <div>
          {hasRequiredPermission("Editor", currentCompany, user) && (
            <div className="tw-flex tw-items-center tw-justify-stretch tw-gap-2 max-md:tw-grid">
              {hasRequiredPermission("Editor", currentCompany, user) && (
                <EventsBanner
                  totalDraftEvents={draftEvents.length}
                  inReview={
                    pendingEvents.length > 0 || pendingRollbackEvents.length > 0
                  }
                  isRollback={pendingRollbackEvents.length > 0}
                  onClick={() => setShowEventsWizard(true)}
                  pendingApprovalBy={pendingApprovalBy}
                  approvedBy={approvedBy}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="max-md:tw-pb-[80px]">
          <EventsTable
            events={allApprovedEvents}
            currentCompany={currentCompany}
            entitiesMap={entitiesMap}
            expandedEvents={expandedEvents}
            setExpandedEvents={setExpandedEvents}
            emptyState={
              <EmptyState
                icon={<ListDashes size={32} />}
                title={i18n.t("events.table.empty.title")}
                description={i18n.t("events.table.empty.description")}
              />
            }
          />
        </div>
        <EventActionsBar
          currentCompany={currentCompany}
          user={user}
          setShowEventsWizard={setShowEventsWizard}
          pendingApprovalBy={pendingApprovalBy}
          approvedBy={approvedBy}
        />
      </div>
      {showEventsWizard && (
        <EventsWizard
          currentCompany={currentCompany}
          entitiesMap={entitiesMap}
          onClose={() => setShowEventsWizard(false)}
        />
      )}
    </div>
  );
};

export { History };
