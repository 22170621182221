import { useTranslation } from "react-i18next";

import { LedgerVersionDetails } from "../../pages/CompanyShares/useLedgerVersions";
import { CompanyInformation } from "../../types/models/administration";
import { CompanyInvolvement } from "../../types/models/company";
import { EntitiesMap } from "../../types/models/entities";
import { RestrictionCase } from "../../types/models/restriction-case";
import {
  Shareblock,
  ShareBlockHistory,
  ShareTypesMap,
} from "../../types/models/shares";
import { useRestrictiveConditionOptions } from "../ShareTypes/SelectRestrictiveConditions";
import { ExportMenu, FormData } from "./ExportMenu";
import {
  downloadShareledgerPdf,
  downloadShareledgerXlsx,
} from "./ExportMenu.utils";

type ExportMenuShareholdersProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  ledgerVersions: LedgerVersionDetails[];
  selectedVersion?: LedgerVersionDetails;
  shareblocks: Shareblock[];
  entitiesMap: EntitiesMap;
  shareTypesMap: ShareTypesMap;
  shareBlockHistory: ShareBlockHistory[];
  notes: Record<string, RestrictionCase[]>;
};

const ExportMenuShareledger = ({
  currentCompany,
  ledgerVersions,
  selectedVersion,
  shareblocks,
  entitiesMap,
  shareTypesMap,
  shareBlockHistory,
  notes,
}: ExportMenuShareholdersProps) => {
  const i18n = useTranslation();
  const conditionOptions = useRestrictiveConditionOptions();

  return (
    <ExportMenu
      ledgerVersions={ledgerVersions}
      selectedVersion={selectedVersion}
      downloadPdf={(data: FormData) =>
        downloadShareledgerPdf(
          i18n.t("label.shareLedger", { lng: data.language }),
          currentCompany,
          selectedVersion!.formatedValue,
          data.language
        )
      }
      downloadXlsx={(data: FormData) =>
        downloadShareledgerXlsx(
          shareblocks,
          notes,
          entitiesMap,
          shareTypesMap,
          shareBlockHistory,
          conditionOptions,
          currentCompany,
          data.version!.formatedValue,
          i18n,
          data.language
        )
      }
    />
  );
};

export { ExportMenuShareledger };
