import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useUpdateCaseLitigation } from "../../../../api/rest/restriction-case";
import { Button } from "../../../../components/design-system/Button";
import { Dialog } from "../../../../components/design-system/Dialog";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../../components/design-system/FormGroup";
import { Select } from "../../../../components/design-system/Select";
import { CompanyInformation } from "../../../../types/models/administration";
import { CompanyInvolvement } from "../../../../types/models/company";
import { UpdateRestrictionCaseLitigation } from "../../../../types/models/restriction-case";

type RestrictionCaseLitigationUpdateFormProps = {
  company: CompanyInformation | CompanyInvolvement;
  caseId: string;
  litigationId: string;
  defaultValues?: UpdateRestrictionCaseLitigation;
  onClose: () => void;
};

const formId = "case-litigation-update-response";

const RestrictionCaseLitigationUpdateForm = ({
  company,
  caseId,
  litigationId,
  defaultValues,
  onClose,
}: RestrictionCaseLitigationUpdateFormProps) => {
  const i18n = useTranslation();
  const { handleSubmit, control } = useForm<UpdateRestrictionCaseLitigation>({
    mode: "onSubmit",
    defaultValues,
  });

  const statusOptions: { value: string; label: string }[] = [
    {
      value: "ACTIVE",
      label: i18n.t("restrictionCase.litigation.status.active"),
    },
    {
      value: "RESOLVED",
      label: i18n.t("restrictionCase.litigation.status.resolved"),
    },
  ];

  const mutation = useUpdateCaseLitigation(
    company.orgNumber,
    caseId,
    litigationId,
    {
      onSuccess: onClose,
    }
  );

  return (
    <Dialog
      isOpen
      title={i18n.t("restrictionCase.litigation.update")}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} disabled={mutation.isLoading}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            form={formId}
            isLoading={mutation.isLoading}
          >
            {i18n.t("label.save")}
          </Button>
        </>
      }
      isLoading={mutation.isLoading}
    >
      <form
        className="tw-space-y-4 tw-pt-4"
        onSubmit={(event) => {
          event.stopPropagation();

          return handleSubmit((data) => {
            mutation.mutate(
              data.status === "RESOLVED" ? data : { status: data.status }
            );
          })(event);
        }}
        id={formId}
      >
        <FormGroup>
          <FormLabel htmlFor="status">
            {i18n.t("restrictionCase.litigation.status")}
          </FormLabel>
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value }, fieldState }) => (
              <>
                <Select
                  value={statusOptions.find((c) => c.value === value)}
                  options={statusOptions}
                  onChange={(newValue) => onChange(newValue?.value)}
                  menuPosition="fixed"
                  isSearchable={false}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
          />
        </FormGroup>
        {mutation.error && <FormErrorList error={mutation.error} />}
      </form>
    </Dialog>
  );
};

export { RestrictionCaseLitigationUpdateForm };
