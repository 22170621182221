import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useBlockchainClient } from "../../context/blockchain";
import {
  CreateRestrictionCase,
  CreateRestrictionCaseLitigation,
  CreateRestrictionCaseResponse,
  UpdateRestrictionCaseLitigation,
} from "../../types/models/restriction-case";
import * as monitoring from "../../utils/monitoring";
import type { IRequestError } from "..";
import useClient, { URL } from "./client";
import {
  IdTxResponse,
  IdTxResponseSchema,
  RawTxResponse,
  RawTxResponseSchema,
} from "./users";

const useCreateRestrictionCase = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError, CreateRestrictionCase>
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, CreateRestrictionCase>(
    async (data) => {
      const response = await client<IdTxResponse>(
        `${URL.ADMIN}/Transaction/RestrictionCase/${orgNumber}`,
        { body: data, method: "PUT" }
      );

      const result = IdTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      await blockchainClient.sendTransaction(result.data.rawTx);
      return result.data.id;
    },
    {
      ...options,
      onSuccess: (data, vars, context) => {
        queryClient.invalidateQueries({
          queryKey: ["restrictionCases", orgNumber],
        });

        if (options?.onSuccess) {
          options.onSuccess(data, vars, context);
        }
      },
    }
  );
};

const useUpdateRestrictionCase = (
  orgNumber: string,
  caseId: string,
  options?: UseMutationOptions<unknown, IRequestError, CreateRestrictionCase>
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, CreateRestrictionCase>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/RestrictionCase/${orgNumber}/${caseId}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    {
      ...options,
      onSuccess: (data, vars, context) => {
        queryClient.invalidateQueries({
          queryKey: ["restrictionCases", orgNumber],
        });

        if (options?.onSuccess) {
          options.onSuccess(data, vars, context);
        }
      },
    }
  );
};

const useUpdateRestrictionCaseStatus = (
  orgNumber: string,
  caseId: string,
  options?: UseMutationOptions<unknown, IRequestError, "ACTIVE" | "ARCHIVED">
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, "ACTIVE" | "ARCHIVED">(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/RestrictionCase/${orgNumber}/${caseId}/Status`,
        { body: { status: data }, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    {
      ...options,
      onSuccess: (data, vars, context) => {
        queryClient.invalidateQueries({
          queryKey: ["restrictionCases", orgNumber],
        });

        if (options?.onSuccess) {
          options.onSuccess(data, vars, context);
        }
      },
    }
  );
};

const useCreateShareholderResponse = (
  orgNumber: string,
  caseId: string,
  options?: UseMutationOptions<
    unknown,
    IRequestError,
    CreateRestrictionCaseResponse
  >
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, CreateRestrictionCaseResponse>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/RestrictionCase/${orgNumber}/Response/${caseId}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    {
      ...options,
      onSuccess: (data, vars, context) => {
        queryClient.invalidateQueries({
          queryKey: ["restrictionCases", orgNumber],
        });

        if (options?.onSuccess) {
          options.onSuccess(data, vars, context);
        }
      },
    }
  );
};

const useUpdateShareholderResponse = (
  orgNumber: string,
  caseId: string,
  options?: UseMutationOptions<
    unknown,
    IRequestError,
    CreateRestrictionCaseResponse
  >
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, CreateRestrictionCaseResponse>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/RestrictionCase/${orgNumber}/Response/${caseId}/Update`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    {
      ...options,
      onSuccess: (data, vars, context) => {
        queryClient.invalidateQueries({
          queryKey: ["restrictionCases", orgNumber],
        });

        if (options?.onSuccess) {
          options.onSuccess(data, vars, context);
        }
      },
    }
  );
};

const useDeleteShareholderResponse = (
  orgNumber: string,
  caseId: string,
  options?: UseMutationOptions<unknown, IRequestError, string>
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, string>(
    async (shareholderId) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/RestrictionCase/${orgNumber}/Response/${caseId}/${shareholderId}`,
        { method: "DELETE" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    {
      ...options,
      onSuccess: (data, vars, context) => {
        queryClient.invalidateQueries({
          queryKey: ["restrictionCases", orgNumber],
        });

        if (options?.onSuccess) {
          options.onSuccess(data, vars, context);
        }
      },
    }
  );
};

const useRegisterCaseLitigation = (
  orgNumber: string,
  caseId: string,
  options?: UseMutationOptions<
    unknown,
    IRequestError,
    CreateRestrictionCaseLitigation
  >
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, CreateRestrictionCaseLitigation>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/RestrictionCase/${orgNumber}/Response/${caseId}/Litigation`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    {
      ...options,
      onSuccess: (data, vars, context) => {
        queryClient.invalidateQueries({
          queryKey: ["restrictionCases", orgNumber],
        });

        if (options?.onSuccess) {
          options.onSuccess(data, vars, context);
        }
      },
    }
  );
};

const useUpdateCaseLitigation = (
  orgNumber: string,
  caseId: string,
  litigationId: string,
  options?: UseMutationOptions<
    unknown,
    IRequestError,
    UpdateRestrictionCaseLitigation
  >
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, UpdateRestrictionCaseLitigation>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/RestrictionCase/${orgNumber}/Response/${caseId}/Litigation/${litigationId}/Update`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    {
      ...options,
      onSuccess: (data, vars, context) => {
        queryClient.invalidateQueries({
          queryKey: ["restrictionCases", orgNumber],
        });

        if (options?.onSuccess) {
          options.onSuccess(data, vars, context);
        }
      },
    }
  );
};

export {
  useCreateRestrictionCase,
  useCreateShareholderResponse,
  useDeleteShareholderResponse,
  useRegisterCaseLitigation,
  useUpdateCaseLitigation,
  useUpdateRestrictionCase,
  useUpdateRestrictionCaseStatus,
  useUpdateShareholderResponse,
};
