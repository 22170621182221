import { useTranslation } from "react-i18next";

import { LedgerVersionDetails } from "../../pages/CompanyShares/useLedgerVersions";
import { CompanyInformation } from "../../types/models/administration";
import { CompanyInvolvement } from "../../types/models/company";
import { Entity } from "../../types/models/entities";
import { OptionsProgram } from "../../types/models/options";
import { Shareholder } from "../../types/models/shares";
import { ShareholderDataTotals } from "../../utils/excel-utils";
import { ExportMenu, FormData } from "./ExportMenu";
import {
  downloadShareholdersPdf,
  downloadShareholdersXlsx,
} from "./ExportMenu.utils";

type ExportMenuShareholdersProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  ledgerVersions: LedgerVersionDetails[];
  selectedVersion?: LedgerVersionDetails;
  optionPrograms?: OptionsProgram[];
  selectedPrograms?: string[];
  shareholders?: (Shareholder & { entity: Entity })[];
  entities?: Record<string, Entity>;
  shareTypesByName?: Record<string, number>;
  totals?: ShareholderDataTotals;
};

const ExportMenuShareholders = ({
  currentCompany,
  ledgerVersions,
  selectedVersion,
  optionPrograms,
  selectedPrograms,
  shareholders,
  shareTypesByName,
  entities,
  totals,
}: ExportMenuShareholdersProps) => {
  const i18n = useTranslation();

  return (
    <ExportMenu
      ledgerVersions={ledgerVersions}
      selectedVersion={selectedVersion}
      optionPrograms={optionPrograms}
      selectedPrograms={selectedPrograms}
      downloadPdf={(data: FormData) =>
        downloadShareholdersPdf(
          i18n.t("label.shareholders", { lng: data.language }),
          currentCompany,
          data.version!.formatedValue,
          data.selectedPrograms || [],
          data.language
        )
      }
      downloadXlsx={(data: FormData) => {
        const selectedOptionPrograms = optionPrograms!.filter((x) =>
          (data.selectedPrograms || []).includes(x.id!)
        );
        downloadShareholdersXlsx(
          shareholders!,
          selectedOptionPrograms,
          shareTypesByName!,
          entities!,
          totals!,
          currentCompany,
          data.version!.formatedValue,
          i18n,
          data.language
        );
      }}
    />
  );
};

export { ExportMenuShareholders };
