import "react-phone-number-input/style.css";
import "./custom-phone-input.css";

import type { ComponentProps } from "react";
import PhoneInput from "react-phone-number-input";

const Phone = ({
  id,
  name,
  value,
  onChange,
}: {
  id: string;
  name: string;
  value?: string;
  onChange: ComponentProps<typeof PhoneInput>["onChange"];
}) => {
  return (
    <div className="focus-within:tw-custom-focus-visible tw-relative tw-flex tw-h-12 tw-rounded tw-border tw-border-solid">
      <PhoneInput
        id={id}
        name={name}
        value={value || ""}
        onChange={onChange}
        defaultCountry="SE"
        international
        placeholder="+46 XXX XXX XX"
        className="tw-border-none tw-px-4 tw-outline-none focus:tw-outline-none"
      />
    </div>
  );
};

export { isPossiblePhoneNumber } from "react-phone-number-input/input";
export { Phone as PhoneInput };
