import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Entity } from "../../../types/models/entities";
import { ShareBlockHistory } from "../../../types/models/shares";
import { getFormattedDate } from "../../../utils/date";
import { Button } from "../../design-system/Button";
import { Description } from "../../design-system/Description";
import { EntityItem } from "../../design-system/EntityItem";
import { getEventTypeFormatted } from "../DetailedBlockRow";

type HistorySectionProps = {
  history?: ShareBlockHistory;
  entitiesMap: Record<string, Entity>;
};

const HistoryRow = ({
  holder,
  historicHolder,
}: {
  holder: ShareBlockHistory;
  historicHolder: Entity;
}) => {
  const i18n = useTranslation();
  const eventType = getEventTypeFormatted(i18n, holder.eventType);

  return (
    <div
      key={`${holder.holder.id}-${holder.date}`}
      className="tw-grid tw-w-full tw-gap-2 tw-border-[1px] tw-p-4 print:tw-grid-cols-3 md:tw-grid-cols-3"
    >
      <EntityItem value={historicHolder} displayIcon={false} hasFlag={false} />
      <Description
        title={eventType}
        description={getFormattedDate(holder.date)}
      />
    </div>
  );
};

const HistorySection: React.FC<HistorySectionProps> = ({
  history,
  entitiesMap,
}) => {
  const i18n = useTranslation();
  const [showAllPreviousHolders, setShowAllPreviousHolders] = useState(false);

  if (!history?.previousHolders || history.previousHolders.length === 0) {
    return null;
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      {history.previousHolders?.map((holder, index) => {
        if (!showAllPreviousHolders && index > 0) {
          return null;
        }

        const historicHolder =
          entitiesMap[holder.holder.id] ||
          Object.values(entitiesMap).find(
            (x) => x.refId === holder.holder.refId
          );
        if (!historicHolder) {
          return null;
        }

        return (
          <HistoryRow
            historicHolder={historicHolder}
            holder={holder}
            key={holder.date}
          />
        );
      })}

      {history.previousHolders?.length > 1 && (
        <Button
          type="button"
          className="tw-w-full tw-rounded tw-p-4 tw-text-sm"
          onClick={(e) => {
            e.stopPropagation();
            setShowAllPreviousHolders(!showAllPreviousHolders);
          }}
        >
          {showAllPreviousHolders
            ? i18n.t("label.showLess")
            : `+ ${history.previousHolders.length - 1} ${i18n.t("label.more")}`}
        </Button>
      )}
    </div>
  );
};

export { HistorySection };
