import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useLedgerQuery } from "../../../api/blockchain/company";
import { Dialog } from "../../../components/design-system/Dialog";
import {
  FormError,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { Input } from "../../../components/design-system/Input";
import { Loading } from "../../../components/design-system/Loading";
import { Select } from "../../../components/design-system/Select";
import { CompanyInformation } from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import { OptionsProgram } from "../../../types/models/options";
import { getFormattedDate } from "../../../utils/date";
import { formatCurrency, formatNumber } from "../../../utils/format";
import {
  blackScholes,
  calculateYearsBetweenDates,
} from "./OptionsValuation.utils";

type OptionsValuationProps = {
  currentCompany: CompanyInformation | CompanyInvolvement;
  program: OptionsProgram;
  onClose: () => void;
};

const OptionsValuation = ({
  currentCompany,
  program,
  onClose,
}: OptionsValuationProps) => {
  const i18n = useTranslation();
  type ValuationMethod = "company" | "pricePerShare";
  const valuationMethods: {
    value: ValuationMethod;
    label: string;
  }[] = [
    {
      value: "company",
      label: i18n.t("optionsValuation.valuation.company"),
    },
    {
      value: "pricePerShare",
      label: i18n.t("optionsValuation.valuation.share"),
    },
  ];

  type ValuationData = {
    valuation?: number;
    valuationMethod: ValuationMethod;
    strikePrice?: number;
    riskFreeRate?: number;
    volatility?: number;
    date?: string;
  };
  const getValuationFromStorage = () => {
    return JSON.parse(
      window.sessionStorage.getItem("optionsValuation") ?? "{}"
    ) as { [program: string]: ValuationData };
  };
  const setValuationInStorage = (data: ValuationData) => {
    const valuationStorage = getValuationFromStorage();
    window.sessionStorage.setItem(
      "optionsValuation",
      JSON.stringify({ ...valuationStorage, [currentCompany.orgNumber]: data })
    );
  };

  const ledgerQuery = useLedgerQuery(currentCompany.orgNumber, "");
  const ledgerData = ledgerQuery.data;
  const valuationData = getValuationFromStorage()[currentCompany.orgNumber];

  const defaultValues: ValuationData = {
    valuation: valuationData?.valuation,
    valuationMethod: valuationData?.valuationMethod || "company",
    strikePrice: valuationData?.strikePrice,
    riskFreeRate: valuationData?.riskFreeRate,
    volatility: valuationData?.volatility,
    date:
      valuationData?.date ||
      (new Date() <= new Date(program.strikeEndDate)
        ? getFormattedDate(new Date())
        : program.strikeEndDate),
  };
  const {
    register,
    formState: { errors },
    control,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues,
  });
  const formValues = watch();

  const valuation = useMemo(() => {
    if (
      formValues.valuation &&
      formValues.date &&
      formValues.riskFreeRate &&
      formValues.volatility &&
      program.strikeEndDate &&
      program.strikePrice
    ) {
      setValuationInStorage(formValues);
      const yearsBetweenDates = calculateYearsBetweenDates(
        new Date(formValues.date),
        new Date(program.strikeEndDate)
      );
      const shareValuation =
        formValues.valuationMethod === "pricePerShare"
          ? formValues.valuation
          : formValues.valuation / (ledgerData?.shares.total || 1);
      return blackScholes(
        shareValuation,
        program.strikePrice,
        formValues.riskFreeRate / 100,
        yearsBetweenDates,
        formValues.volatility / 100
      );
    }
    return undefined;
  }, [program, formValues, ledgerData?.shares.total]);

  if (ledgerQuery.isLoading) {
    return <Loading />;
  }

  return (
    <Dialog
      title={i18n.t("optionsValuation.title")}
      isOpen
      onClose={onClose}
      size="md"
    >
      <div className="tw-flex tw-flex-col tw-gap-6 tw-px-2 tw-py-4">
        <form className="tw-space-y-6">
          <FormGroup>
            <FormLabel htmlFor="strikePrice">
              {i18n.t("optionsValuation.strikePrice")}
            </FormLabel>
            <Input
              id="strikePrice"
              value={program.strikePrice}
              disabled
              prefix={program.currency}
            />
          </FormGroup>
          <FormGroup className="tw-flex-1">
            <FormLabel htmlFor="valuation">
              {i18n.t("optionsValuation.valuation")}
            </FormLabel>
            <div className="tw-flex tw-flex-col tw-gap-6 md:tw-flex-row md:tw-gap-2">
              <div className="tw-flex-1 tw-space-y-2">
                <Controller
                  control={control}
                  name="valuation"
                  rules={{
                    required: i18n.t("error.validation.required"),
                    min: {
                      value: 0.01,
                      message: i18n.t("error.validation.range.min", {
                        min: 0.01,
                      }),
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <>
                      <Input
                        id="valuation"
                        type="number"
                        step=".01"
                        min={1}
                        prefix={program.currency}
                        value={value}
                        onChange={(e) => onChange(e.target.valueAsNumber)}
                      />
                      {value && value > 0 ? (
                        <p className="tw-text-sm tw-text-secondary">
                          {formValues.valuationMethod === "company"
                            ? `${i18n.t(
                                "optionsValuation.valuation.share"
                              )}: ${formatCurrency(
                                value / (ledgerData?.shares.total || 1),
                                program.currency
                              )}`
                            : `${i18n.t(
                                "optionsValuation.valuation.company"
                              )}: ${formatCurrency(
                                value * (ledgerData?.shares.total || 1),
                                program.currency,
                                0,
                                0
                              )}`}
                        </p>
                      ) : undefined}
                      <FormError>{fieldState.error?.message}</FormError>
                    </>
                  )}
                />
              </div>
              <div className="tw-flex-1">
                <Controller
                  control={control}
                  name="valuationMethod"
                  render={({
                    field: { onChange, value, name },
                    fieldState,
                  }) => (
                    <>
                      <Select
                        name={name}
                        value={valuationMethods.find((t) => t.value === value)}
                        options={valuationMethods}
                        onChange={(newValue) => onChange(newValue?.value)}
                        menuPosition="fixed"
                      />
                      <FormError>{fieldState.error?.message}</FormError>
                    </>
                  )}
                  rules={{ required: i18n.t("error.validation.required") }}
                />
              </div>
            </div>
          </FormGroup>
          <div className="tw-flex tw-w-full tw-flex-col tw-gap-6 md:tw-flex-row md:tw-gap-2">
            <FormGroup className="tw-flex-1">
              <FormLabel htmlFor="riskFreeRate">
                {i18n.t("optionsValuation.riskFreeRate")}
              </FormLabel>
              <Input
                id="riskFreeRate"
                {...register("riskFreeRate", {
                  required: i18n.t("error.validation.required"),
                  valueAsNumber: true,
                  min: {
                    value: 0.01,
                    message: i18n.t("error.validation.range.min", {
                      min: 0.01,
                    }),
                  },
                  max: {
                    value: 100,
                    message: i18n.t("error.validation.range.max", { max: 100 }),
                  },
                })}
                type="number"
                step=".01"
                min={0.01}
                max={100}
                suffix="%"
              />
              <FormError>{errors.riskFreeRate?.message}</FormError>
            </FormGroup>
            <FormGroup className="tw-flex-1">
              <FormLabel htmlFor="volatility">
                {i18n.t("optionsValuation.volatility")}
              </FormLabel>
              <Input
                id="volatility"
                {...register("volatility", {
                  required: i18n.t("error.validation.required"),
                  valueAsNumber: true,
                  min: {
                    value: 0.01,
                    message: i18n.t("error.validation.range.min", {
                      min: 0.01,
                    }),
                  },
                  max: {
                    value: 100,
                    message: i18n.t("error.validation.range.max", { max: 100 }),
                  },
                })}
                type="number"
                step=".01"
                min={0.01}
                max={100}
                suffix="%"
              />
              <FormError>{errors.volatility?.message}</FormError>
            </FormGroup>
          </div>
          <Controller
            control={control}
            render={({ field: { ref, name, onChange, value }, fieldState }) => (
              <div className="tw-flex tw-w-full tw-flex-col tw-gap-6 md:tw-flex-row md:tw-gap-2">
                <FormGroup className="tw-flex-1">
                  <FormLabel htmlFor="date">
                    {i18n.t("optionsValuation.date")}
                  </FormLabel>
                  <Input
                    id="date"
                    value={value}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    type="date"
                    max={program.strikeEndDate}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </FormGroup>
                <FormGroup className="tw-flex-1">
                  <FormLabel htmlFor="date">
                    {i18n.t("optionsValuation.time")}
                  </FormLabel>
                  <Input
                    value={i18n.t("optionsValuation.time.years", {
                      total: formatNumber(
                        calculateYearsBetweenDates(
                          new Date(value!),
                          new Date(program.strikeEndDate)
                        ),
                        2
                      ),
                    })}
                    disabled
                  />
                </FormGroup>
              </div>
            )}
            name="date"
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </form>
        <div className="tw-flex tw-flex-col tw-gap-4 tw-rounded tw-bg-kvanta-secondary tw-p-4 tw-text-kvanta-primary md:tw-flex-row">
          <div className="tw-flex-1">
            <p>{i18n.t("optionsValuation.result.perOption")}</p>
            <h3 className="tw-font-medium">
              {valuation !== undefined
                ? formatCurrency(valuation, program.currency, 2, 2)
                : "-"}
            </h3>
          </div>
          <div className="tw-flex-1">
            <p>{i18n.t("optionsValuation.result.program")}</p>
            <h3 className="tw-font-medium">
              {valuation !== undefined
                ? formatCurrency(
                    valuation * (program.totalAmountOfOptions || 1),
                    program.currency,
                    2,
                    2
                  )
                : "-"}
            </h3>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export { OptionsValuation };
