import { ArrowRight } from "@phosphor-icons/react";

type HomePageCardProps = {
  title: string;
  description: string;
  footer?: JSX.Element;
  arrowLabel?: string;
};

const HomePageCard = ({
  title,
  description,
  footer,
  arrowLabel,
}: HomePageCardProps) => (
  <div className="tw-rounded-md tw-border tw-p-4 tw-text-left hover:tw-bg-neutral-50">
    <h5 className="tw-text-base tw-font-medium">{title}</h5>
    <h5 className="tw-text-sm tw-text-secondary">{description}</h5>
    <div className="tw-mt-6 tw-flex tw-justify-between">
      <div>{footer}</div>
      <div className="tw-flex tw-items-center tw-gap-2">
        {arrowLabel && (
          <span className="tw-items-center tw-text-xs">{arrowLabel}</span>
        )}
        <ArrowRight />
      </div>
    </div>
  </div>
);

export { HomePageCard };
