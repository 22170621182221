import "./index.css";

import { Tab as TwTab } from "@headlessui/react";

import type { ExtractProps } from "../../../types";
import { clsxm } from "../../../utils/tailwind";

type TabProps = {
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  children: string | React.ReactNode;
};

type TabGroupProps = ExtractProps<typeof TwTab.Group>;

const Tab = ({ className, disabled, children, ...props }: TabProps) => (
  <TwTab
    className={clsxm(
      "tw-rounded tw-border tw-border-transparent tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-outline-none tw-ring-0 hover:tw-bg-neutral-100 focus:tw-ring-0 focus-visible:tw-ring-0 focus-visible:tw-ring-transparent",
      { "tw-text-neutral-400": disabled },
      className
    )}
    disabled={disabled}
    {...props}
  >
    <div className="tw-flex tw-flex-col tw-items-center">{children}</div>
  </TwTab>
);
Tab.Group = (props: TabGroupProps) => <TwTab.Group {...props} />;
Tab.List = ({ className, ...props }: ExtractProps<typeof TwTab.List>) => (
  <TwTab.List className={className} {...props} />
);
Tab.Panels = (props: ExtractProps<typeof TwTab.Panels>) => (
  <TwTab.Panels {...props} />
);
Tab.Panel = (props: ExtractProps<typeof TwTab.Panel>) => (
  <TwTab.Panel {...props} />
);

export { Tab };
export type { TabGroupProps, TabProps };
